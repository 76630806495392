import { Popover } from '@headlessui/react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { Fragment, PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';
import IconAction from './IconAction';

// someday may this can be auto with something like floating UI
const anchorStyles = {
  left: 'left-0',
  right: 'right-0',
} as const;

export default function InfoPopover({
  title,
  anchor,
  className,
  children,
}: PropsWithChildren<{
  title: string;
  anchor: keyof typeof anchorStyles;
  className?: string;
}>) {
  return (
    <Popover className="relative flex items-center">
      <Popover.Button as={Fragment}>
        <IconAction.Button
          icon={InformationCircleIcon}
          iconSize="sm"
          title={title}
          variant="ghost"
        />
      </Popover.Button>
      <Popover.Panel>
        <div
          className={twMerge(
            'absolute top-6 z-10 rounded-md border border-corso-gray-200 bg-white px-4 py-4 shadow-lg',
            anchorStyles[anchor],
            className,
          )}
        >
          {children}
        </div>
      </Popover.Panel>
    </Popover>
  );
}

function InfoPopoverProse({ children }: { children: string }) {
  return (
    <p className="w-max max-w-prose text-xs text-corso-gray-800">{children}</p>
  );
}

InfoPopover.Prose = InfoPopoverProse;
