import { RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { CorsoClaimType, corsoClaimTypeName } from 'corso-types';
import { useCallback, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { useEnabledClaimType } from '~/hooks/useEnabledClaimType';
import Alert from '~/components/Alert';

const corsoClaimTypeDescription = {
  [CorsoClaimType.return]: 'Refunds, exchanges, and store credit.',
  [CorsoClaimType.warranty]: 'Product defects, malfunctions, etc.',
  [CorsoClaimType.shippingProtection]:
    'Shipping issues, carrier damage, lost packages, etc.',
} satisfies Record<CorsoClaimType, string>;

function RadioGroupOption({
  claimType,
  disabled,
}: {
  claimType: CorsoClaimType;
  disabled?: boolean;
}) {
  return (
    <RadioGroup.Option
      key={claimType}
      value={claimType}
      disabled={disabled}
      className={({ active, checked }) =>
        twMerge(
          'relative flex rounded-lg border p-4 shadow-sm focus:outline-none',
          active || checked ?
            'border-corso-blue-600 ring-2 ring-corso-blue-600'
          : 'border-gray-300',
          disabled ?
            'cursor-not-allowed bg-corso-gray-50 opacity-80'
          : 'cursor-pointer bg-white',
        )
      }
    >
      {({ checked }) => (
        <>
          <div className="flex flex-col">
            <span className="block text-sm font-medium text-corso-gray-900">
              {corsoClaimTypeName[claimType]}
            </span>
            <span className="mt-1 flex items-center text-sm text-corso-gray-500">
              {corsoClaimTypeDescription[claimType]}
            </span>
          </div>

          <CheckCircleIcon
            className={twMerge(
              'absolute right-4 top-4 size-5 text-corso-blue-600',
              checked ? 'visible' : 'invisible',
            )}
            aria-hidden="true"
          />
        </>
      )}
    </RadioGroup.Option>
  );
}

export default function ClaimTypeSelection({
  onClaimTypeSelect,
}: {
  onClaimTypeSelect: (claimType: CorsoClaimType) => void;
}) {
  const [claimType, setClaimType] = useState<CorsoClaimType | null>(null);

  const handleOnClaimTypeSelect = useCallback(
    (selectClaimType: CorsoClaimType) => {
      setClaimType(selectClaimType);
      onClaimTypeSelect(selectClaimType);
    },
    [onClaimTypeSelect],
  );

  const { isWarrantyEnabled, isReturnsEnabled, isShippingProtectionEnabled } =
    useEnabledClaimType();

  const claimTypes = Object.values(CorsoClaimType).map((type) => ({
    type,
    enabled:
      // eslint-disable-next-line no-nested-ternary
      type === CorsoClaimType.warranty ? isWarrantyEnabled
      : type === CorsoClaimType.return ? isReturnsEnabled
      : isShippingProtectionEnabled,
  }));

  // if there's only one claim type available, select it automatically

  useEffect(() => {
    if (claimTypes.filter(({ enabled }) => enabled).length === 1) {
      const [onlyClaimType] = claimTypes.filter(({ enabled }) => enabled);
      if (onlyClaimType) {
        handleOnClaimTypeSelect(onlyClaimType.type);
      }
    }
  }, [claimTypes, handleOnClaimTypeSelect]);

  return claimTypes.length ?
      <fieldset>
        <legend className="text-sm font-semibold leading-6 text-corso-gray-900">
          What type of claim would you like to create?
        </legend>

        <RadioGroup
          value={claimType}
          onChange={handleOnClaimTypeSelect}
          className="mt-3 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4"
        >
          {claimTypes.map(
            ({ type, enabled }) =>
              enabled && (
                <RadioGroupOption
                  key={type}
                  claimType={type}
                  disabled={!enabled}
                />
              ),
          )}
        </RadioGroup>
      </fieldset>
    : <Alert variant="DEFAULT" message="No Claim Types Available" />;
}
