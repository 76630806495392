import {
  CrewClaimReasonCategoryCode,
  crewClaimReasonCategoryCodeName,
} from 'corso-types/enums/crew';
import { useMemo } from 'react';

// TODO use a constant dataset instead of a hook
// ? might be better used as a singleton from `corso-types`, or possibly a part of a dataset file
export default function useClaimReasonCategories() {
  return useMemo(
    () =>
      Object.values(CrewClaimReasonCategoryCode) // ? there's no equivalent of an "Other" category; this might be desirable in the future
        .map((code) => ({
          code,
          name: crewClaimReasonCategoryCodeName[code],
        }))
        .sort((a, b) => a.name.localeCompare(b.name)), // alphabetize by label
    [],
  );
}
