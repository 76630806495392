import { z } from 'zod';
import { variantExchange } from '../customer/schema/variantExchange.js';
import { claimAddress } from '../schema/address.js';
import {
  claim,
  claimCreate,
  claimCustomerPayment,
  claimList,
  claimListCount,
  claimListItem,
  claimUpdateCustomerInfo,
  claimUpdateFees,
  tag,
} from '../schema/claim.js';
import {
  approvedGiftCard,
  approvedRefund,
  approvedReplacementOrder,
  approvedVariantExchange,
  baseClaimLineItemProcess,
  claimFinalizePayload,
  claimNeedsMoreInfoPayload,
  claimNotifyByEmail,
  denyClaimLineItem,
} from '../schema/claimFinalize.js';
import {
  claimLineItem,
  claimLineItemCustomField,
  claimLineItemInspection,
  claimLineItemInspectionCreate,
} from '../schema/claimLineItem.js';
import {
  claimReason,
  claimReasonCreate,
  claimReasonDetailUpdate,
  claimReasonGroup,
  claimReasonGroupCreate,
  claimReasonGroupUpdate,
  claimReasonUpdate,
} from '../schema/claimReasons.js';
import { claimTimeline, claimTimelineEvent } from '../schema/claimTimeline.js';
import { crewOrder, trackerWithLineItems } from '../schema/order.js';

import { storeInspectionSettings } from '../schema/inspection.js';
import { registration, registrationList } from '../schema/registration.js';
import {
  returnLocation,
  returnLocationCreate,
} from '../schema/returnLocation.js';
import {
  returnShipmentTracker,
  shipment,
  shipmentAdminCreate,
  shipmentAdminQuote,
  shipmentAdminQuoteResponse,
} from '../schema/shipment.js';
import {
  shippingClaimCreateMerchant,
  shippingClaimFinalize,
  shippingClaimList,
  shippingClaimMerchant,
} from '../schema/shippingClaim.js';
import { signedUrl } from '../schema/signedUrl.js';
import {
  productCollection,
  productTag,
  productType,
  storeProduct,
  storeProductGroup,
  storeProductGroupCreate,
  storeProductMinimum,
} from '../schema/storeProduct.js';
import {
  arrayFactData,
  storeRule,
  storeRuleCreate,
} from '../schema/storeRule.js';
import { superset } from '../schema/superset.js';
import { apiClientResp } from './error.js';
import {
  customField,
  customFieldCreate,
  customFieldUpdate,
} from './schema/customField.js';
import { invoice } from './schema/invoices.js';
import { merchantOnboard } from './schema/merchantOnboard.js';
import {
  appSubscription,
  blueBoxUpdate,
  easyPostUpdate,
  rechargeUpdate,
  settingsConfig,
  settingsIntegration,
  settingsIntegrationUpdate,
  settingsTheme,
  shipBobUpdate,
  shipHeroUpdate,
  shippingProtection,
  shippingProviderUpdate,
  statusConfigUpdate,
  vesylUpdate,
} from './schema/settings.js';
import { userAccessCodeStore } from './schema/storeWithUsers.js';
import {
  storeFulfillmentLocation,
  storeUser,
  storeUserCreate,
  storeUserSignUp,
  storeUserUpdate,
  userSignUp,
  userSignUpAndOnboard,
  userWithStoreUserRoles,
} from './schema/user.js';

/**
 * CrewUi types for the crew-merchant-ui :)
 * Utility types to be used in conjunction with the relevant API endpoint types.
 */
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CrewMerchantUi {
  // ? we still want to be able to support that do not have a product / variant ID from Shopify, currently this is an error for us

  // ? urls being used by the API to redirect to the merchant-ui
  export const frontEndUrlPaths = [
    `:storeId/settings/integrations`, // ? used by the API to redirect to the merchant-ui after app subscription generation + stripe subscription
    ':storeId/claims/:claimType/:claimId', // ? used by the API to redirect to a specific claim in the merchant-ui
  ] as const;

  // Shared Constants
  export const CREW_DISCOUNT_CODE = 'crew-discount';
  export const CREW_STORE_CREDIT_ORDER_PREFIX = '#C-GC';
  export const MERCHANT_APP_JWT_QUERY_KEY = 'accessCode';

  export const invoiceSchema = invoice;
  export type Invoice = z.infer<typeof invoice>;

  // ---------- ShopifyStore Redirect ----------
  export type UserAccessCodeStore = z.infer<typeof userAccessCodeStore>;
  export const userAccessCodeSchema = userAccessCodeStore;

  // ----------  App Subscription  ----------
  export const appSubscriptionSchema = appSubscription;
  export type AppSubscription = z.infer<typeof appSubscription>;

  // ----------  User Policy  ----------
  export type UserWithStoreUserRoles = z.infer<typeof userWithStoreUserRoles>;
  export const userWithStoreUserRolesSchema = userWithStoreUserRoles;

  export type StoreUser = z.infer<typeof storeUser>;
  export const storeUserSchema = storeUser;

  export type StoreFulfillmentLocation = z.infer<
    typeof storeFulfillmentLocation
  >;

  export type StoreUserCreate = z.infer<typeof storeUserCreate>;
  export const storeUserCreateSchema = storeUserCreate;

  export type StoreUserUpdate = z.infer<typeof storeUserUpdate>;
  export const storeUserUpdateSchema = storeUserUpdate;

  // ----------  Signed URL   ----------
  export type SignedUrl = z.infer<typeof signedUrl>;
  export const signedUrlSchema = signedUrl;

  // ----------  Superset Token   ----------
  export type SupersetToken = z.infer<typeof superset>;
  export const supersetTokenSchema = superset;

  // ---------- Return Location   ----------

  export type ReturnLocation = z.infer<typeof returnLocation>;
  export const returnLocationSchema = returnLocation;

  export type ReturnLocationCreate = z.infer<typeof returnLocationCreate>;
  export const returnLocationCreateSchema = returnLocationCreate;

  // ---------- Store InspectionSettings   ----------

  export type StoreInspectionSettings = z.infer<typeof storeInspectionSettings>;
  export const storeInspectionSettingsSchema = storeInspectionSettings;

  // ---------- Claim  Processor ----------

  export const claimFinalizePayloadSchema = claimFinalizePayload;
  export type ClaimFinalizePayload = z.infer<typeof claimFinalizePayload>;
  export type ClaimFinalizeLineItemDenial = z.infer<typeof denyClaimLineItem>;
  export type ClaimFinalizeLineItemRefund = z.infer<typeof approvedRefund>;
  export type ClaimFinalizeLineItemGiftCard = z.infer<typeof approvedGiftCard>;
  export type ClaimFinalizeLineItemVariantExchange = z.infer<
    typeof approvedVariantExchange
  >;
  export type ClaimFinalizeLineItemBase = z.infer<
    typeof baseClaimLineItemProcess
  >;
  export type ClaimFinalizeReplacementOrder = z.infer<
    typeof approvedReplacementOrder
  >;

  export type ClaimTimeline = z.infer<typeof claimTimeline>;
  export const claimTimelineSchema = claimTimeline;
  export type ClaimTimelineEvent = z.infer<typeof claimTimelineEvent>;
  export const claimTimelineEventSchema = claimTimelineEvent;

  export type ClaimNotifyByEmail = z.infer<typeof claimNotifyByEmail>;
  export const claimNotifyByEmailSchema = claimNotifyByEmail;

  export type ClaimNeedsMoreInfo = z.infer<typeof claimNeedsMoreInfoPayload>;
  export const claimNeedsMoreInfoSchema = claimNeedsMoreInfoPayload;

  // ---------- Claim Reason ----------

  export type ClaimReason = z.infer<typeof claimReason>;
  export const claimReasonSchema = claimReason;
  export type ClaimReasonCreate = z.infer<typeof claimReasonCreate>;
  export const claimReasonCreateSchema = claimReasonCreate;
  export type ClaimReasonUpdate = z.infer<typeof claimReasonUpdate>;
  export const claimReasonUpdateSchema = claimReasonUpdate;
  export const claimReasonDetailUpdateSchema = claimReasonDetailUpdate;

  export type ClaimReasonGroup = z.infer<typeof claimReasonGroup>;
  export const claimReasonGroupSchema = claimReasonGroup;
  export type ClaimReasonGroupUpdate = z.infer<typeof claimReasonGroupUpdate>;
  export const claimReasonGroupUpdateSchema = claimReasonGroupUpdate;
  export type ClaimReasonGroupCreate = z.infer<typeof claimReasonGroupCreate>;
  export const claimReasonGroupCreateSchema = claimReasonGroupCreate;

  // ---------- Claim  ----------
  export type Claim = z.infer<typeof claim>;
  export const claimSchema = claim;

  export type ClaimUpdateCustomerInfo = z.infer<typeof claimUpdateCustomerInfo>;
  export const claimUpdateCustomerInfoSchema = claimUpdateCustomerInfo;

  export type ClaimUpdateRefundHandlingFee = z.infer<typeof claimUpdateFees>;
  export const claimUpdateRefundHandlingFeeSchema = claimUpdateFees;

  export type ClaimTag = z.infer<typeof tag>;
  export const claimTagSchema = tag;

  export type ClaimMinimumData = z.infer<typeof claimListItem>;
  export const claimMinimumDataSchema = claimListItem;

  export type ClaimList = z.infer<typeof claimList>;
  export const claimListSchema = claimList;

  export type ClaimListCount = z.infer<typeof claimListCount>;
  export const claimListCountSchema = claimListCount;

  export type ClaimListItem = z.infer<typeof claimListItem>;
  export const claimListItemSchema = claimListItem;

  export type ClaimLineItem = z.infer<typeof claimLineItem>;
  export type ClaimLineItemCustomField = z.infer<
    typeof claimLineItemCustomField
  >;

  export type ClaimShipment = z.infer<typeof shipment>;

  export type ClaimReturnShipmentCreate = z.infer<typeof shipmentAdminCreate>;
  export const claimReturnShipmentCreateSchema = shipmentAdminCreate;

  export type ClaimReturnShipmentQuoteRequest = z.infer<
    typeof shipmentAdminQuote
  >;
  export const claimReturnShipmentQuoteSchema = shipmentAdminQuote;

  export type ClaimReturnShipmentQuoteResponse = z.infer<
    typeof shipmentAdminQuoteResponse
  >;
  export const claimReturnShipmentQuoteResponse = shipmentAdminQuoteResponse;

  export type ClaimLineItemInspection = z.infer<typeof claimLineItemInspection>;
  export const claimLineItemInspectionSchema = claimLineItemInspection;

  export type ClaimLineItemInspectionCreate = z.infer<
    typeof claimLineItemInspectionCreate
  >;
  export const claimLineItemInspectionCreateSchema =
    claimLineItemInspectionCreate;

  export type ClaimAddress = z.infer<typeof claimAddress>;
  export const claimAddressSchema = claimAddress;

  export type ClaimCustomerPayment = z.infer<typeof claimCustomerPayment>;
  export const claimCustomerPaymentSchema = claimCustomerPayment;
  // ---------- Settings  ----------

  export type SettingsConfig = z.infer<typeof settingsConfig>;
  export const settingsConfigSchema = settingsConfig;

  export type TrackerWithLineItems = z.infer<typeof trackerWithLineItems>;

  export type SettingsTheme = z.infer<typeof settingsTheme>;
  export const settingsThemeSchema = settingsTheme;

  export type SettingsIntegration = z.infer<typeof settingsIntegration>;
  export const settingsIntegrationSchema = settingsIntegration;

  export type SettingsIntegrationUpdate = z.infer<
    typeof settingsIntegrationUpdate
  >;

  export type ShipProtectSettings = z.infer<typeof shippingProtection>;
  export const shipProtectSettingsSchema = shippingProtection;

  export type BlueBoxUpdate = z.infer<typeof blueBoxUpdate>;
  export const blueBoxUpdateSchema = blueBoxUpdate;
  export type ShipBobUpdate = z.infer<typeof shipBobUpdate>;
  export const shipBobUpdateSchema = shipBobUpdate;
  export type ShipHeroUpdate = z.infer<typeof shipHeroUpdate>;
  export const shipHeroUpdateSchema = shipHeroUpdate;

  export type ShippingProviderUpdate = z.infer<typeof shippingProviderUpdate>;
  export const shippingProviderUpdateSchema = shippingProviderUpdate;

  export type VesylUpdate = z.infer<typeof vesylUpdate>;
  export const vesylUpdateSchema = vesylUpdate;

  export type RechargeUpdate = z.infer<typeof rechargeUpdate>;
  export const rechargeUpdateSchema = rechargeUpdate;

  export type EasyPostUpdate = z.infer<typeof easyPostUpdate>;
  export const easyPostUpdateSchema = easyPostUpdate;

  export type StatusConfigUpdate = z.infer<typeof statusConfigUpdate>;
  export const statusConfigUpdateSchema = statusConfigUpdate;

  export const settingsIntegrationUpdateSchema = settingsIntegrationUpdate;

  // ---------- Products ------------

  export type StoreProduct = z.infer<typeof storeProduct>;
  export const storeProductSchema = storeProduct;

  export type StoreProductType = z.infer<typeof productType>;
  export const storeProductTypeSchema = productType;

  export type StoreProductTag = z.infer<typeof productTag>;
  export const storeProductTagSchema = productTag;

  export type StoreProductCollection = z.infer<typeof productCollection>;
  export const storeProductCollectionSchema = productCollection;

  export type StoreProductGroup = z.infer<typeof storeProductGroup>;
  export const storeProductGroupSchema = storeProductGroup;

  export type StoreProductGroupCreate = z.infer<typeof storeProductGroupCreate>;
  export const storeProductGroupCreateSchema = storeProductGroupCreate;

  export type StoreProductsForGroup = z.infer<typeof storeProductMinimum>;
  export const storeProductsForGroupSchema = storeProductMinimum;

  export type StoreProductGroupUpdate = z.infer<typeof storeProductGroup>;
  export const storeProductGroupUpdateSchema = storeProductGroup;

  export type ApiClientResp = z.infer<typeof apiClientResp>;
  export const apiClientRespSchema = apiClientResp;

  // ---------- Shipment ------------
  export type ReturnShipmentTracker = z.infer<typeof returnShipmentTracker>;

  export type MerchantOnboard = z.infer<typeof merchantOnboard>;
  export const merchantOnboardSchema = merchantOnboard;

  // ---------- Store Rules + Array Fact Data (Pegasus) ------------
  export type ArrayFactData = z.infer<typeof arrayFactData>;
  export const arrayFactDataSchema = arrayFactData;

  export type StoreRule = z.infer<typeof storeRule>;
  export const storeRuleSchema = storeRule;

  export type StoreRuleUpdate = z.infer<typeof storeRule>;
  export const storeRuleUpdateSchema = storeRule;

  export type StoreRuleCreate = z.infer<typeof storeRuleCreate>;
  export const storeRuleCreateSchema = storeRuleCreate;

  export type CustomField = z.infer<typeof customField>;
  export const customFieldSchema = customField;

  export type CustomFieldCreate = z.infer<typeof customFieldCreate>;
  export const customFieldCreateSchema = customFieldCreate;

  export type CustomFieldUpdate = z.infer<typeof customFieldUpdate>;
  export const customFieldUpdateSchema = customFieldUpdate;

  // ---------- Uncategorized ----------
  export type CrewOrder = z.infer<typeof crewOrder>;
  export const crewOrderSchema = crewOrder;

  export type ClaimCreate = z.infer<typeof claimCreate>;
  export const claimCreateSchema = claimCreate;

  export type VariantExchangeOptions = z.infer<
    typeof variantExchangeOptionsSchema
  >;
  export const variantExchangeOptionsSchema = variantExchange;

  // ----------  GSP Claim  ----------
  export type ShippingClaim = z.infer<typeof shippingClaimMerchant>;
  export const shippingClaimSchema = shippingClaimMerchant;

  export type ShippingClaimList = z.infer<typeof shippingClaimList>;
  export const shippingClaimListSchema = shippingClaimList;

  export type ShippingClaimFinalize = z.infer<typeof shippingClaimFinalize>;
  export const shippingClaimFinalizeSchema = shippingClaimFinalize;

  export type ShippingClaimCreate = z.infer<typeof shippingClaimCreateMerchant>;
  export const shippingClaimCreateSchema = shippingClaimCreateMerchant;

  // ---------- Store User Welcome ----------
  export type StoreUserSignupCreate = z.infer<typeof storeUserSignUp>;
  export const storeUserSignupCreateSchema = storeUserSignUp;

  export type StoreUserSignup = z.infer<typeof userSignUp>;
  export const storeUserSignUpSchema = userSignUp;

  export type StoreUserSignupAndOnboard = z.infer<typeof userSignUpAndOnboard>;
  export const storeUserSignUpAndOnboardSchema = userSignUpAndOnboard;

  // ------------- Registration -----------

  export type Registration = z.infer<typeof registration>;
  export const registrationSchema = registration;

  export type RegistrationList = z.infer<typeof registrationList>;
  export const registrationListSchema = registrationList;
}
