import { z } from 'zod';
import { RoleCode } from '../../../enums/enum.js';
import { enumToZodLiteralUnion } from '../../../zodExtensions.js';

export const storeFulfillmentLocation = z.object({
  idFromPlatform: z.string(),
  name: z.string(),
});

const store = z.object({
  id: z.number(),
  name: z.string(),
  estimatedAnnualReturns: z.number().optional(),
  invoiceDefinition: z
    .object({
      endDate: z.string(),
      id: z.number(),
    })
    .optional(),
  customerAppUrl: z.string(),
  currencyCode: z.string(),
  currencySymbol: z.string(),
  isTest: z.boolean().optional(),
  isGspInstalled: z.boolean(),
  isCrewInstalled: z.boolean(),
  fulfillmentLocations: storeFulfillmentLocation.array(),
});

const storeUserRole = z.object({
  storeId: z.number(),
  roleCode: enumToZodLiteralUnion(RoleCode),
  store,
});

const userEmailProperties = z.object({
  email: z.string().trim().email('Invalid email'),
  firstName: z.string().min(1, 'First name is required'),
  lastName: z.string().min(1, 'Last name is required'),
});

// user loaded into the UI containing all the store user roles for the user
export const userWithStoreUserRoles = z
  .object({
    id: z.number(),
    idFromPlatform: z.string(),
    storeUserRoles: z.array(storeUserRole),
  })
  .merge(userEmailProperties);

//! not to future use, the merchant admin operates with a single role for a user
export const storeUser = userWithStoreUserRoles
  .omit({
    storeUserRoles: true,
  })
  .merge(
    z.object({
      storeUserRole: storeUserRole.omit({ store: true }),
    }),
  );

export const storeUserCreate = storeUser
  .omit({
    id: true,
    idFromPlatform: true,
  })
  .required();

export const userSignUp = userEmailProperties;
export const userSignUpAndOnboard = userSignUp.merge(
  z.object({
    estimatedAnnualClaims: z.string(),
    phone: z.string().optional(),
    productsOfInterest: z
      .array(z.string())
      .min(1, 'Select at least one product'),
  }),
);

export const storeUserSignUp = z.discriminatedUnion('isOnboardingComplete', [
  userSignUp.merge(z.object({ isOnboardingComplete: z.literal(true) })),
  userSignUpAndOnboard.merge(
    z.object({ isOnboardingComplete: z.literal(false) }),
  ),
]);

export const storeUserUpdate = storeUser
  .pick({
    id: true,
    idFromPlatform: true,
    firstName: true,
    lastName: true,
    email: true,
    storeUserRole: true,
  })
  .required();
