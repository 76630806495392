import { EnvelopeIcon, HomeIcon, UserIcon } from '@heroicons/react/20/solid';
import { useQuery } from '@tanstack/react-query';
import { CrewMerchantUi } from 'corso-types';
import { PropsWithChildren } from 'react';
import { useParams } from 'react-router-dom';

import api from '~/api';
import Address from '~/components/Address';
import EmptyDetails from '~/components/claim/EmptyDetails';
import ContentWrapper from '~/components/ContentWrapper';
import DescriptionList, { Description } from '~/components/DescriptionList';
import DetailInfoItem from '~/components/DetailInfoItem';
import LineItem from '~/components/LineItem';
import MediaGallery from '~/components/MediaGallery';
import Panel from '~/components/Panel';
import RelativeDateTime from '~/components/RelativeDateTime';
import { useStoreId } from '~/hooks/useStoreId';
import { formatter } from '~/utils/formatter';

function RegistrationGeneralInfo({
  registration,
}: PropsWithChildren<{
  registration: CrewMerchantUi.Registration;
}>) {
  const { externalId, createdOn, registrationChannelName } = registration;

  return (
    <div className="flex flex-col gap-2 bg-white p-4 lg:rounded-t-md">
      <div className="grid grid-cols-1 gap-4 sm:gap-4 md:grid-cols-3">
        <div className="col-span-1 flex flex-col gap-3 md:col-span-3">
          <div className="flex flex-col items-start justify-center">
            <p className="text-lg font-semibold">#{externalId}</p>

            <span className="text-sm">
              <span> {registrationChannelName}</span>
            </span>
            <span className="mt-2 text-xs text-corso-gray-500">
              Created <RelativeDateTime dateTime={createdOn} />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

function RegistrationCustomerDetail({
  registration,
}: {
  registration: CrewMerchantUi.Registration;
}) {
  const { email, customerName, address } = registration;

  return (
    <ContentWrapper>
      <div className="flex flex-col gap-4 rounded-md border p-4 shadow-sm md:mt-8">
        <div className="space-y-3">
          <p className="mb-2 flex items-center justify-between text-sm font-medium">
            <span>Customer</span>
          </p>

          {/* customer name */}
          <DetailInfoItem
            icon={<UserIcon className="h-4 w-4" />}
            content={customerName}
          />

          {/* customer address */}
          {address && (
            <DetailInfoItem
              icon={<HomeIcon className="h-4 w-4" />}
              className="items-start"
              content={<Address address={address} />}
            />
          )}

          {/* customer email */}
          <DetailInfoItem
            icon={<EnvelopeIcon className="h-4 w-4" />}
            content={email}
          />
        </div>
      </div>
    </ContentWrapper>
  );
}

function RegistrationRequest({
  registration,
}: {
  registration: CrewMerchantUi.Registration;
}) {
  const {
    estimatedPurchaseDate,
    lineItems,
    proofOfPurchaseFileUrls,
    registrationChannelName,
    createdOn,
  } = registration;

  const descriptions: Description[] = [];

  descriptions.push({
    details: registrationChannelName,
    term: 'Channel',
  });

  descriptions.push({
    details: <RelativeDateTime dateTime={createdOn} />,
    term: 'Created',
  });

  if (estimatedPurchaseDate) {
    descriptions.push({
      details: formatter.date(estimatedPurchaseDate),
      term: 'Purchase Date',
    });
  }

  if (proofOfPurchaseFileUrls.length) {
    descriptions.push({
      term: 'Proof Of Purchase',
      details: (
        <MediaGallery
          media={proofOfPurchaseFileUrls.map((url) => ({
            src: url,
            alt: 'Proof of Purchase',
          }))}
        />
      ),
    });
  }

  return (
    <>
      <p className="font-medium"> Registration Request Details</p>
      <ContentWrapper>
        <div className="divide-y rounded-md border p-4 shadow-sm [&>*]:py-4 first:[&>*]:pt-0 last:[&>*]:pb-0">
          <DescriptionList descriptions={descriptions} />
          {lineItems?.map((li) => (
            <LineItem
              variant="small"
              key={li.id}
              imageUrl={li.imgUrl}
              sku={li.sku}
              name={li.name}
              quantity={li.quantity}
              options={li.optionsFromPlatform}
            />
          ))}
        </div>
      </ContentWrapper>
    </>
  );
}

export default function RegistrationOverview() {
  const { registrationId } = useParams();
  const storeId = useStoreId();

  const {
    data: registration,
    isLoading,
    isError,
  } = useQuery({
    enabled: !!registrationId,
    queryKey: ['registration', { registrationId, storeId }],
    queryFn: () => {
      if (!registrationId) throw new Error('Missing Registration ID');
      return api.store(storeId).registrations.get({
        registrationId,
      });
    },
    retry: 1,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  if (!registration || isLoading)
    return (
      <EmptyDetails
        claimType="Registration"
        title="Loading Registration"
        description="Please wait while we load the registration."
      />
    );

  if (isError)
    return (
      <EmptyDetails
        claimType="Registration"
        title="Error Loading Registration"
        description="Please try again later."
      />
    );

  return (
    <>
      <RegistrationGeneralInfo registration={registration} />
      <Panel className="lg:rounded-t-none">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-[3fr_1fr]">
          <div className="space-y-2">
            <RegistrationRequest registration={registration} />
          </div>
          <div className="order-first md:order-none">
            <RegistrationCustomerDetail registration={registration} />
          </div>
        </div>
      </Panel>
    </>
  );
}
