import { Outlet } from 'react-router-dom';
import Auth0ProviderWithNavigate from './Auth0ProviderWithNavigate';
import { AuthenticationProvider } from './AuthenticationProvider';
import { MerchantProvider } from './MerchantProvider';
import { SettingsActionsProvider } from './SettingsActionsProvider';

export default function Providers() {
  return (
    <Auth0ProviderWithNavigate>
      <AuthenticationProvider>
        <MerchantProvider>
          <SettingsActionsProvider>
            <Outlet /> {/* out to all other components */}
          </SettingsActionsProvider>
        </MerchantProvider>
      </AuthenticationProvider>
    </Auth0ProviderWithNavigate>
  );
}
