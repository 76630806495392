import { ChevronLeftIcon } from '@heroicons/react/20/solid';
import { Link, useNavigate } from 'react-router-dom';

/**
 * A simple text link to navigate back.
 * Similar to clicking the back button in the browser, except it will not keep any query parameters.
 */
function BackLink({
  text,
}: {
  /** Shown as "Back" or "Back to {text}". */
  text?: string;
}) {
  return (
    <Link
      to=".."
      className="flex items-center gap-1 text-sm text-corso-gray-600 hover:text-corso-gray-800 hover:underline"
    >
      <ChevronLeftIcon className="h-4 w-4" aria-hidden="true" />
      Back{text ? ` to ${text}` : ''}
    </Link>
  );
}

/**
 * Visually shows as a text link to navigate back.
 * Equivalent to clicking the back button in the browser.
 */
function BackButton({
  text,
}: {
  /** Shown as "Back" or "Back to {text}". */
  text?: string;
}) {
  const navigate = useNavigate();
  return (
    <button
      type="button"
      className="flex items-center gap-1 text-sm text-corso-gray-600 hover:text-corso-gray-800"
      onClick={() => navigate(-1)}
    >
      <ChevronLeftIcon className="h-4 w-4" aria-hidden="true" />
      Back{text ? ` to ${text}` : ''}
    </button>
  );
}

export default {
  Link: BackLink,
  Button: BackButton,
};
