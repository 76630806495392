import {
  ArrowDownOnSquareIcon,
  ArrowTopRightOnSquareIcon,
} from '@heroicons/react/24/outline';
import React from 'react';
import { twMerge } from 'tailwind-merge';

type DetailSectionProps =
  | {
      href: string;
      variant: 'download' | 'link';
      content: React.ReactNode; // when `href` exists, `content` will be rendered as a link
      icon: React.ReactNode;
      className?: string;
    }
  | {
      href?: never; // no `href` when rendering `content` directly
      variant?: never;
      content: React.ReactNode;
      icon: React.ReactNode;
      className?: string;
    };

export default function DetailInfoItem({
  content,
  href,
  icon,
  className,
  variant,
}: DetailSectionProps) {
  if (!content) return null;

  return (
    <div
      className={twMerge('flex gap-1 text-xs text-corso-gray-500', className)}
    >
      <div className="flex">
        <div className="mr-1 w-5">{icon}</div>

        {href ?
          <a
            className="flex gap-1 hover:underline focus:underline"
            href={href}
            target={variant === 'link' ? '_blank' : '_self'}
            rel="noreferrer"
          >
            {content}
            {variant &&
              (variant === 'link' ?
                <ArrowTopRightOnSquareIcon className="h-4 w-4" />
              : <ArrowDownOnSquareIcon className="h-4 w-4" />)}
          </a>
        : content}
      </div>
    </div>
  );
}
