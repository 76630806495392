import { PlayCircleIcon } from '@heroicons/react/20/solid';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import Alert from './Alert';
import FallbackImage from './FallbackImage';
import Icon from './Icon';
import SpinnerIcon from './SpinnerIcon';
import { Dialog, DialogContent, DialogTitle } from './ui/primitives/Dialog';

/**
 * Identifies a handful of specific `Content-Type` categories using the `HEAD` method.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types#discrete_types
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/HEAD
 * Missing, or non-matching `Content-Type` headers will default `mediaType` to `null`.
 */
function useMediaContentType(src: string) {
  return useQuery({
    queryKey: ['content-type', src],
    queryFn: async () => {
      const response = await fetch(src, { method: 'HEAD' });
      if (!response.ok) throw response;
      const contentType = response.headers.get('Content-Type');

      if (contentType?.startsWith('image/')) {
        return { mediaType: 'image/*', contentType } as const;
      }
      if (contentType?.startsWith('video/')) {
        return { mediaType: 'video/*', contentType } as const;
      }
      return { mediaType: null, contentType } as const;
    },
    throwOnError: false,
  });
}

function MediaLoader() {
  return (
    <div className="absolute inset-0 flex items-center justify-center">
      <SpinnerIcon />
    </div>
  );
}

function ImageMediaDisplay({ src, alt }: { src: string; alt: string }) {
  const [loaded, setLoaded] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  return (
    <div className="relative size-full">
      {!loaded && <MediaLoader />}
      <FallbackImage
        loading="lazy"
        src={src}
        alt={alt}
        className="size-full cursor-pointer object-cover object-center"
        onLoad={() => setLoaded(true)}
        onClick={() => setDialogOpen(true)}
        onKeyDown={(e) => e.key === 'Enter' && setDialogOpen(true)}
      />
      <Dialog modal open={dialogOpen} onOpenChange={setDialogOpen}>
        <DialogContent
          className="max-h-[75svh] w-max max-w-[75vw] p-2"
          aria-describedby={undefined} // explicitly not described through a description (this is not the title/label), resolves relevant a11y warning
        >
          <DialogTitle className="sr-only">Large Preview of {alt}</DialogTitle>
          <a
            href={src}
            target="_blank"
            rel="noreferrer"
            className="block size-full max-h-[768px] max-w-[1024px] overflow-auto"
          >
            <FallbackImage
              loading="lazy"
              src={src}
              alt={alt}
              className="size-full rounded-md object-contain object-center"
            />
          </a>
        </DialogContent>
      </Dialog>
    </div>
  );
}

function VideoMediaDisplay({ src }: { src: string }) {
  // we need to revisit video display so that it shows as a thumbnail and opens in a modal for playback
  return (
    <a
      href={src}
      target="_blank"
      rel="noreferrer"
      className="grid size-full items-center justify-center bg-black"
    >
      {/* eslint-disable-next-line jsx-a11y/media-has-caption -- no caption content available */}
      <video
        src={src}
        className="pointer-events-none col-span-full row-span-full size-full object-cover"
      />
      <div className="col-span-full row-span-full flex items-center justify-center">
        <Icon icon={PlayCircleIcon} className="size-12 text-white" />
      </div>
    </a>
  );
}

/**
 * Displays a preview of the media content at the provided `src`, so long as it's a supported type.
 * The `alt` is used as a fallback for image media.
 */
export default function MediaDisplay({
  src,
  alt,
}: {
  src: string;
  alt: string; // ? we may want to make this more flexible and used by other media types
}) {
  const {
    data: types,
    isPending,
    isLoading,
    isError,
    error,
  } = useMediaContentType(src);

  useEffect(() => {
    if (!error) return;
    console.error(`Failed to Fetch Media Content Type for: ${src}`, error);
  }, [error, src]);

  if (isError) {
    return (
      <Alert
        variant="danger"
        // title="Media Load Error"
        message="An error occurred loading this media."
      />
    );
  }

  if (isPending || isLoading) {
    return (
      <figure className="relative h-full w-full overflow-clip rounded-md">
        <MediaLoader />
      </figure>
    );
  }

  if (!types?.mediaType) {
    // ? maybe we add a download button or link for unsupported types
    return (
      <Alert
        variant="warning"
        title="Unsupported Media Type"
        message={`Preview of ${types?.contentType ? `"${types.contentType}"` : 'unknown'} content is not supported.`}
      />
    );
  }

  return (
    <figure className="h-full w-full overflow-clip rounded-md">
      {types.mediaType === 'video/*' && <VideoMediaDisplay src={src} />}
      {types.mediaType === 'image/*' && (
        <ImageMediaDisplay src={src} alt={alt} />
      )}
    </figure>
  );
}
