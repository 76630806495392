import { subDays } from 'date-fns';
import { PropsWithChildren } from 'react';
import { DateRange } from 'react-day-picker';
import { TextInput } from '~/components/field';
import DateRangePicker from '~/components/field/DateRangePicker';
import InfoPopover from '~/components/InfoPopover';
import Panel from '~/components/Panel';
import { Label } from '~/components/ui/primitives/Label';
import useNavigateSearch from '~/hooks/useNavigateSearch';

/** Using a `dateRange` gets a usable and well-defined known date range. */
function getDefinedDateRange(dateRange?: DateRange) {
  const { from, to } = dateRange ?? {};
  if (from && to) return { from, to };
  if (from) return { from, to: from }; // ? may not be useful as this is likely one point in time
  return { from: subDays(new Date(), 30), to: new Date() };
}

type RegistrationSearchProps = PropsWithChildren<{
  searchTerm: string;
  dateRange: {
    from: Date;
    to: Date;
  };
}>;

function SharedPopover({ content }: { content: string }) {
  return (
    <InfoPopover title="Search Details" anchor="left">
      <InfoPopover.Prose>{content}</InfoPopover.Prose>
    </InfoPopover>
  );
}

/** Search updates the navigable URL, which should trigger updates in state. */
export default function RegistrationSearch({
  searchTerm,
  dateRange,
  children,
}: RegistrationSearchProps) {
  const navigateSearch = useNavigateSearch();

  return (
    <Panel>
      <div className="flex flex-col gap-2">
        <Label className="flex items-center gap-0.5">
          Search
          <SharedPopover
            content="Search by email, registration number, or customer
            name."
          />
        </Label>

        <TextInput
          id="search"
          label="Search"
          className="flex-grow"
          placeholder="Search registrations"
          onChange={(e) => {
            // TODO consider debouncing this to avoid excessive network requests
            navigateSearch(new URLSearchParams({ searchTerm: e.target.value }));
          }}
          value={searchTerm}
          labelVisuallyHidden
        />
      </div>

      <div className="flex flex-col gap-2">
        <Label>Created Date</Label>

        <DateRangePicker
          defaultRange={dateRange}
          onSelect={(selectedDateRange) => {
            const definedDateRange = getDefinedDateRange(selectedDateRange);
            navigateSearch(
              new URLSearchParams({
                startDate: definedDateRange.from.toISOString(),
                endDate: definedDateRange.to.toISOString(),
              }),
            );
          }}
        />
      </div>

      {children}
    </Panel>
  );
}
