export default function Address({
  name,
  address,
}: {
  name?: string;
  address: {
    line1: string;
    line2?: string | null;
    city: string;
    stateOrProvinceCode?: string | null;
    postalCode: string;
    countryCode: string;
  };
}) {
  return (
    <p>
      {name && (
        <>
          {name} <br />{' '}
        </>
      )}
      {address.line1} {address.line2}
      <br />
      {address.city}, {address.stateOrProvinceCode} {address.postalCode}
      <br />
      {address.countryCode}
    </p>
  );
}
