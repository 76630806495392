import FullPageStatus from '~/components/FullPageStatus';

export default function NotFound() {
  return (
    <FullPageStatus
      statusCode={404}
      title="Page not found"
      message="Sorry, we couldn't find the page you're looking for."
    />
  );
}
