import {
  faAddressBook,
  faAt,
  faBadgeCheck,
  faBell,
  faBox,
  faCircleExclamation,
  faFeather,
  faGrid2,
  faHatWizard,
  faPaintBrush,
  faPlugCirclePlus,
  faRectangleHistory,
  faSwapArrows,
  faUserGroupSimple,
  faUserSecret,
  IconDefinition,
} from '@fortawesome/pro-light-svg-icons';

import {
  faAddressBook as faAddressBookSolid,
  faAt as faAtSolid,
  faBadgeCheck as faBadgeCheckSolid,
  faBell as faBellSolid,
  faBox as faBoxSolid,
  faFeather as faFeatherSolid,
  faGrid2 as faGrid2Solid,
  faHatWizard as faHatWizardSolid,
  faPaintBrush as faPaintBrushSolid,
  faPlugCirclePlus as faPlugCirclePlusSolid,
  faRectangleHistory as faRectangleHistorySolid,
  faSwapArrows as faSwapArrowsSolid,
  faUserGroupSimple as faUserGroupSimpleSolid,
  faUserSecret as faUserSecretSolid,
} from '@fortawesome/pro-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Transition } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import {
  ArrowUturnLeftIcon,
  ChevronRightIcon,
  DocumentCheckIcon,
} from '@heroicons/react/24/outline';
import { isTruthy } from 'corso-types';
import React, { useCallback, useMemo, useState } from 'react';
import {
  Link,
  NavLink,
  Outlet,
  useBeforeUnload,
  useBlocker,
} from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import Button from '~/components/Button';
import ConfirmModal from '~/components/ConfirmModal';
import Page from '~/components/Page';
import SpinnerIcon from '~/components/SpinnerIcon';
import { Badge } from '~/components/ui/primitives/Badge';
import { useEnabledClaimType } from '~/hooks/useEnabledClaimType';
import { useSettingsActionsContext } from '~/providers/SettingsActionsProvider';
import { SettingsPageForm } from '~/types';

// TODO review ALL settings pages text content for accuracy and clarity with the product team
// TODO review ALL settings error messages for accuracy and clarity with the product team
// TODO review settings validation around validity days and required fields; i.e. all fields are required to send data, but validity days can impact if a field will be applied

function SettingsNavigation({ pageForm }: { pageForm: SettingsPageForm }) {
  const { isReturnOrWarrantyEnabled } = useEnabledClaimType();

  const settingsNavigation = useMemo(() => {
    const returnOrWarrantyItems = [
      {
        name: 'Automations',
        to: 'automations',
        icon: faHatWizard,
        activeIcon: faHatWizardSolid,
        badge: null,
      },
      {
        name: 'Registrations',
        badge: <Badge variant="beta">Beta</Badge>,
        to: 'registrations',
        icon: faAddressBook,
        activeIcon: faAddressBookSolid,
      },
      {
        name: 'Email',
        to: 'email',
        icon: faAt,
        activeIcon: faAtSolid,
        badge: null,
      },
      {
        name: 'Reasons',
        to: 'reasons',
        icon: faGrid2,
        activeIcon: faGrid2Solid,
        badge: null,
      },
      {
        name: 'Custom Fields',
        to: 'custom-fields',
        icon: faFeather,
        activeIcon: faFeatherSolid,
        badge: null,
      },
      {
        name: 'Product Groups',
        to: 'product-groups',
        icon: faRectangleHistory,
        activeIcon: faRectangleHistorySolid,
        badge: null,
      },
      {
        name: 'Notifications',
        to: 'notifications',
        icon: faBell,
        activeIcon: faBellSolid,
        badge: null,
      },
      {
        name: 'Shipping Policies',
        to: 'shipping-policies',
        icon: faBox,
        activeIcon: faBoxSolid,
        badge: null,
      },
    ]
      .filter(isTruthy)
      .map((i) => ({
        ...i,
        isEnabled: isReturnOrWarrantyEnabled,
      }));

    const navigationItems = [
      {
        name: 'Theme',
        to: 'theme',
        icon: faPaintBrush,
        activeIcon: faPaintBrushSolid,
        badge: null,
      },
      {
        name: 'Users',
        to: 'users',
        icon: faUserGroupSimple,
        activeIcon: faUserGroupSimpleSolid,
        badge: null,
      },
      {
        name: 'Integrations',
        to: 'integrations',
        icon: faPlugCirclePlus,
        activeIcon: faPlugCirclePlusSolid,
        badge: null,
      },
      {
        name: 'Returns',
        to: 'returns',
        icon: faSwapArrows,
        activeIcon: faSwapArrowsSolid,
        badge: null,
      },
      {
        name: 'Warranties',
        to: 'warranties',
        icon: faBadgeCheck,
        activeIcon: faBadgeCheckSolid,
        badge: null,
      },
      {
        name: 'Shipping Protection',
        to: 'shipping-protection',
        icon: faUserSecret,
        activeIcon: faUserSecretSolid,
        badge: null,
      },
      ...returnOrWarrantyItems,
    ].map((i) => ({ isEnabled: true, ...i }));

    return navigationItems.filter(Boolean) satisfies {
      isEnabled: boolean;
      name: string;
      badge: React.ReactNode | null;
      to: string;
      icon: IconDefinition;
      activeIcon: IconDefinition;
    }[];
  }, [isReturnOrWarrantyEnabled]);

  return (
    <nav className="flex flex-col overflow-hidden bg-white shadow-md lg:rounded-lg">
      {/* // ? should this be a `Disclosure` or `Menu` on mobile */}
      <ul className="flex flex-col">
        {settingsNavigation.map((navigation) => (
          <li key={navigation.name}>
            <NavLink
              to={navigation.to}
              className={({ isActive }) =>
                twMerge(
                  'group flex items-center justify-between p-4 text-sm font-semibold',
                  isActive ?
                    'border-s-2 border-corso-blue-600 bg-corso-gray-100 text-corso-blue-600'
                  : 'border-s-2 border-transparent text-corso-gray-800 hover:bg-corso-gray-100 hover:text-corso-blue-600 focus:bg-corso-gray-100',
                  !navigation.isEnabled && 'text-corso-gray-600',
                )
              }
            >
              {({ isActive }) => (
                <>
                  <div className="flex items-center gap-2.5">
                    {isActive ?
                      <FontAwesomeIcon
                        icon={navigation.activeIcon}
                        fixedWidth
                        size="lg"
                      />
                    : <>
                        <FontAwesomeIcon
                          icon={navigation.activeIcon}
                          fixedWidth
                          size="lg"
                          className="hidden group-hover:block group-focus:block"
                        />
                        <FontAwesomeIcon
                          icon={navigation.icon}
                          fixedWidth
                          size="lg"
                          className="group-hover:hidden group-focus:hidden"
                        />
                      </>
                    }
                    {navigation.name}{' '}
                    {navigation.badge ? navigation.badge : null}
                  </div>
                  <div className="flex items-center gap-2">
                    {pageForm?.state.isDirty && isActive && (
                      <div
                        className="h-2 w-2 rounded-full bg-corso-blue-600"
                        title={`Unsaved changes in ${navigation.name} Settings`}
                      />
                    )}
                    {/* // * form section name must match URL path for this to display an error badge as expected */}
                    {pageForm?.state.hasErrors && isActive && (
                      <FontAwesomeIcon
                        icon={faCircleExclamation}
                        className="h-5 w-5 text-corso-red-600"
                        title={`Errors in ${navigation.name} Settings`}
                      />
                    )}
                  </div>
                </>
              )}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
}

function ActionItems() {
  const { actions } = useSettingsActionsContext();
  const incomplete = actions.filter((item) => !item.isComplete).length;

  return (
    <Transition
      show={incomplete > 0}
      enter="ease-out duration-300"
      enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      enterTo="opacity-100 translate-y-0 sm:scale-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
      leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    >
      <section className="overflow-hidden bg-white shadow-md lg:rounded-lg">
        <h2 className="mx-4 mb-2 mt-4 text-lg font-medium">Action Items</h2>
        <p className="mx-4 text-xs text-corso-gray-500">
          To get started, complete the following items to configure your
          settings.{' '}
          <span>
            <a
              className="text-corso-blue-600 hover:text-corso-blue-500 hover:underline"
              href="https://help.corso.com/crew#action-items-checklist"
              target="_blank"
              rel="noreferrer"
            >
              Learn More
            </a>
          </span>
        </p>
        <ul className="my-4 flex flex-col">
          {actions.map((action) => (
            <li key={action.label}>
              <Link
                to={action.to}
                className="flex items-center justify-between gap-x-4 px-4 py-4 text-sm text-corso-gray-800 hover:bg-corso-gray-50 hover:text-corso-blue-600"
              >
                <div className="flex items-center gap-2">
                  {action.isComplete ?
                    <CheckCircleIcon
                      className="h-5 w-5 text-green-600"
                      aria-label="Completed"
                    />
                  : <svg
                      aria-hidden="true"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="none"
                      stroke="currentColor"
                      aria-label="Incomplete"
                    >
                      <circle cx="10" cy="10" r="8" />
                    </svg>
                  }

                  {action.label}
                </div>
                <ChevronRightIcon className="h-3 w-3 stroke-2" />
              </Link>
            </li>
          ))}
        </ul>
      </section>
    </Transition>
  );
}

/** Sub-layout for `/settings` Pages */
export default function SettingsLayout() {
  const [pageForm, setPageForm] = useState<SettingsPageForm>(null);
  const showFormActions =
    Boolean(pageForm?.state.isDirty) || Boolean(pageForm?.state.isSubmitting);

  /**
   * ! USING UNSTABLE API
   * @see https://github.com/remix-run/react-router/issues/8139
   * Using `useBlocker` instead of `usePrompt` due to compatibility concerns.
   */
  // ? used to display custom prompt on SPA navigation
  const blocker = useBlocker(!!pageForm?.state.isDirty);

  // ? handles `beforeunload` event to display native browser prompt such as refresh
  const onBeforeUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      if (!pageForm?.state.isDirty) return;

      // cancel the event as stated by the standard
      event.preventDefault();
      /** set `returnValue` to help with compatibility @see https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event#compatibility_notes */
      // eslint-disable-next-line no-param-reassign
      event.returnValue = '';
    },
    [pageForm],
  );

  // could use `useEffect`, but this abstraction is already available and does the handler cleanup
  useBeforeUnload(onBeforeUnload);

  // TODO need a skeleton zero state while settings are loading
  return (
    <Page headline="Settings">
      <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-[minmax(0,1fr)_minmax(0,3fr)]">
        {/* // * top-16 is a magic value so that it doesn't slip behind the app layout navigation */}
        <aside className="flex flex-col gap-4 lg:sticky lg:top-16">
          <ActionItems />
          <SettingsNavigation pageForm={pageForm} />
        </aside>
        <article>
          <Outlet context={setPageForm} />

          <Transition
            show={showFormActions}
            className="fixed bottom-6 right-6 z-10 flex flex-col flex-wrap items-end gap-6"
          >
            <Transition.Child
              enter="duration-300 ease-out"
              enterFrom="opacity-0 scale-50"
              enterTo="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-50"
            >
              <Button
                className="max-w-fit px-2 shadow-md"
                onClick={() => {
                  pageForm?.reset();
                }}
                aria-label="Revert Changes"
              >
                <ArrowUturnLeftIcon className="h-5 w-5" />
              </Button>
            </Transition.Child>
            <Transition.Child
              enter="duration-300 ease-out"
              enterFrom="opacity-0 scale-50"
              enterTo="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-50"
            >
              <Button
                type="submit"
                variant="primary"
                className="min-w-[6rem] p-4 shadow-md"
                form={pageForm?.id}
                disabled={pageForm?.state.isSubmitting}
              >
                {/* // TODO this will flicker when the submit is complete, need to polish it up sometime. */}
                {pageForm?.state.isSubmitting ?
                  <SpinnerIcon />
                : <>
                    <DocumentCheckIcon
                      className="h-5 w-5"
                      title="Save Changes"
                    />{' '}
                    Save
                  </>
                }
              </Button>
            </Transition.Child>
          </Transition>

          <ConfirmModal
            title="Unsaved Settings"
            show={blocker.state === 'blocked'}
            prompt="You have unsaved changes. Do you wish to proceed and discard your changes?"
            confirmText="Discard Changes"
            cancelText="Return to Page"
            onConfirm={() => {
              pageForm?.reset();
              blocker.proceed?.();
            }}
            onCancel={() => {
              blocker.reset?.();
              // ? maybe this could instead just offer to save the changes; however, this would also trigger when dismissing the modal outside of the buttons
            }}
          />
        </article>
      </div>
    </Page>
  );
}
