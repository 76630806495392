import { twMerge } from 'tailwind-merge';

const sizeStyle = {
  base: 'h-8',
  lg: 'h-16',
  xl: 'h-24',
};

export default function CorsoIcon({
  size = 'base',
}: {
  size?: keyof typeof sizeStyle;
}) {
  // a real version of this SVG should be exported through Adobe Illustrator to optimize the `viewBox`
  return (
    <svg
      className={twMerge(sizeStyle[size], 'fill-current')}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 200 234.59"
    >
      <g>
        <path d="M0,117.3c0,58.36,42.95,106.67,98.96,115.09v-62.37c-22.13-7.31-38.12-28.13-38.12-52.72s15.99-45.4,38.12-52.72V2.21 C42.95,10.62,0,58.94,0,117.3z" />
        {/* maybe could be simplified as a `circle` */}
        <path d="M161.04,76.44c17.83,0,32.29-14.46,32.29-32.29c0-17.83-14.46-32.29-32.29-32.29s-32.29,14.46-32.29,32.29 C128.75,61.98,143.2,76.44,161.04,76.44z" />
        <circle cx="161.04" cy="190.45" r="32.29" />
      </g>
    </svg>
  );
}
