import {
  CrewClaimResolutionMethodEnum,
  crewClaimResolutionMethodEnumName,
  CrewClaimStatusCode,
  CrewClaimTypeEnum,
} from 'corso-types/enums/crew';
import { toEnum } from 'corso-types/typing';
import { useFieldArray } from 'react-hook-form';
import Alert from '~/components/Alert';
import ContentWrapper from '~/components/ContentWrapper';
import Disclosure, { SimpleSummary } from '~/components/Disclosure';
import LineItem from '~/components/LineItem';
import { useConfigSettings } from '~/hooks/useConfigSettings';
import {
  ReviewableResolutionMethod,
  reviewableResolutionMethods,
  unsupportedResolutionMethods,
  useClaimReviewContext,
} from '~/providers/ClaimReviewProvider';
import ClaimLineItem from './ClaimLineItem';
import ClaimLineItemActions from './ClaimLineItemActions';
import CustomerMedia from './CustomerMedia';
import Inspection from './Inspection';

function shouldShowInspectionSection(
  claimType: 'Return' | 'Warranty',
  isWarrantyInspectionEnabled: boolean,
  isReturnInspectionEnabled: boolean,
) {
  if (claimType === 'Warranty' && isWarrantyInspectionEnabled) return true;

  if (claimType === 'Return' && isReturnInspectionEnabled) return true;

  return false;
}

function ReviewableClaimLineItemsList({
  resolutionMethod,
}: {
  resolutionMethod: ReviewableResolutionMethod;
}) {
  const { claimReview } = useClaimReviewContext();
  const claim = claimReview.watch('claim');

  const reviewLineItemsFields = useFieldArray({
    control: claimReview.control,
    name: `claim.reviewLineItems.${resolutionMethod}`,
  });
  const reviewLineItems = claim.reviewLineItems[resolutionMethod];
  const { claimType } = claim;

  const { data } = useConfigSettings(({ returns, warranties }) => ({
    returns,
    warranties,
  }));

  const { returns, warranties } = data ?? {};

  const showInspectionSection = shouldShowInspectionSection(
    claimType,
    !!warranties?.isWarrantyInspectionEnabled,
    !!returns?.isReturnInspectionEnabled,
  );

  return (
    <ContentWrapper>
      <Disclosure
        defaultOpen
        key={claim.id} // used to help re-rendering the component to reset `defaultOpen` if it exists within the same place in the DOM but surrounding components change
        renderSummary={() => (
          <SimpleSummary>
            <span className="text-md ml-1 font-medium text-corso-gray-500">
              {crewClaimResolutionMethodEnumName[resolutionMethod]}s{' '}
            </span>
          </SimpleSummary>
        )}
      >
        <div className="flex flex-col gap-2">
          {reviewLineItems.map((reviewLineItem, i) => {
            const { claimLineItem } = reviewLineItem;
            const alreadyResolved =
              claimLineItem.status.code === CrewClaimStatusCode.approved ||
              claimLineItem.status.code === CrewClaimStatusCode.denied;

            return (
              <ClaimLineItem
                claimLineItem={claimLineItem}
                key={claimLineItem.id}
              >
                <div className="mt-2 flex flex-col gap-4 pb-3 text-xs text-corso-gray-500 lg:pb-4">
                  <section>
                    <span className="font-medium">Reason:</span>{' '}
                    <span>
                      {claimLineItem.reason.name}
                      {claimLineItem.reason.detail?.name &&
                        ` - ${claimLineItem.reason.detail.name}`}
                    </span>
                  </section>

                  {!!claimLineItem.comments?.length && (
                    <section>
                      <span className="font-medium">Comment:</span>{' '}
                      <span>{claimLineItem.comments}</span>
                    </section>
                  )}
                  {!!claimLineItem.customFields.length && (
                    <section>
                      <ul>
                        {claimLineItem.customFields.map((field) => (
                          <li key={field.id}>
                            <span className="font-medium">
                              {field.displayName}:
                            </span>{' '}
                            {field.value}
                          </li>
                        ))}
                      </ul>
                    </section>
                  )}
                  {!!claimLineItem.noteToCustomer?.length && (
                    <section>
                      <span className="font-medium">Note to Customer:</span>{' '}
                      <span>{claimLineItem.noteToCustomer}</span>
                    </section>
                  )}
                  {!!reviewLineItem.noteToCustomer?.length && (
                    <section>
                      <span className="font-medium">Note to Customer:</span>{' '}
                      <span>{reviewLineItem.noteToCustomer}</span>
                    </section>
                  )}
                  {claimLineItem.requestedResolutionMethodEnum ===
                    CrewClaimResolutionMethodEnum.variantExchange &&
                    claimLineItem.variantExchangeLineItem && (
                      <section>
                        <h6 className="mb-2 font-medium">Exchange For:</h6>
                        <LineItem
                          variant="small"
                          sku={claimLineItem.variantExchangeLineItem.sku}
                          name={claimLineItem.variantExchangeLineItem.name}
                          imageUrl={
                            claimLineItem.variantExchangeLineItem.imgUrl
                          }
                          options={
                            claimLineItem.variantExchangeLineItem
                              .optionsFromPlatform
                          }
                          quantity={
                            claimLineItem.variantExchangeLineItem.quantity
                          }
                        />
                      </section>
                    )}
                </div>
                <div className="flex flex-col gap-2">
                  <CustomerMedia assets={claimLineItem.images} />

                  {showInspectionSection && (
                    <Inspection lineItem={claimLineItem} />
                  )}

                  {!alreadyResolved && (
                    <ClaimLineItemActions
                      claimType={toEnum(claimType, CrewClaimTypeEnum)}
                      reviewLineItem={reviewLineItem}
                      onChange={
                        (updatedReviewLineItem) =>
                          reviewLineItemsFields.update(i, updatedReviewLineItem) // ! potentially concerning since this accepts `unknown`
                      }
                    />
                  )}
                </div>
              </ClaimLineItem>
            );
          })}
        </div>
      </Disclosure>
    </ContentWrapper>
  );
}

export default function ClaimLineItemList() {
  const { claimReview } = useClaimReviewContext();
  const reviewLineItems = claimReview.getValues('claim.reviewLineItems');

  return (
    <ContentWrapper>
      <p className="font-medium">Requested By Customer</p>

      {reviewableResolutionMethods.map(
        (resolutionMethod) =>
          !!reviewLineItems[resolutionMethod].length && (
            <ReviewableClaimLineItemsList
              key={`claimResolutionLineItems-${resolutionMethod}`}
              resolutionMethod={resolutionMethod}
            />
          ),
      )}
      {unsupportedResolutionMethods.map(
        (resolutionMethod) =>
          !!reviewLineItems[resolutionMethod].length && (
            <ContentWrapper
              key={`claimResolutionLineItems-${resolutionMethod}`}
            >
              <Alert
                variant="warning"
                title={`${crewClaimResolutionMethodEnumName[resolutionMethod]} Claims`}
                message={`This claim line cannot be finalized, because it's resolution method is currently unsupported.`}
              />
              {reviewLineItems[resolutionMethod].map((claimLineItem) => (
                <ClaimLineItem
                  key={`claimLineItem-${claimLineItem.id}`}
                  claimLineItem={claimLineItem}
                />
              ))}
            </ContentWrapper>
          ),
      )}
    </ContentWrapper>
  );
}
