import { ArrowsUpDownIcon } from '@heroicons/react/20/solid';
import {
  ArrowRightIcon,
  BuildingStorefrontIcon,
  CalendarIcon,
  ChatBubbleBottomCenterIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  DocumentIcon,
  EnvelopeIcon,
  HashtagIcon,
  HomeIcon,
  PencilIcon,
  PhoneIcon,
  PlusIcon,
  QrCodeIcon,
  TruckIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import {
  CrewClaimResolutionMethodEnum,
  CrewMerchantUi,
  ShipmentMethod,
} from 'corso-types';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import api from '~/api';
import Address from '~/components/Address';
import Alert from '~/components/Alert';
import Button from '~/components/Button';
import ContentWrapper from '~/components/ContentWrapper';
import DetailInfoItem from '~/components/DetailInfoItem';
import { NumberInput, TextInput } from '~/components/field';
import IconAction from '~/components/IconAction';
import Modal from '~/components/Modal';
import RelativeDateTime from '~/components/RelativeDateTime';
import Skeleton from '~/components/Skeleton';
import SpinnerIcon from '~/components/SpinnerIcon';
import { MultiSelect } from '~/components/ui/MultiSelect';
import { Label } from '~/components/ui/primitives/Label';
import SimpleSelect from '~/components/ui/SimpleSelect';
import {
  useClaimTagOptions,
  useClaimTags,
  useClaimTagsUpsert,
} from '~/hooks/useClaimTags';
import { useReturnLocations } from '~/hooks/useReturnLocations';
import { useStoreId } from '~/hooks/useStoreId';
import {
  useClaimReviewContext,
  useInvalidateClaimReview,
} from '~/providers/ClaimReviewProvider';
import { useMerchantContext } from '~/providers/MerchantProvider';
import {
  claimShipmentCreate,
  ClaimShipmentCreate,
  ClaimShipmentQuote,
  ClaimUpdateCustomerInfo,
  claimUpdateCustomerInfo,
  ClaimUpdateFees,
  claimUpdateFees,
  shipmentTypeLabels,
} from '~/types';
import { formatter } from '~/utils/formatter';

const statusLabels = {
  delivered: 'Delivered',
  available_for_pickup: 'Available for Pickup',
  cancelled: 'Cancelled',
  error: 'Error',
  failure: 'Failure',
  in_transit: 'In Transit',
  out_for_delivery: 'Out for Delivery',
  pre_transit: 'Getting Ready To Ship',
  return_to_sender: 'Return to Sender',
  unknown: 'Getting Ready To Ship',
} satisfies Record<
  CrewMerchantUi.ReturnShipmentTracker['latestStatus'],
  string
>;

const rmaProviderLabel = {
  Ship_Hero: 'ShipHero',
  Ship_Bob: 'ShipBob',
  Blue_Box: 'BlueBox',
} satisfies Record<'Ship_Hero' | 'Ship_Bob' | 'Blue_Box', string>;

function EditCustomerInfo({
  show,
  onClose,
}: {
  show: boolean;
  onClose: () => void;
}) {
  const { claimReview } = useClaimReviewContext();

  const claim = claimReview.watch('claim');

  const invalidateClaim = useInvalidateClaimReview();
  const storeId = useStoreId();
  const { userFullName } = useMerchantContext();

  const { mutateAsync: updateCustomerInfo, isPending } = useMutation({
    mutationFn: (customerInfoUpdate: ClaimUpdateCustomerInfo) =>
      api
        .store(storeId)
        .claim(`${claim.id}`, userFullName)
        .updateCustomerInfo(customerInfoUpdate),
    onSuccess: () => invalidateClaim(),
  });

  const formId = 'edit-claim-form';
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm({
    resolver: zodResolver(claimUpdateCustomerInfo),
    defaultValues: {
      id: claim.id,
      shippingAddress: claim.shippingAddress,
      customerEmail: claim.customerEmail,
    } satisfies ClaimUpdateCustomerInfo,
  });

  const closeAndReset = () => {
    onClose();
    reset();
  };

  return (
    <Modal
      title="Edit Customer"
      key={claim.id}
      show={show}
      onClose={closeAndReset}
      actions={
        <>
          <Button onClick={closeAndReset}>Cancel</Button>
          <Button
            variant="primary"
            type="submit"
            form={formId}
            disabled={isPending}
            loading={isPending}
          >
            Save
          </Button>
        </>
      }
    >
      <form
        id={formId}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(
          async ({ id, customerEmail, shippingAddress }) => {
            await updateCustomerInfo({
              id,
              customerEmail,
              shippingAddress,
            });
            onClose();
          },
        )}
        className="flex flex-col gap-4"
      >
        <TextInput
          id="first-name"
          label="First Name"
          autoComplete="first-name"
          required
          {...register('shippingAddress.firstName')}
          error={errors.shippingAddress?.firstName?.message}
        />

        <TextInput
          id="last-name"
          label="Last Name"
          autoComplete="last-name"
          required
          {...register('shippingAddress.lastName')}
          error={errors.shippingAddress?.lastName?.message}
        />

        <TextInput
          id="email"
          label="Email"
          autoComplete="email"
          required
          {...register('customerEmail')}
          error={errors.customerEmail?.message}
        />

        <TextInput
          id="phone"
          label="Phone"
          autoComplete="tel"
          required
          {...register('shippingAddress.phone')}
          error={errors.shippingAddress?.phone?.message}
        />

        <TextInput
          id="shipping-address-line1"
          label="Street Address"
          autoComplete="address-line1"
          required
          {...register('shippingAddress.line1')}
          error={errors.shippingAddress?.line1?.message}
        />

        <TextInput
          id="shipping-address-line2"
          label="Street Address Line 2"
          autoComplete="address-line2"
          {...register('shippingAddress.line2')}
          error={errors.shippingAddress?.line2?.message}
        />

        <TextInput
          id="shipping-address-city"
          label="City"
          autoComplete="address-level2"
          required
          {...register('shippingAddress.city')}
          error={errors.shippingAddress?.city?.message}
        />

        <TextInput
          id="shipping-address-state-or-province"
          label="State or Province Code"
          autoComplete="address-level1"
          required
          {...register('shippingAddress.stateOrProvinceCode')}
          error={errors.shippingAddress?.stateOrProvinceCode?.message}
        />

        <TextInput
          id="shipping-address-postal-code"
          label="Postal Code"
          autoComplete="postal-code"
          required
          {...register('shippingAddress.postalCode')}
          error={errors.shippingAddress?.postalCode?.message}
        />

        <TextInput
          id="shipping-address-country"
          label="Country Code"
          autoComplete="country"
          required
          {...register('shippingAddress.countryCode')}
          error={errors.shippingAddress?.countryCode?.message}
        />
      </form>
    </Modal>
  );
}

function EditFees({ show, onClose }: { show: boolean; onClose: () => void }) {
  const { claimReview } = useClaimReviewContext();

  const claim = claimReview.watch('claim');

  const currentRefundHandlingFee = claim.feeApplied;

  const invalidateClaim = useInvalidateClaimReview();

  const storeId = useStoreId();
  const {
    userFullName,
    storeUser: {
      store: { currencyCode, currencySymbol },
    },
  } = useMerchantContext();

  const { mutateAsync: updateClaimFees, isPending } = useMutation({
    mutationFn: (claimFeesUpdate: ClaimUpdateFees) =>
      api
        .store(storeId)
        .claim(`${claim.id}`, userFullName)
        .updateFees(claimFeesUpdate),
    onSuccess: () => invalidateClaim(),
  });

  const formId = 'edit-claim-fees-form';
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm({
    resolver: zodResolver(claimUpdateFees),
    defaultValues: {
      feeApplied: currentRefundHandlingFee,
    } satisfies ClaimUpdateFees,
  });

  const closeAndReset = () => {
    onClose();
    reset();
  };

  return (
    <Modal
      title="Edit Fees"
      show={show}
      onClose={closeAndReset}
      actions={
        <>
          <Button onClick={closeAndReset}>Cancel</Button>
          <Button
            variant="primary"
            type="submit"
            form={formId}
            disabled={isPending}
            loading={isPending}
          >
            Save
          </Button>
        </>
      }
    >
      <form
        id={formId}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(async ({ feeApplied }) => {
          await updateClaimFees({
            feeApplied,
          });
          onClose();
        })}
        className="flex flex-col gap-4"
      >
        <NumberInput
          id="refund-handling-fee"
          label="Refund Handling Fee"
          step="0.01"
          details="The amount that will be deducted from the refund amount when the claim is finalized."
          addon={{
            /**
             *  not ideal as symbol placement depends on locale
             * and in some locales the symbol is not used at all
             * e.g. es-MX, es-AR the Euro symbol is not used
             * but instead display prefixed with `EUR`
             */
            insideStart: currencySymbol,
            insideEnd: currencyCode,
          }}
          required
          {...register('feeApplied', {
            valueAsNumber: true,
          })}
          error={errors.feeApplied?.message}
        />
      </form>
    </Modal>
  );
}

function gramsToOunces(grams: number) {
  return grams / 28.3495;
}

function CreateClaimShipment({
  show,
  onClose,
  addresses,
}: {
  show: boolean;
  onClose: () => void;
  addresses: CrewMerchantUi.ReturnLocation['address'][];
}) {
  const { claimReview } = useClaimReviewContext();
  const { userFullName } = useMerchantContext();

  const invalidateClaim = useInvalidateClaimReview();
  const storeId = useStoreId();
  const claim = claimReview.watch('claim');

  const {
    [CrewClaimResolutionMethodEnum.warrantyReview]: warrantyLi,
    [CrewClaimResolutionMethodEnum.refund]: refundLi,
    [CrewClaimResolutionMethodEnum.variantExchange]: exchangeLi,
    [CrewClaimResolutionMethodEnum.giftCard]: storeCreditLi,
  } = claim.reviewLineItems;

  const allLineItems = [
    ...warrantyLi,
    ...refundLi,
    ...exchangeLi,
    ...storeCreditLi,
  ];
  const totalWeightInOz = gramsToOunces(
    allLineItems.reduce(
      (acc, item) => acc + item.claimLineItem.originalStoreOrderLineItem.grams,
      0,
    ),
  );

  const { mutateAsync: createReturnShipment, isPending } = useMutation({
    mutationFn: (body: ClaimShipmentCreate) =>
      api
        .store(storeId)
        .claim(`${claim.id}`, userFullName)
        .createShipment(body),
    onSuccess: () => invalidateClaim(),
  });

  const formId = 'create-claim-shipment-form';
  const {
    formState: { errors },
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
  } = useForm<ClaimShipmentCreate>({
    resolver: zodResolver(claimShipmentCreate),
    defaultValues: {
      claimId: claim.id,
      returnShipmentType: ShipmentMethod.packingSlip,
      // defaulting the from address to the claim's shipping address
      fromAddressId: claim.shippingAddress.id,
      // defaulting the to address to the first available address
      toAddressId: addresses[0]?.id,
    },
  });

  const [currentStep, setCurrentStep] = useState(0);
  const [packageWeight, setPackageWeight] = useState(
    Math.round(totalWeightInOz),
  );

  const {
    mutateAsync: getShipmentQuotes,
    isPending: isRatesPending,
    data: quoteResp,
  } = useMutation({
    mutationFn: (body: ClaimShipmentQuote) =>
      // TODO verify what happens when a shipping provider is not available; i.e. has been removed/disconnected
      api
        .store(storeId)
        .claim(`${claim.id}`, userFullName)
        .createShipmentQuote(body),
  });

  useEffect(() => {
    if (!quoteResp) return;
    setValue('shipmentIdFromPlatform', quoteResp.shipmentIdFromPlatform);
  }, [quoteResp, setValue]);

  const closeAndReset = () => {
    onClose();
    reset();
    setCurrentStep(0);
  };

  const goBack = () => {
    reset();
    setCurrentStep(0);
  };

  const selectedToAddressId = watch('toAddressId');
  const selectedFromAddressId = watch('fromAddressId');
  const selectedType = watch('returnShipmentType');
  const selectedRate = watch('rate');

  const toAddress = addresses.find((loc) => loc.id === selectedToAddressId);
  const fromAddress = addresses.find((loc) => loc.id === selectedFromAddressId);

  const rates = quoteResp?.rates ?? [];

  const isSameAddress = selectedToAddressId === selectedFromAddressId;

  const isManualShipment = selectedFromAddressId !== claim.shippingAddress.id;

  return (
    <Modal
      title="Create Shipment"
      show={show}
      onClose={closeAndReset}
      actions={
        <>
          <Button onClick={currentStep === 0 ? closeAndReset : goBack}>
            {currentStep === 0 ? 'Cancel' : 'Back'}
          </Button>

          {/* // create a label */}
          {selectedType === ShipmentMethod.packingSlip && (
            <Button
              variant="primary"
              type="submit"
              form={formId}
              disabled={isPending || isSameAddress}
              loading={isPending}
            >
              Create
            </Button>
          )}

          {selectedType === ShipmentMethod.label &&
            (currentStep === 0 ?
              <Button
                variant="primary"
                type="submit"
                disabled={
                  isRatesPending || !selectedToAddressId || isSameAddress
                }
                loading={isRatesPending}
                onClick={() => {
                  getShipmentQuotes({
                    toAddressId: selectedToAddressId,
                    fromAddressId: selectedFromAddressId,
                    weightInOunces: packageWeight,
                  })
                    .then(() => {
                      setCurrentStep(1);
                    })
                    .catch((e) => {
                      console.error(`Error getting shipment quotes:`, e);
                    });
                }}
              >
                Next
              </Button>
            : <Button
                variant="primary"
                type="submit"
                form={formId}
                disabled={isPending || !selectedRate || isSameAddress}
                loading={isPending}
              >
                Create
              </Button>)}
        </>
      }
    >
      <form
        id={formId}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(async (submitPayload) => {
          const { returnShipmentType, toAddressId, fromAddressId } =
            submitPayload;

          if (returnShipmentType === ShipmentMethod.label) {
            const { rate, shipmentIdFromPlatform } = submitPayload;
            await createReturnShipment({
              claimId: claim.id,
              toAddressId,
              fromAddressId,
              returnShipmentType,
              shipmentIdFromPlatform,
              rate,
            });
          }

          if (returnShipmentType === ShipmentMethod.packingSlip) {
            await createReturnShipment({
              claimId: claim.id,
              toAddressId,
              fromAddressId,
              returnShipmentType,
            });
          }
          closeAndReset();
        })}
        className="flex flex-col gap-4"
      >
        <Controller
          name="fromAddressId"
          control={control}
          render={({ field: { onChange, value }, fieldState }) => (
            <SimpleSelect
              label="From"
              options={addresses.map((location) => ({
                label: location.name,
                value: `${location.id}`,
              }))}
              value={`${value}`}
              onChange={(selected) => onChange(Number.parseInt(selected, 10))}
              details="The from address that will be used for this shipment."
              error={fieldState.error?.message}
            />
          )}
        />
        <Controller
          name="toAddressId"
          control={control}
          render={({ field: { onChange, value }, fieldState }) => (
            <SimpleSelect
              label="To"
              options={addresses.map((location) => ({
                label: location.name,
                value: `${location.id}`,
              }))}
              value={`${value}`}
              onChange={(selected) => onChange(Number.parseInt(selected, 10))}
              details="The to address that will be used for this shipment."
              error={fieldState.error?.message}
            />
          )}
        />

        {isSameAddress && (
          <Alert
            title="Address Issue"
            message="The from and to addresses are the same."
            variant="warning"
          />
        )}

        {isManualShipment && !isSameAddress && (
          <Alert
            title="Manual Shipment"
            message="This is a manual shipment, and will not trigger Automation workflows."
            variant="info"
          />
        )}
        {toAddress && fromAddress && (
          <div className="flex items-center justify-center gap-10 text-xs text-corso-gray-500">
            <div>
              <p className="text-sm font-bold">From:</p>
              <Address name={fromAddress.name} address={fromAddress} />
            </div>

            <ArrowRightIcon className="h-6 w-6" />

            <div>
              <p className="text-sm font-bold">To:</p>
              <Address name={toAddress.name} address={toAddress} />
            </div>
          </div>
        )}

        {currentStep === 0 ?
          <>
            <Controller
              name="returnShipmentType"
              control={control}
              render={({ field: { onChange, value } }) => (
                <SimpleSelect
                  label="Return Method"
                  options={Object.values(ShipmentMethod).map(
                    (shipmentType) => ({
                      label: shipmentTypeLabels[shipmentType],
                      value: shipmentType,
                    }),
                  )}
                  value={value}
                  onChange={onChange}
                  details="The type of return shipment that will be created."
                  error={errors.returnShipmentType?.message}
                />
              )}
            />
            {selectedType === ShipmentMethod.label && (
              <NumberInput
                id="package-weight-oz"
                required
                label="Weight"
                addon={{ insideEnd: 'oz' }}
                details="The combined weight of the items in the shipment."
                onChange={(e) => setPackageWeight(e.target.valueAsNumber)}
                value={Number.isNaN(packageWeight) ? null : packageWeight}
              />
            )}
          </>
        : !isRatesPending &&
          rates.length > 1 && (
            <Controller
              name="rate"
              control={control}
              render={({ field: { onChange, value }, fieldState }) => {
                const parsedValue =
                  claimShipmentCreate.options[1].shape.rate.safeParse(
                    // part of the issue here is that there's no value set, but type-wise it's expected to exist at this point
                    // TODO determine why `value` seems to be set as a `string`, widening type to `unknown` to enforce resolution, and generally resolve to remove this
                    value as unknown,
                  );

                return (
                  <SimpleSelect
                    label="Rate"
                    placeholder="Select a rate"
                    options={rates.map((rate) => ({
                      // TODO propagate the currency code from the rate and format it accordingly
                      label: `${rate.carrier} - ${rate.service} - ${formatter.currency(rate.cost, 'USD')}`,
                      value: rate.id,
                    }))}
                    value={parsedValue.data?.id}
                    onChange={(selected) =>
                      onChange(rates.find((rate) => rate.id === selected))
                    }
                    details="The rate that will be used for the shipment."
                    error={fieldState.error?.message}
                  />
                );
              }}
            />
          )
        }
      </form>
      {!isRatesPending && rates.length === 0 && currentStep === 1 && (
        <div className="mt-4">
          <Alert
            title="No Shipping Rates Available"
            message="Please go back and select a different Return Method."
            variant="info"
          />
        </div>
      )}
    </Modal>
  );
}

function CustomerDisplay() {
  const { claimReview } = useClaimReviewContext();
  const claim = claimReview.watch('claim');

  const { shippingAddress } = claim;
  const { firstName, lastName, phone } = shippingAddress;

  const customerName = `${firstName} ${lastName}`;

  const [showEditClaim, setShowEditClaim] = useState(false);
  const closeEditClaim = () => setShowEditClaim(false);

  return (
    <div className="space-y-3">
      {/* edit customer */}
      <p className="mb-2 flex items-center justify-between text-sm font-medium">
        <span>Customer</span>
        <IconAction.Button
          icon={PencilIcon}
          variant="ghost"
          iconSize="sm"
          title="Edit Customer"
          onClick={() => setShowEditClaim(true)}
        />
      </p>

      {/* customer name */}
      <DetailInfoItem
        icon={<UserIcon className="h-4 w-4" />}
        content={customerName}
      />

      {/* customer address */}
      <DetailInfoItem
        icon={<HomeIcon className="h-4 w-4" />}
        className="items-start"
        content={<Address address={shippingAddress} />}
      />

      {/* customer email */}
      <DetailInfoItem
        icon={<EnvelopeIcon className="h-4 w-4" />}
        content={claim.customerEmail}
      />

      {/* customer phone */}
      <DetailInfoItem
        icon={<PhoneIcon className="h-4 w-4" />}
        content={phone}
      />

      {/* note to customer */}
      <DetailInfoItem
        content={claim.noteToCustomer}
        icon={<ChatBubbleBottomCenterIcon className="h-4 w-4" />}
      />

      {/* edit claim */}
      <EditCustomerInfo show={showEditClaim} onClose={closeEditClaim} />
    </div>
  );
}

function ClaimShipmentDisplay({
  shipment,
  customerShippingAddressId,
}: {
  shipment?: CrewMerchantUi.ClaimShipment;
  customerShippingAddressId?: number;
}) {
  if (!shipment) return null;

  const {
    tracker,
    returnShipmentPdfAssetUrl,
    returnShipmentType,
    rmaNumber,
    rmaProvider,
    toAddress,
    qrCodeDetail,
    createdOn,
    returnShipmentId,
    fromAddress,
    isReturnShipment,
  } = shipment;

  const tUrl =
    // eslint-disable-next-line no-nested-ternary
    tracker?.carrier.trackingUrl ? tracker?.carrier.trackingUrl
    : tracker?.carrier.trackingCode ?
      `https://www.google.com/search?q=${tracker.carrier.trackingCode}`
    : undefined;

  return (
    <>
      <DetailInfoItem
        icon={<HashtagIcon className="h-4 w-4" />}
        content={returnShipmentId}
      />

      {isReturnShipment ?
        <DetailInfoItem
          icon={<ArrowsUpDownIcon className="h-4 w-4" />}
          content="Return Shipment"
        />
      : <DetailInfoItem
          icon={<TruckIcon className="h-4 w-4" />}
          content="Manual Shipment"
        />
      }

      {returnShipmentPdfAssetUrl && (
        <DetailInfoItem
          icon={<DocumentIcon className="h-4 w-4" />}
          href={returnShipmentPdfAssetUrl}
          className="justify-between"
          content={
            returnShipmentPdfAssetUrl && returnShipmentType === 'Packing_Slip' ?
              'Download Packing Slip'
            : 'Download Label'
          }
          variant="download"
        />
      )}

      {qrCodeDetail?.url && (
        <DetailInfoItem
          icon={<QrCodeIcon className="h-4 w-4" />}
          href={qrCodeDetail.url}
          className="justify-between"
          content="Download QR Code"
          variant="download"
        />
      )}

      {!isReturnShipment && (
        <DetailInfoItem
          icon={
            fromAddress.id === customerShippingAddressId ?
              <UserIcon className="h-4 w-4" />
            : <BuildingStorefrontIcon className="h-4 w-4" />
          }
          className="items-start"
          content={
            <Address name={`From: ${fromAddress.name}`} address={fromAddress} />
          }
        />
      )}

      <DetailInfoItem
        icon={
          toAddress.id === customerShippingAddressId ?
            <UserIcon className="h-4 w-4" />
          : <BuildingStorefrontIcon className="h-4 w-4" />
        }
        className="items-start"
        content={
          <>
            <Address name={`To: ${toAddress.name}`} address={toAddress} />
            {rmaNumber && rmaProvider && (
              <>
                <br />
                <br />
                {`${rmaProviderLabel[rmaProvider]} RMA #${rmaNumber}`}
              </>
            )}
          </>
        }
      />

      {tracker && tUrl && (
        <DetailInfoItem
          icon={<TruckIcon className="h-4 w-4" />}
          className="justify-between"
          variant="link"
          href={tUrl}
          content={
            <>
              {tracker.carrier.name}
              <br />
              {tracker.carrier.trackingCode ?
                `${statusLabels[tracker.latestStatus]}`
              : undefined}
              <br />#{tracker.carrier.trackingCode}
            </>
          }
        />
      )}

      <DetailInfoItem
        icon={<CalendarIcon className="h-4 w-4" />}
        content={
          <span className="text-xs text-corso-gray-500">
            Created <RelativeDateTime dateTime={createdOn} compact />
          </span>
        }
      />
    </>
  );
}

function ClaimShipmentsDisplay() {
  const { claimReview } = useClaimReviewContext();
  const claim = claimReview.getValues('claim');
  const shipments = claim.claimReturnShipments ?? [];
  const [showCreateShipment, setShowCreateShipment] = useState(false);
  const closeCreateShipment = () => setShowCreateShipment(false);

  const { data: locations, isSuccess } = useReturnLocations(); // ? might need to display an error state

  const locationAddresses = locations?.map((policy) => policy.address);

  const [currentShipmentIndex, setCurrentShipmentIndex] = useState(0);
  const shipment = shipments[currentShipmentIndex];

  const handlePrevious = () => {
    setCurrentShipmentIndex((prevIndex) =>
      prevIndex === 0 ? shipments.length - 1 : prevIndex - 1,
    );
  };

  const handleNext = () => {
    setCurrentShipmentIndex((prevIndex) =>
      prevIndex === shipments.length - 1 ? 0 : prevIndex + 1,
    );
  };

  return (
    <div className="space-y-3">
      <p className="flex items-center justify-between text-sm font-medium">
        <span>Shipments</span>
        <IconAction.Button
          icon={PlusIcon}
          variant="ghost"
          iconSize="lg"
          title="Create Shipment"
          onClick={() => setShowCreateShipment(true)}
        />
      </p>

      <ClaimShipmentDisplay
        shipment={shipment}
        customerShippingAddressId={claim.shippingAddress.id}
      />

      {shipments.length > 1 && (
        <div className="flex items-center justify-between">
          <p className="text-sm text-corso-gray-500">
            {currentShipmentIndex + 1} of {shipments.length}
          </p>
          <div className="flex space-x-2">
            <button
              type="button"
              onClick={handlePrevious}
              disabled={shipments.length <= 1}
              aria-label="Previous Shipment"
            >
              <ChevronLeftIcon className="h-5 w-5 text-corso-gray-500 hover:text-black" />
            </button>

            <button
              type="button"
              onClick={handleNext}
              disabled={shipments.length <= 1}
              aria-label="Next Shipment"
            >
              <ChevronRightIcon className="h-5 w-5 text-corso-gray-500 hover:text-black" />
            </button>
          </div>
        </div>
      )}

      {isSuccess && (
        <CreateClaimShipment
          show={showCreateShipment}
          onClose={closeCreateShipment}
          addresses={
            locationAddresses ?
              [
                ...locationAddresses,
                {
                  ...claim.shippingAddress,
                  // spreading the claim address last, so a location gets selected by default
                  name: `${claim.shippingAddress.firstName} ${claim.shippingAddress.lastName}`,
                },
              ]
            : []
          }
        />
      )}
    </div>
  );
}

function ClaimTagDisplaySkeleton() {
  return (
    <div className="flex flex-col gap-2">
      <Label>Tags</Label>
      <Skeleton.Rectangle height="4rem" width="100%" />
    </div>
  );
}

function ClaimTagDisplay() {
  const { data: tagOptions, isPending: isOptionsPending } =
    useClaimTagOptions();
  const { data: currentTags, isPending: isCurrentPending } = useClaimTags();
  const { mutateAsync: upsertTags, isPending: isUpsertPending } =
    useClaimTagsUpsert();

  const [tags, setTags] = useState<string[]>([]);

  useEffect(() => {
    if (!currentTags) return;
    setTags(currentTags);
  }, [currentTags]);

  return (
    <Skeleton
      isLoading={isOptionsPending || isCurrentPending}
      skeleton={ClaimTagDisplaySkeleton}
    >
      <MultiSelect
        label={
          <div className="flex items-center gap-2">
            Tags
            {isUpsertPending && <SpinnerIcon className="h-3 w-3" />}
          </div>
        }
        placeholder="Add tags"
        options={(tagOptions ?? []).map((tag) => ({
          label: tag,
          value: tag,
        }))}
        value={tags.map((tag) => ({
          label: tag,
          value: tag,
        }))}
        onChange={(changedTags) => {
          const tagValues = changedTags.map((tag) => tag.value);
          setTags(tagValues);
          upsertTags(tagValues).catch((error) => console.error(error));
        }}
        creatable
      />
    </Skeleton>
  );
}

function ClaimFeesDisplay() {
  const { claimReview } = useClaimReviewContext();

  const claim = claimReview.watch('claim');
  const showClaimFeesDisplay =
    claim.reviewLineItems.Refund.length > 0 &&
    claim.claimRollup.code !== 'Completed';

  const [showEditFees, setShowEditFees] = useState(false);
  const closeEditFees = () => setShowEditFees(false);

  if (!showClaimFeesDisplay) return null;
  return (
    <div className="space-y-3">
      {/* edit customer */}
      <p className="mb-2 flex items-center justify-between text-sm font-medium">
        <span>Handling Fee</span>
        <IconAction.Button
          icon={PencilIcon}
          variant="ghost"
          iconSize="sm"
          title="Edit Handling Fee"
          onClick={() => setShowEditFees(true)}
        />
      </p>

      {/* edit claim */}
      <EditFees show={showEditFees} onClose={closeEditFees} />
      <hr />
    </div>
  );
}

export default function ClaimDetail() {
  const { claimReview } = useClaimReviewContext();
  const claim = claimReview.watch('claim');
  return (
    <ContentWrapper key={claim.id}>
      <div className="flex flex-col gap-4 rounded-md border p-4 shadow-sm md:mt-8">
        <CustomerDisplay />
        <hr />
        <ClaimFeesDisplay />
        <ClaimTagDisplay />
        <hr />
        <ClaimShipmentsDisplay />
      </div>
    </ContentWrapper>
  );
}
