import { CrewIncentiveTypeEnum } from 'corso-types';
import { ReactNode, useCallback } from 'react';
import { twMerge } from 'tailwind-merge';
import Button from '~/components/Button';
import ButtonGroup from '~/components/ButtonGroup';
import { useMerchantContext } from '~/providers/MerchantProvider';
import { InputTypeProps } from './Input';
import NumberInput from './NumberInput';

// focused subset of incentives // TODO rename `CrewIncentiveTypeEnum` and remove `None`; semantically `0` is none for either type
export const MonetaryType = {
  fixed: CrewIncentiveTypeEnum.fixed,
  percent: CrewIncentiveTypeEnum.percent,
} as const satisfies Record<string, CrewIncentiveTypeEnum>;

type MonetaryType = (typeof MonetaryType)[keyof typeof MonetaryType];

function MonetaryTypeSwitch({
  type,
  setType,
}: {
  type: MonetaryType;
  setType: (type: MonetaryType) => void;
}) {
  return (
    <ButtonGroup>
      <Button
        className={twMerge(
          'text-xs',
          type === MonetaryType.percent &&
            'bg-corso-gray-200 hover:bg-corso-gray-200',
        )}
        onClick={() => setType(MonetaryType.percent)}
      >
        Percentage
      </Button>
      <Button
        onClick={() => setType(MonetaryType.fixed)}
        className={twMerge(
          'text-xs',
          type === MonetaryType.fixed &&
            'bg-corso-gray-200 hover:bg-corso-gray-200',
        )}
      >
        Fixed Amount
      </Button>
    </ButtonGroup>
  );
}

type MonetaryAmountInputProps = Omit<
  InputTypeProps<never>,
  'value' | 'onChange'
> & {
  id: string;
  label: string;
  details?: string;
  error?: ReactNode;
  value: { amount: number; type: MonetaryType };
  onChange: (value: { amount: number; type: MonetaryType }) => void;
};

/** Enforced as a controlled input, since it modifies an object instead of a single value. */
// TODO better name; works for incentives, fees, discounts, etc
export default function MonetaryRateInput({
  id,
  label,
  details,
  error,
  value,
  onChange,
  ...props
}: MonetaryAmountInputProps) {
  const {
    storeUser: {
      store: { currencyCode, currencySymbol },
    },
  } = useMerchantContext();

  // resets `amount` to `0` when switching between types
  const setType = useCallback(
    (type: MonetaryType) => onChange({ type, amount: 0 }),
    [onChange],
  );

  const setAmount = useCallback(
    (amount: number) => onChange({ type: value.type, amount }),
    [onChange, value.type],
  );

  return (
    <NumberInput
      id={id}
      label={label}
      details={details}
      placeholder={value.type === MonetaryType.fixed ? '0.00' : '0'}
      step={value.type === MonetaryType.fixed ? '0.01' : '1'}
      addon={
        value.type === MonetaryType.percent ?
          {
            outsideStart: (
              <MonetaryTypeSwitch type={value.type} setType={setType} />
            ),
            insideEnd: '%',
          }
        : {
            outsideStart: (
              <MonetaryTypeSwitch type={value.type} setType={setType} />
            ),
            // Symbol placement/usage depends on locale, so this isn't perfect.
            insideStart: currencySymbol,
            insideEnd: currencyCode,
          }
      }
      required
      value={value.amount}
      onChange={(event) => setAmount(event.target.valueAsNumber)}
      error={error}
      {...props}
    />
  );
}
