import { NavLink } from 'react-router-dom';
import CorsoIcon from './CorsoIcon';

/** Used in place of application content to display a status to the user, and give them a way to return home. */
export default function FullPageStatus({
  statusCode,
  title,
  message,
}: {
  statusCode?: number;
  title: string;
  message: string;
}) {
  return (
    <main className="grid min-h-full grid-rows-[auto,_1fr] place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8 dark:bg-corso-gray-800">
      <div className="text-corso-blue-600 dark:text-white">
        <CorsoIcon size="lg" />
      </div>
      <div className="text-center">
        {statusCode ?
          <p className="text-base font-semibold text-corso-blue-600 dark:text-blue-300">
            {statusCode}
          </p>
        : null}
        <h1 className="mt-4 text-3xl font-medium tracking-tight text-corso-gray-800 sm:text-5xl dark:text-corso-gray-100">
          {title}
        </h1>
        <p className="mt-6 text-base leading-7 text-corso-gray-600 dark:text-corso-gray-500">
          {message}
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <NavLink
            to="/"
            className="rounded-md bg-corso-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-corso-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            reloadDocument
          >
            Go Back Home
          </NavLink>
          {/* // ? maybe add a support email link or `children` */}
        </div>
      </div>
    </main>
  );
}
