import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import api from '~/api';
import { ProductGroup, ProductGroupCreate } from '~/types';
import { isErrorMessage } from '~/utils/error';
import { useStoreId } from './useStoreId';
import { useToast } from './useToast';

const useQueryKey = () => {
  const storeId = useStoreId();
  return ['productGroups', storeId] as const;
};

export const useProductGroups = () => {
  const queryKey = useQueryKey();

  return useQuery({
    queryKey,
    queryFn: ({ queryKey: [, storeId] }) =>
      api.store(storeId).productGroup.get(),
  });
};

export const useProductGroupUpsert = () => {
  const queryClient = useQueryClient();
  const storeId = useStoreId();
  const queryKey = useQueryKey();
  const toast = useToast();

  return useMutation({
    mutationFn: (group: ProductGroup | ProductGroupCreate) =>
      'id' in group ?
        api.store(storeId).productGroup.update(String(group.id), group)
      : api.store(storeId).productGroup.create(group),
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
    onMutate: (group: ProductGroup | ProductGroupCreate) => {
      const previousGroups =
        queryClient.getQueryData<ProductGroup[]>(queryKey) ?? [];

      let updates: ProductGroup[] = [];
      if ('id' in group) {
        const index = previousGroups.findIndex((g) => g.id === group.id);
        updates = [...previousGroups];
        updates.splice(index, 1, group);
      } else {
        updates = [
          ...previousGroups,
          { ...group, id: Number.MAX_SAFE_INTEGER },
        ];
      }

      queryClient.setQueryData(queryKey, updates);

      return { previousGroups };
    },
    onError: (error, _, context) => {
      queryClient.setQueryData(queryKey, context?.previousGroups);
      const message =
        isErrorMessage(error) ?
          error.message
        : 'Something went wrong. Please try again.';

      toast.show(message);
      return error;
    },
  });
};

export const useProductGroupDelete = () => {
  const queryClient = useQueryClient();
  const storeId = useStoreId();
  const queryKey = useQueryKey();
  const toast = useToast();

  return useMutation({
    mutationFn: (group: ProductGroup) =>
      api.store(storeId).productGroup.delete(String(group.id)),
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
    onMutate: (group: ProductGroup) => {
      const previousGroups =
        queryClient.getQueryData<ProductGroup[]>(queryKey) ?? [];

      queryClient.setQueryData(
        queryKey,
        previousGroups.filter((g) => g.id !== group.id),
      );

      return { previousGroups };
    },
    onError: (error, _, context) => {
      queryClient.setQueryData(queryKey, context?.previousGroups);

      toast.show(
        isErrorMessage(error) ?
          error.message
        : 'Something went wrong. Please try again.',
      );
    },
  });
};

export const useProductGroupProducts = (groupId: number, enabled: boolean) => {
  const storeId = useStoreId();
  const queryKey = ['productGroup', 'products', storeId, groupId];

  return useQuery({
    enabled,
    queryKey,
    queryFn: () =>
      api.store(storeId).productGroup.listProducts(String(groupId)),
  });
};
