import Alert from '~/components/Alert';
import { LinkButton } from '~/components/Button';
import { useStoreId } from '~/hooks/useStoreId';
import { useClaimReviewContext } from '~/providers/ClaimReviewProvider';

export function RegistrationSummary() {
  const { claimReview } = useClaimReviewContext();
  const claim = claimReview.watch('claim');
  const storeId = useStoreId();

  const { originalStoreOrder } = claim;
  const { registration } = originalStoreOrder;

  if (!registration) return null;

  const { externalId } = registration;

  return (
    <Alert
      title={`#${externalId}`}
      message={
        <div className="flex items-center">
          <p>This claim was created from a registration. </p>{' '}
          <LinkButton
            to={`/${storeId}/registration/${registration.id}`}
            variant="text"
            className="ml-1"
          >
            View Details
          </LinkButton>
        </div>
      }
    />
  );
}
