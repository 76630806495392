import { ArrowRightIcon } from '@heroicons/react/20/solid';
import { JSX, PropsWithChildren, ReactNode } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

// TODO consider adding a `Card` component and replace the `Panel` usage
/** Minimalist wrapper that can be used alike a card. */
function GridListWrapper({ children }: PropsWithChildren) {
  return (
    <div className="overflow-hidden shadow-md sm:rounded-lg">{children}</div>
  );
}

const variantStyles = {
  DEFAULT:
    'bg-corso-gray-50 p-3 text-corso-gray-700 group-hover:bg-corso-gray-700 group-hover:text-white',
  primary:
    'bg-corso-blue-50 p-3 text-corso-blue-700 group-hover:bg-corso-blue-700 group-hover:text-white',
  secondary:
    'bg-corso-green-50 p-3 text-corso-green-700 group-hover:bg-corso-green-700 group-hover:text-white',
} as const;

function GridListItemLink({
  to,
  label,
  description,
  icon,
  children,
  secondaryIcon,
  variant = 'DEFAULT',
}: PropsWithChildren<{
  label: string;
  description?: string;
  icon?: ReactNode;
  to: LinkProps['to'];
  secondaryIcon?: JSX.Element;
  variant?: keyof typeof variantStyles;
}>) {
  return (
    <div className="flex flex-col bg-white has-[.link:focus]:ring-2 has-[.link:focus]:ring-inset has-[.link:focus]:ring-corso-blue-700">
      <Link
        to={to}
        className="link group grid h-full grid-cols-[1fr_auto] justify-between gap-4 p-6 focus:outline-none"
      >
        <div
          className={twMerge(
            'flex items-center gap-4',
            description && 'flex-col items-start justify-normal gap-4',
          )}
        >
          {icon && (
            <span
              className={twMerge(
                'inline-flex rounded-lg transition-all duration-300 *:h-6 *:w-6',
                variantStyles[variant],
              )}
            >
              {icon}
            </span>
          )}
          <div className="space-y-2">
            <p className="text-pretty text-base font-semibold leading-6 text-gray-900">
              {label}
            </p>
            {description && (
              <p className="text-balance text-sm text-gray-500">
                {description}
              </p>
            )}
          </div>
        </div>
        <span
          className={twMerge(
            'pointer-events-none text-gray-300 *:h-6 *:w-6 group-hover:text-gray-400',
            !icon && 'self-center',
          )}
        >
          {secondaryIcon ?? <ArrowRightIcon aria-hidden="true" />}
        </span>
      </Link>
      {children && <div className="px-6 pb-6">{children}</div>}
    </div>
  );
}

export default function GridList<T>({
  columns = 2,
  data,
  children: renderChild,
}: {
  columns?: number;
  data: T[];
  children: (value: T) => ReactNode;
}) {
  const columnSpan = columns - (data.length % columns);

  return (
    <div
      style={{
        '--column-count': columns,
      }}
      // uses grid gap, and background color to emulate border between cards with border radius
      className="sm:grid-cols divide-y divide-gray-200 bg-gray-200 sm:grid sm:grid-cols-[repeat(var(--column-count),minmax(0,1fr))] sm:gap-px sm:divide-y-0 sm:rounded-lg"
    >
      {data.map(renderChild)}
      {!!columnSpan && columnSpan !== columns && (
        <div
          // automatically pad out the last row to fill the grid after the `sm` breakpoint
          style={{ '--column-span': columnSpan }}
          className="m-0 hidden bg-white [grid-column:span_var(--column-span)/span_var(--column-span)] md:block"
        />
      )}
    </div>
  );
}

GridList.ItemLink = GridListItemLink;
GridList.Wrapper = GridListWrapper;
