import { faFeather } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PencilIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormEventHandler, ReactNode, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Button from '~/components/Button';
import ConfirmModal from '~/components/ConfirmModal';
import DescriptionList, { Description } from '~/components/DescriptionList';
import Disclosure, { SimpleSummary } from '~/components/Disclosure';
import EmptyStateAction from '~/components/EmptyStateAction';
import { TextInput } from '~/components/field';
import IconAction from '~/components/IconAction';
import Modal from '~/components/Modal';
import Panel from '~/components/Panel';
import Skeleton from '~/components/Skeleton';
import SimpleSelect from '~/components/ui/SimpleSelect';
import {
  useCustomFields,
  useCustomFieldsDelete,
  useCustomFieldsUpsert,
} from '~/hooks/useCustomFields';
import { useSettingsLayoutContext } from '~/hooks/useSettingsLayoutContext';
import {
  CustomField,
  CustomFieldCreate,
  customFieldCreateSchema,
  customFieldUpdateSchema,
  UpsertFormProps,
} from '~/types';

function CustomFieldForm({
  show,
  onClose,
  onSubmit,
  values,
}: UpsertFormProps<CustomField>) {
  const update = !!values?.id;

  const schemaToUse =
    update ? customFieldUpdateSchema : customFieldCreateSchema;

  // eslint-disable-next-line no-underscore-dangle
  const valueTypes = customFieldCreateSchema.shape.valueType._def.options.map(
    (option) => option.value,
  );

  const valueTypeOptions = valueTypes.map((valueType) => ({
    value: valueType,
    displayName: valueType,
  }));

  const {
    control,
    watch,
    formState: { errors },
    handleSubmit,
    reset,
    register,
    formState,
  } = useForm({
    resolver: zodResolver(schemaToUse),
    values,
  });

  const selectedValueType = watch('valueType');

  const submitHandler: FormEventHandler = (event) => {
    handleSubmit((formValues) => {
      onSubmit(formValues);
      reset();
    })(event).catch(console.error);
  };

  return (
    <Modal
      title="Custom Fields"
      description="Create a custom field to store additional information about a claim, these fields can be used in tandem with an automation rule to collect additional detail during the claim creation process."
      show={show}
      onClose={onClose}
    >
      <form className="flex flex-col gap-6" onSubmit={submitHandler}>
        <TextInput
          id="display-name"
          label="Name"
          details="The name of the custom field. This name is an internal reference and will not be displayed to the customer. (eg. Serial Number, Approx. Purchase Date)"
          required
          {...register('displayName')}
          error={errors.displayName?.message}
        />

        <TextInput
          id="name"
          label="Question"
          details="The question that will be displayed to the customer. (eg. 'What is the serial number of the product?, When did you purchase the product?)"
          required
          {...register('question')}
          error={errors.question?.message}
        />

        {!update && (
          <Controller
            name="valueType"
            control={control}
            render={({ field: { onChange, value } }) => (
              <SimpleSelect
                label="Value Type"
                options={valueTypeOptions.map((valueTypeOption) => ({
                  label: valueTypeOption.displayName,
                  value: valueTypeOption.value,
                }))}
                value={value}
                onChange={onChange}
                details="The type of value that will be stored in this field."
                error={formState.errors.valueType?.message}
              />
            )}
          />
        )}

        {selectedValueType === 'Select' && (
          <TextInput
            id="value-type-options"
            label="Options"
            details="A comma-separated list of options that the user can select from. (eg. 'Red, Blue, Green')"
            {...register('options')}
            error={errors.options?.message}
            required // required when valueType is 'Select'
          />
        )}

        <Button variant="primary" type="submit">
          {update ? 'Save' : 'Create'} Custom Field
        </Button>
      </form>
    </Modal>
  );
}

function CustomFieldsSkeleton() {
  return (
    <div className="flex gap-2">
      <Skeleton.Rectangle height="36px" width="100%" />
      <Skeleton.Rectangle height="36px" width="44px" />
      <Skeleton.Rectangle height="36px" width="44px" />
    </div>
  );
}

function DeleteCustomField({ cf }: { cf: CustomField }) {
  const { mutate: deleteCustomField } = useCustomFieldsDelete();
  const [showConfirmation, setShowConfirmation] = useState(false);

  const confirmDelete = () => {
    deleteCustomField(cf);
    setShowConfirmation(false);
  };

  return (
    <>
      <IconAction.Button
        icon={TrashIcon}
        title={`Delete ${cf.displayName}`}
        onClick={() => setShowConfirmation(true)}
      />

      <ConfirmModal
        title="Delete Custom Field"
        prompt={`Are you sure you want to delete ${cf.displayName}?`}
        confirmText="Delete"
        cancelText="Cancel"
        show={showConfirmation}
        onConfirm={confirmDelete}
        onCancel={() => setShowConfirmation(false)}
      />
    </>
  );
}

function EditCustomField({ cf }: { cf: CustomField }) {
  const { mutate: saveCustomField } = useCustomFieldsUpsert();
  const [showForm, setShowForm] = useState(false);

  const saveEdits = (edits: CustomField) => {
    saveCustomField(edits);
    setShowForm(false);
  };

  return (
    <>
      <IconAction.Button
        icon={PencilIcon}
        title={`Edit ${cf.displayName} group`}
        onClick={() => setShowForm(true)}
      />

      <CustomFieldForm
        show={showForm}
        onClose={() => setShowForm(false)}
        onSubmit={saveEdits}
        values={cf}
      />
    </>
  );
}

function CustomFieldDisplay({
  customField: { displayName, valueType, question, options },
  actions,
}: {
  customField: CustomField;
  actions?: ReactNode;
}) {
  const descriptions: Description[] = [];

  descriptions.push({
    term: 'Question',
    details: question,
  });

  if (options) {
    descriptions.push({
      term: 'Options',
      details: options,
    });
  }

  descriptions.push({ term: 'Value Type', details: valueType });

  return (
    <Disclosure
      renderSummary={
        <SimpleSummary>
          <div className="flex w-full flex-row items-center justify-between gap-2 pl-2">
            <h4 className="text-base font-medium text-corso-gray-800">
              {displayName}
            </h4>
            {actions}
          </div>
        </SimpleSummary>
      }
    >
      <div className="mt-1 pl-4">
        <DescriptionList descriptions={descriptions} />
      </div>
    </Disclosure>
  );
}

function AddCustomField() {
  const { data: customFields = [] } = useCustomFields();
  const { mutate: saveCustomField } = useCustomFieldsUpsert();
  const [showForm, setShowForm] = useState(false);

  const addCustomField = (cf: CustomFieldCreate) => {
    saveCustomField(cf);
    setShowForm(false);
  };

  return (
    <>
      {customFields.length === 0 ?
        <EmptyStateAction.Button
          onClick={() => setShowForm(true)}
          icon={<FontAwesomeIcon icon={faFeather} />}
          label="Create a custom field"
          description="Custom fields allow you to create additional fields, attributes, or properties. These fields can be used in tandem with an automation rule to collect additional information about from the customer about a claim."
        />
      : <Button
          variant="primary"
          onClick={() => setShowForm(true)}
          className="md:max-w-fit"
        >
          <PlusIcon className="h-5 w-5" aria-hidden="true" />
          Add Custom Field
        </Button>
      }
      <CustomFieldForm
        show={showForm}
        onClose={() => setShowForm(false)}
        onSubmit={addCustomField}
      />
    </>
  );
}

export default function CustomFieldSettings() {
  const { data: customFields = null, isLoading } = useCustomFields();
  const setPageForm = useSettingsLayoutContext();

  useEffect(() => {
    setPageForm(null);
  }, [setPageForm]);

  return (
    <Panel headline="Custom Fields">
      <Skeleton
        instances={1}
        skeleton={CustomFieldsSkeleton}
        isLoading={isLoading}
      >
        <ul className="flex flex-col gap-2">
          {customFields?.map((cf) => (
            <li key={cf.id}>
              <CustomFieldDisplay
                customField={cf}
                actions={
                  <div className="flex flex-row gap-2">
                    <EditCustomField cf={cf} />
                    <DeleteCustomField cf={cf} />
                  </div>
                }
              />
            </li>
          ))}
        </ul>
        <AddCustomField />
      </Skeleton>
    </Panel>
  );
}
