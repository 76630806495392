import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { PropsWithChildren } from 'react';
import Alert from '~/components/Alert';
import RelativeDateTime from '~/components/RelativeDateTime';
import {
  unsupportedResolutionMethods,
  useClaimReviewContext,
} from '~/providers/ClaimReviewProvider';

// TODO revisit if we want to show `shippingAddress` and `billingAddress` info
export default function ClaimGeneralInfo({ children }: PropsWithChildren) {
  const { claimReview } = useClaimReviewContext();
  const claim = claimReview.getValues('claim');

  const hasUnsupportedReviewLineItems = unsupportedResolutionMethods.some(
    (resolutionMethod) => !!claim.reviewLineItems[resolutionMethod].length,
  );

  return (
    <div className="flex flex-col gap-2 bg-white p-4 lg:rounded-t-md">
      {hasUnsupportedReviewLineItems && (
        <Alert
          variant="warning"
          title="Cannot Finalize Claim"
          message="Claim contains non-reviewable resolution methods and cannot be finalized."
        />
      )}
      <div className="grid grid-cols-1 gap-4 sm:gap-4 md:grid-cols-3">
        <div className="col-span-1 flex flex-col gap-3 md:col-span-3">
          <div className="flex flex-col items-start justify-center">
            {claim.linkToCustomerUi ?
              <a
                className="flex items-center gap-1 text-lg font-semibold hover:underline focus:underline"
                href={claim.linkToCustomerUi}
                target="_blank"
                rel="noreferrer"
              >
                #{claim.externalId}
                <ArrowTopRightOnSquareIcon className="h-4 w-4" />
              </a>
            : <p className="text-lg font-semibold">#{claim.externalId}</p>}
            <span className="text-sm">
              <span>{claim.claimRollup.name}</span>
            </span>
            <span className="mt-2 text-xs text-corso-gray-500">
              Created <RelativeDateTime dateTime={claim.createdOn} />
            </span>
          </div>
        </div>
        <div className="sm:col-start-4 sm:row-start-1">{children}</div>
      </div>
    </div>
  );
}
