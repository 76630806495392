import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

/**
 * Provides an abstraction to `useSearchParams` that allows for easier updating the search params.
 */
export default function useNavigateSearch() {
  const [, setSearchParams] = useSearchParams();

  const navigateSearch = useCallback(
    (navigateSearchParams: URLSearchParams) => {
      setSearchParams((previousSearchParams) => {
        const updatedSearchParams = new URLSearchParams(previousSearchParams);
        // update query string with new search params
        navigateSearchParams.forEach((value, key) => {
          updatedSearchParams.set(key, value);
        });
        return updatedSearchParams;
      });
    },
    [setSearchParams],
  );

  return navigateSearch;
}
