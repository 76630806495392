import { PropsWithChildren, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

const variantStyle = {
  DEFAULT: 'max-w-[95rem]',
  narrow: 'max-w-[50rem]',
};

type Props = PropsWithChildren<{
  headline: string; // TODO make optional
  actions?: ReactNode;
  variant?: keyof typeof variantStyle;
}>;
export default function Page({
  headline,
  actions,
  variant = 'DEFAULT',
  children,
}: Props) {
  return (
    <article>
      <header>
        <div
          className={twMerge(
            'mx-auto flex flex-col justify-between gap-4 px-4 sm:px-6 md:flex-row lg:px-8',
            variantStyle[variant],
          )}
        >
          <h1 className="sr-only text-3xl font-medium tracking-tight text-white">
            {headline}
          </h1>
          <div className="sr-only ml-auto">{actions}</div>
        </div>
      </header>
      <div
        className={twMerge(
          'mx-auto mt-2 pb-28 md:mt-4 lg:px-8',
          variantStyle[variant],
        )}
      >
        {children}
      </div>
    </article>
  );
}
