import { Menu as HeadlessMenu, Transition } from '@headlessui/react';
import { ComponentProps, Fragment, PropsWithChildren, ReactNode } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

// ? should the buttonAs be more narrow to specify the type of button that can be passed in?
export default function Menu({
  buttonAs,
  children,
}: PropsWithChildren<{ buttonAs: ReactNode }>) {
  return (
    <HeadlessMenu as="div" className="relative inline-block text-left">
      <div>
        <HeadlessMenu.Button as={Fragment}>{buttonAs}</HeadlessMenu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <HeadlessMenu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white py-1.5 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {children}
        </HeadlessMenu.Items>
      </Transition>
    </HeadlessMenu>
  );
}

const baseActionStyles =
  'flex items-center w-full gap-2 px-4 py-2 text-left text-sm text-corso-gray-800';
const activeActionStyles = 'bg-corso-blue-600 text-white';

Menu.ItemButton = ({
  children,
  onClick,
  className,
}: PropsWithChildren<{ onClick: () => void; className?: string }>) => (
  <HeadlessMenu.Item>
    {({ active }) => (
      <button
        type="button"
        className={twMerge(
          baseActionStyles,
          active && activeActionStyles,
          className,
        )}
        onClick={onClick}
      >
        {children}
      </button>
    )}
  </HeadlessMenu.Item>
);

type ItemNavLinkProps = Omit<ComponentProps<typeof NavLink>, 'className'> & {
  className?: string;
};

/** A `NavLink` wrapped within a `Menu.Item`. */
Menu.ItemNavLink = ({ children, className, ...props }: ItemNavLinkProps) => (
  <HeadlessMenu.Item>
    {({ active }) => (
      <NavLink
        // * do not use the callback for `className`, it does not work as expected in this context and the function becomes serialized to a string instead of being run
        className={twMerge(
          baseActionStyles,
          active && activeActionStyles,
          className,
        )}
        // eslint-disable-next-line react/jsx-props-no-spreading -- intentionally spreading props to passthrough `NavLink` functionality
        {...props}
      >
        {children}
      </NavLink>
    )}
  </HeadlessMenu.Item>
);

type ItemLinkProps = Omit<ComponentProps<typeof Link>, 'className'> & {
  className?: string;
};

/** A `Link` wrapped within a `Menu.Item`. */
Menu.ItemLink = ({ children, className, ...props }: ItemLinkProps) => (
  <HeadlessMenu.Item>
    {({ active }) => (
      <Link
        // * do not use the callback for `className`, it does not work as expected in this context and the function becomes serialized to a string instead of being run
        className={twMerge(
          baseActionStyles,
          active && activeActionStyles,
          className,
        )}
        // eslint-disable-next-line react/jsx-props-no-spreading -- intentionally spreading props to passthrough `NavLink` functionality
        {...props}
      >
        {children}
      </Link>
    )}
  </HeadlessMenu.Item>
);
