import {
  CrewClaimRollupStatusCode,
  crewClaimRollupStatusCodeName,
  SearchableCrewClaimResolutionMethod,
} from 'corso-types';

import { subDays } from 'date-fns';
import { PropsWithChildren } from 'react';
import { DateRange } from 'react-day-picker';
import { TextInput } from '~/components/field';
import DateRangePicker from '~/components/field/DateRangePicker';
import InfoPopover from '~/components/InfoPopover';
import Panel from '~/components/Panel';
import { Label } from '~/components/ui/primitives/Label';
import SimpleSelect from '~/components/ui/SimpleSelect';
import useNavigateSearch from '~/hooks/useNavigateSearch';

// TODO: Some of this file could be consolidated with `ShippingClaimSearch.tsx`
/** Using a `dateRange` gets a usable and well-defined known date range. */
function getDefinedDateRange(dateRange?: DateRange) {
  const { from, to } = dateRange ?? {};
  if (from && to) return { from, to };
  if (from) return { from, to: from }; // ? may not be useful as this is likely one point in time
  return { from: subDays(new Date(), 30), to: new Date() };
}

const searchableResolutionMethodName = {
  [SearchableCrewClaimResolutionMethod.refund]: 'Refund',
  [SearchableCrewClaimResolutionMethod.variantExchange]: 'Exchange',
  [SearchableCrewClaimResolutionMethod.giftCard]: 'Gift Card',
  [SearchableCrewClaimResolutionMethod.warrantyReview]: 'Warranty Review',
} as const satisfies Record<SearchableCrewClaimResolutionMethod, string>;

export type ClaimSearchProps = PropsWithChildren<{
  searchTerm: string;
  dateRange: {
    from: Date;
    to: Date;
  };
  rollupStatusCode: CrewClaimRollupStatusCode | 'all';
  requestedResolutionMethod: SearchableCrewClaimResolutionMethod | 'all';
  orderBy: 'asc' | 'desc';
}>;

function SharedPopover({ content }: { content: string }) {
  return (
    <InfoPopover title="Search Details" anchor="left">
      <InfoPopover.Prose>{content}</InfoPopover.Prose>
    </InfoPopover>
  );
}

/** Search updates the navigable URL, which should trigger updates in state. */
export default function ClaimSearch({
  searchTerm,
  dateRange,
  rollupStatusCode,
  requestedResolutionMethod,
  orderBy,
  children,
}: ClaimSearchProps) {
  const navigateSearch = useNavigateSearch();

  return (
    <Panel>
      <div className="flex flex-col gap-2">
        <Label className="flex items-center gap-0.5">
          Search
          <SharedPopover
            content="Search by email, claim number, claim tag, order number, customer
          name, or return tracking number."
          />
        </Label>

        <TextInput
          id="search"
          label="Search"
          className="flex-grow"
          placeholder="Search claims"
          onChange={(e) => {
            // TODO consider debouncing this to avoid excessive network requests
            navigateSearch(new URLSearchParams({ searchTerm: e.target.value }));
          }}
          value={searchTerm}
          labelVisuallyHidden
        />
      </div>

      <SimpleSelect
        label="Status"
        value={rollupStatusCode}
        options={[
          { value: 'all', label: 'All' } as const,
          ...Object.values(CrewClaimRollupStatusCode).map((value) => ({
            value,
            label: crewClaimRollupStatusCodeName[value],
          })),
        ]}
        onChange={(selectedRollupStatusCode) => {
          navigateSearch(
            new URLSearchParams({
              rollupStatusCode: selectedRollupStatusCode,
            }),
          );
        }}
      />

      <SimpleSelect
        label={
          <div className="flex items-center gap-0.5">
            Resolution
            <SharedPopover content="Filter by customer requested resolution method." />
          </div>
        }
        value={requestedResolutionMethod}
        options={[
          { value: 'all', label: 'All' } as const,
          ...Object.values(SearchableCrewClaimResolutionMethod).map(
            (value) => ({
              value,
              label: searchableResolutionMethodName[value],
            }),
          ),
        ]}
        onChange={(method) => {
          navigateSearch(
            new URLSearchParams({
              requestedResolutionMethod: method,
            }),
          );
        }}
      />

      <div className="flex flex-col gap-2">
        <Label>Created Date</Label>
        <DateRangePicker
          defaultRange={dateRange}
          onSelect={(selectedDateRange) => {
            const definedDateRange = getDefinedDateRange(selectedDateRange); // TODO inform user that the range will default to the last 30 days if no range is selected
            navigateSearch(
              new URLSearchParams({
                startDate: definedDateRange.from.toISOString(),
                endDate: definedDateRange.to.toISOString(),
              }),
            );
          }}
        />
      </div>

      <SimpleSelect
        label="Order By"
        value={orderBy}
        options={
          [
            { value: 'asc', label: 'Oldest First' },
            { value: 'desc', label: 'Newest First' },
          ] as const
        }
        onChange={(selectedOrderBy) => {
          navigateSearch(
            new URLSearchParams({
              orderBy: selectedOrderBy,
            }),
          );
        }}
      />
      {children}
    </Panel>
  );
}
