import {
  CrewClaimResolutionMethodEnum,
  crewClaimResolutionMethodEnumName,
} from 'corso-types/enums/crew';
import { ReactNode, useState } from 'react';
import SimpleSelect from '~/components/ui/SimpleSelect';
import {
  resolutionMethodChangesAllowed,
  TransformableResolutionMethod,
} from '~/providers/ClaimReviewProvider';
import MonetaryModifier from './MonetaryModifier';
import ReplacementOrderModifier from './ReplacementOrderModifier';
import { ResolutionModifierProps } from './types';

/** Connection of resolvable resolution methods to components that can create the related resolution after human input. */
const resolutionModifier = {
  [CrewClaimResolutionMethodEnum.refund]: ({ reviewLineItem, onChange }) => (
    <MonetaryModifier
      resolutionMethod={CrewClaimResolutionMethodEnum.refund}
      reviewLineItem={reviewLineItem}
      onChange={onChange}
    />
  ),
  [CrewClaimResolutionMethodEnum.giftCard]: ({ reviewLineItem, onChange }) => (
    <MonetaryModifier
      resolutionMethod={CrewClaimResolutionMethodEnum.giftCard}
      reviewLineItem={reviewLineItem}
      onChange={onChange}
    />
  ),
  [CrewClaimResolutionMethodEnum.replacementOrder]: ({
    reviewLineItem,
    onChange,
  }) => (
    <ReplacementOrderModifier
      reviewLineItem={reviewLineItem}
      onChange={onChange}
    />
  ),
} as const satisfies Record<
  TransformableResolutionMethod,
  (props: ResolutionModifierProps) => ReactNode
>;

export default function ResolutionModifier({
  reviewLineItem,
  onChange,
}: ResolutionModifierProps) {
  const resolutionOptions =
    resolutionMethodChangesAllowed[
      reviewLineItem.claimLineItem.requestedResolutionMethodEnum
    ];
  const [modifiedResolutionMethod, setModifiedResolutionMethod] = useState<
    (typeof resolutionOptions)[number]
  >(resolutionOptions[0]);

  return (
    <>
      <SimpleSelect
        label="How would you like to resolve this claim?"
        options={resolutionOptions.map((resolutionMethod) => ({
          value: resolutionMethod,
          label: crewClaimResolutionMethodEnumName[resolutionMethod],
        }))}
        value={modifiedResolutionMethod}
        onChange={(selectedResolutionMethod) =>
          setModifiedResolutionMethod(selectedResolutionMethod)
        }
      />
      {resolutionModifier[modifiedResolutionMethod]({
        reviewLineItem,
        onChange,
      })}
    </>
  );
}
