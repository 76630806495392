import { z } from 'zod';

export const invoice = z.object({
  id: z.number(),
  createdOn: z.string(),
  toDate: z.string(),
  fromDate: z.string(),
  totalAmount: z.number(),
  isMonthlyCrew: z.boolean(),
  ordersCsvUrl: z.string().nullish(),
  reordersCsvUrl: z.string().nullish(),
  refundsCsvUrl: z.string().nullish(),
  adjustmentsCsvUrl: z.string().nullish(),
});
