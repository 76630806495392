import { z } from 'zod';
import { DbJsonSchema } from '../../dbJsonSchemas.js';
import {
  CrewClaimRollupStatusCode,
  CrewClaimTypeEnum,
  SearchableCrewClaimResolutionMethod,
} from '../../enums/crew.js';
import { ClickhouseDataset } from '../../enums/enum.js';
import { ClaimReasonEnum, ClaimResolutionMethodEnum } from '../../enums/gsp.js';
import { enumToZodLiteralUnion } from '../../zodExtensions.js';
import { variantExchange } from '../customer/schema/variantExchange.js';
import { claimCreate } from '../schema/claim.js';
import { crewOrder } from '../schema/order.js';
import { apiClientResp } from './error.js';
import { CrewMerchantUi } from './schema.js';

type HttpMethod = 'get' | 'post' | 'put' | 'delete'; // ? may need extended for all methods

//* user
const userPath = `/user/:idFromPlatform`;
const userParams = z.object({ idFromPlatform: z.string() });

const userAccessCodePath = `/users`;
const userAccessCodeQuery = z.object({ accessCode: z.string() });

const signedUrlPath = `/signed-url`;

const storeIdPath = `/:storeId`;
const storeIdParams = z.object({ storeId: z.coerce.number() });

const storeIdShippingProtectionPath =
  `${storeIdPath}/shipping-protection` as const;

const shippingProtectionConfigurePath =
  `${storeIdShippingProtectionPath}/configure` as const;

const shippingClaimPath = `${storeIdShippingProtectionPath}/claim` as const;

const registrationPath = `${storeIdPath}/registration` as const;
const registrationIdPath = `${registrationPath}/:registrationId` as const;

const storeIdProductIdFromPlatformParams = z
  .object({ idFromPlatform: z.coerce.number() })
  .merge(storeIdParams);

const storeCustomFieldPath = `${storeIdPath}/custom-field` as const;
const storeCustomFieldIdPath =
  `${storeCustomFieldPath}/:customFieldId` as const;

const storeRulePath = `${storeIdPath}/rule` as const;
const storeRuleIdPath = `${storeRulePath}/:ruleId` as const;
const factValuesPath = `${storeIdPath}/fact/values` as const;

const invoicePath = `${storeIdPath}/invoice` as const;

const customerPaymentPath = `${storeIdPath}/customer-payment` as const;
const customerPaymentIdPath =
  `${customerPaymentPath}/:customerPaymentId` as const;
const customerPaymentIdRefundPath = `${customerPaymentIdPath}/refund` as const;

const storeIdDashboardPath = `${storeIdPath}/dashboard` as const;
const storeIdDashboardLoadPath = `${storeIdDashboardPath}/load` as const;
const storeIdDashboardPromptPath = `${storeIdDashboardPath}/prompt` as const;

const settingsPath = `${storeIdPath}/settings` as const;
const settingsThemePath = `${settingsPath}/theme` as const;
const settingsConfigPath = `${settingsPath}/config` as const;
const settingsIntegrationPath = `${settingsPath}/integrations` as const;

const orderPath = `${storeIdPath}/order` as const;

// * return + warranty claims
const claimPath = `${storeIdPath}/claim` as const;
const claimTagPath = `${claimPath}/tags` as const;
const claimCountPath = `${claimPath}/count` as const;

const claimIdPath = `${claimPath}/:claimId` as const;
const claimIdFeesPath = `${claimIdPath}/fees` as const;
const claimIdCustomerInfoPath = `${claimIdPath}/customer-info` as const;
const claimIdTagPath = `${claimIdPath}/tags` as const;
const claimIdTimelinePath = `${claimIdPath}/timeline` as const;
const claimIdFinalizePath = `${claimIdPath}/finalize` as const;
const claimIdClosePath = `${claimIdPath}/close` as const;
const claimIdShipmentPath = `${claimIdPath}/shipment` as const;
const claimIdShipmentQuotePath = `${claimIdShipmentPath}/quote` as const;

const claimIdNeedsMoreInfoPath = `${claimIdPath}/request-info` as const;
const claimLineItemPath = `${claimIdPath}/line-item` as const;
const claimLineItemIdPath = `${claimLineItemPath}/:claimLineItemId` as const;
const claimLineItemIdInspectionPath =
  `${claimLineItemIdPath}/inspection` as const;

const claimLineItemIdInspectionIdPath =
  `${claimLineItemIdInspectionPath}/:claimInspectionId` as const;

const claimLineItemIdInspectionSettingsPath =
  `${claimLineItemIdInspectionPath}/settings` as const;

// * shipping claim
const shippingClaimIdPath = `${shippingClaimPath}/:shippingClaimId` as const;
const shippingClaimIdFinalizePath = `${shippingClaimIdPath}/finalize` as const;

// * shipment policy
const returnLocationPath = `${storeIdPath}/return-location` as const;
const returnLocationIdPath = `${returnLocationPath}/:returnLocationId` as const;

// * claim reasons (return +)
const claimReasonPath = `${storeIdPath}/claim-reason` as const;
const claimReasonIdPath = `${claimReasonPath}/:claimReasonId` as const;
const claimReasonGroupPath = `${claimReasonPath}/group` as const;
const claimReasonGroupIdPath =
  `${claimReasonGroupPath}/:claimReasonGroupId` as const;

// * store products
const storeProductPath = `${storeIdPath}/product` as const;
const storeProductIdPath = `${storeIdPath}/product/:idFromPlatform` as const;

const storeProductTagPath = `${storeProductPath}/tag` as const;
const storeProductTypePath = `${storeProductPath}/type` as const;
const storeProductCollectionPath = `${storeProductPath}/collection` as const;
const storeProductGroupPath = `${storeProductPath}/group` as const;

const storeProductGroupIdPath = `${storeProductGroupPath}/:groupId` as const;
const storeProductGroupIdListProductsPath =
  `${storeProductGroupIdPath}/list-products` as const;

// * store users
const storeUserPath = `${storeIdPath}/user` as const;
const storeUserSignupPath = `${storeUserPath}/sign-up` as const;
const storeUserIdPath = `${storeUserPath}/:userId` as const;
const storeUserPasswordResetPath = `${storeUserIdPath}/reset-password` as const;

// ------------------  Schemas  ------------------

const dateRangeQuery = z.object({
  startDate: z.string(),
  endDate: z.string(),
});

const claimListQuery = z
  .object({
    searchTerm: z.string().optional(),
    statusCode: enumToZodLiteralUnion(CrewClaimRollupStatusCode).optional(),
    take: z.coerce.number(),
    claimType: enumToZodLiteralUnion(CrewClaimTypeEnum),
    orderBy: z.union([z.literal('asc'), z.literal('desc')]),
    requestedResolutionMethod: enumToZodLiteralUnion(
      SearchableCrewClaimResolutionMethod,
    ).optional(),
  })
  .merge(dateRangeQuery);

const claimCountQuery = claimListQuery.omit({ take: true, orderBy: true });

const shippingClaimListQuery = claimListQuery
  .pick({
    searchTerm: true,
    take: true,
    startDate: true,
    endDate: true,
  })
  .merge(
    z.object({
      resolutionMethod: enumToZodLiteralUnion(
        ClaimResolutionMethodEnum,
      ).optional(),
      reason: enumToZodLiteralUnion(ClaimReasonEnum).optional(),
    }),
  );

const registrationListQuery = z
  .object({
    searchTerm: z.string().optional(),
    take: z.coerce.number(),
  })
  .merge(dateRangeQuery);

const signedUrlQuery = z.object({
  filename: z.string(),
  contentType: z.string(),
});

const storeProductQuery = z.object({
  searchTerm: z.string(),
});

const storeRuleIdParams = z
  .object({ ruleId: z.coerce.number() })
  .merge(storeIdParams);

const customFieldIdParams = z
  .object({
    customFieldId: z.coerce.number(),
  })
  .merge(storeIdParams);

const customerRefundIdParams = z
  .object({
    customerPaymentId: z.coerce.number(),
  })
  .merge(storeIdParams);

const claimIdParams = z
  .object({ claimId: z.coerce.number() })
  .merge(storeIdParams);

const shippingClaimIdParams = z
  .object({ shippingClaimId: z.coerce.number() })
  .merge(storeIdParams);

const registrationIdParams = z
  .object({ registrationId: z.coerce.number() })
  .merge(storeIdParams);

const storeDashboardLoadQuery = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  dashboardId: z.string(),
});

const storeDashboardPromptQuery = z.object({
  question: z.string(),
  dataset: enumToZodLiteralUnion(ClickhouseDataset),
});

const claimLineItemIdParams = claimIdParams.merge(
  z.object({ claimLineItemId: z.coerce.number() }),
);

const claimLineItemIdInspectionParams = claimLineItemIdParams.merge(
  z.object({ claimInspectionId: z.coerce.number() }),
);

const claimReasonIdParams = z
  .object({ claimReasonId: z.coerce.number() })
  .merge(storeIdParams);

const claimReasonGroupIdParams = z
  .object({ claimReasonGroupId: z.coerce.number() })
  .merge(storeIdParams);

const returnLocationIdParams = z
  .object({ returnLocationId: z.string() })
  .merge(storeIdParams);

const storeUserIdParams = z
  .object({
    userId: z.coerce.number(),
  })
  .merge(storeIdParams);

const storeProductGroupIdParams = z
  .object({
    groupId: z.coerce.number(),
  })
  .merge(storeIdParams);

const storeOrderQuery = z.union([
  z.object({
    idFromPlatform: z.string(),
  }),
  z.object({
    emailOrPostalCode: z.string(),
    orderNo: z.string(),
  }),
]);

export const crewMerchantRegistrationApi = {
  [registrationPath]: {
    path: registrationPath,
    get: {
      request: { params: storeIdParams, query: registrationListQuery },
      response: { body: CrewMerchantUi.registrationListSchema },
    },
  },
  [registrationIdPath]: {
    path: registrationIdPath,
    get: {
      request: { params: registrationIdParams },
      response: { body: CrewMerchantUi.registrationSchema },
    },
  },
};

export const crewMerchantShippingClaimApi = {
  [shippingClaimPath]: {
    path: shippingClaimPath,
    post: {
      request: {
        params: storeIdParams,
        body: CrewMerchantUi.shippingClaimCreateSchema,
      },
      response: { body: CrewMerchantUi.shippingClaimSchema },
    },
    get: {
      request: { params: storeIdParams, query: shippingClaimListQuery },
      response: { body: CrewMerchantUi.shippingClaimListSchema },
    },
  },
  [shippingClaimIdPath]: {
    path: shippingClaimIdPath,
    get: {
      request: { params: shippingClaimIdParams },
      response: { body: CrewMerchantUi.shippingClaimSchema },
    },
  },
  [shippingClaimIdFinalizePath]: {
    path: shippingClaimIdFinalizePath,
    post: {
      request: {
        params: shippingClaimIdParams,
        body: CrewMerchantUi.shippingClaimFinalizeSchema,
      },
      response: { body: CrewMerchantUi.shippingClaimSchema },
    },
  },
} as const;

/** Base CRUD Operations for a Claim */
const crewMerchantClaimCrudApi = {
  [claimPath]: {
    path: claimPath,
    get: {
      request: { params: storeIdParams, query: claimListQuery },
      response: { body: CrewMerchantUi.claimListSchema },
    },
    post: {
      request: { params: storeIdParams, body: claimCreate },
      response: { body: CrewMerchantUi.claimSchema },
    },
  },
  [claimCountPath]: {
    path: claimCountPath,
    get: {
      request: { params: storeIdParams, query: claimCountQuery },
      response: {
        body: CrewMerchantUi.claimListCountSchema,
      },
    },
  },
  [claimIdPath]: {
    path: claimIdPath,
    get: {
      request: { params: claimIdParams },
      response: { body: CrewMerchantUi.claimSchema },
    },
  },
  [claimIdFinalizePath]: {
    path: claimIdFinalizePath,
    post: {
      request: {
        params: claimIdParams,
        body: CrewMerchantUi.claimFinalizePayloadSchema,
      },
      response: { body: CrewMerchantUi.claimSchema },
    },
  },
  [claimIdClosePath]: {
    path: claimIdClosePath,
    post: {
      request: {
        params: claimIdParams,
      },
      response: { body: apiClientResp },
    },
  },
};

/**
 * Additional Claim APIs for Updating Related Entities or Complex Property Operations
 */
const crewMerchantClaimRelationsApi = {
  [claimIdCustomerInfoPath]: {
    path: claimIdCustomerInfoPath,
    put: {
      request: {
        params: claimIdParams,
        body: CrewMerchantUi.claimUpdateCustomerInfoSchema,
      },
      response: { body: CrewMerchantUi.claimSchema },
    },
  },
  [claimIdFeesPath]: {
    path: claimIdFeesPath,
    put: {
      request: {
        params: claimIdParams,
        body: CrewMerchantUi.claimUpdateRefundHandlingFeeSchema,
      },
      response: { body: CrewMerchantUi.claimSchema },
    },
  },
  [claimIdTagPath]: {
    path: claimIdTagPath,
    get: {
      request: { params: claimIdParams },
      response: { body: z.array(CrewMerchantUi.claimTagSchema) },
    },
    put: {
      request: {
        params: claimIdParams,
        body: z.array(CrewMerchantUi.claimTagSchema),
      },
      response: { body: z.array(CrewMerchantUi.claimTagSchema) },
    },
  },
  [claimIdNeedsMoreInfoPath]: {
    path: claimIdNeedsMoreInfoPath,
    post: {
      request: {
        params: claimIdParams,
        body: CrewMerchantUi.claimNeedsMoreInfoSchema,
      },
    },
  },
  [claimIdShipmentPath]: {
    path: claimIdShipmentPath,
    post: {
      request: {
        params: claimIdParams,
        body: CrewMerchantUi.claimReturnShipmentCreateSchema,
      },
      response: { body: CrewMerchantUi.claimSchema },
    },
  },
  [claimIdShipmentQuotePath]: {
    path: claimIdShipmentQuotePath,
    post: {
      request: {
        params: claimIdParams,
        body: CrewMerchantUi.claimReturnShipmentQuoteSchema,
      },
      response: { body: CrewMerchantUi.claimReturnShipmentQuoteResponse },
    },
  },
  [claimLineItemIdInspectionPath]: {
    path: claimLineItemIdInspectionPath,
    post: {
      request: {
        params: claimLineItemIdParams,
        body: CrewMerchantUi.claimLineItemInspectionCreateSchema,
      },
      response: { body: CrewMerchantUi.claimLineItemInspectionSchema },
    },
  },
  [claimLineItemIdInspectionIdPath]: {
    path: claimLineItemIdInspectionIdPath,
    delete: {
      request: {
        params: claimLineItemIdInspectionParams,
      },
      response: {
        body: apiClientResp,
      },
    },
  },
  [claimIdTimelinePath]: {
    path: claimIdTimelinePath,
    post: {
      request: {
        params: claimIdParams,
        body: CrewMerchantUi.claimTimelineEventSchema,
      },
      response: { body: apiClientResp },
    },
  },
} as const;

/**
 * Merge of claim APIs.
 *
 * Defined with an explicit type annotation.
 * Otherwise, TypeScript will error with TS7056: 'The inferred type of this node exceeds the maximum length the compiler will serialize. An explicit type annotation is needed.'
 *
 * This so a workaround to handle the limit, but we might need to consider a different approach for our APIs leveraging the builder pattern at some point, so the types can be incrementally built/joined together.
 * A few related discussions:
 * - [Zod #1040](https://github.com/colinhacks/zod/issues/1040)
 * - [Comment from Colin McDonnell on Zod #3719](https://github.com/colinhacks/zod/issues/3719#issuecomment-2322247736) noting that Zod 4 will have simplified internals and should help with this a little.
 * - [Suggested Builder Pattern](https://github.com/microsoft/TypeScript/issues/43817#issuecomment-827746462) from [Relevant StackOverflow Answer](https://stackoverflow.com/a/69908599)
 */
export const crewMerchantClaimApi: typeof crewMerchantClaimCrudApi &
  typeof crewMerchantClaimRelationsApi = {
  ...crewMerchantClaimCrudApi,
  ...crewMerchantClaimRelationsApi,
} as const;

export const crewMerchantApi = {
  [storeIdPath]: {
    path: storeIdPath,
    use: storeIdParams,
  },
  [shippingProtectionConfigurePath]: {
    path: shippingProtectionConfigurePath,
    post: {
      request: {},
      response: {
        body: CrewMerchantUi.shipProtectSettingsSchema,
      },
    },
  },
  [invoicePath]: {
    path: invoicePath,
    get: {
      request: { params: storeIdParams },
      response: { body: CrewMerchantUi.invoiceSchema.array() },
    },
  },
  [orderPath]: {
    path: orderPath,
    get: {
      request: { params: storeIdParams, query: storeOrderQuery },
      response: { body: crewOrder },
    },
  },
  [userPath]: {
    path: userPath,
    get: {
      request: { params: userParams },
      response: { body: CrewMerchantUi.userWithStoreUserRolesSchema },
    },
  },
  [userAccessCodePath]: {
    path: userAccessCodePath,
    get: {
      request: { query: userAccessCodeQuery },
      response: { body: CrewMerchantUi.userAccessCodeSchema },
    },
  },
  [signedUrlPath]: {
    path: signedUrlPath,
    get: {
      request: { query: signedUrlQuery },
      response: { body: CrewMerchantUi.signedUrlSchema },
    },
  },
  [storeIdDashboardPath]: {
    path: storeIdDashboardPath,
    get: {
      request: { params: storeIdParams },
      response: { body: DbJsonSchema.StoreSettings.dashboards },
    },
  },
  [storeIdDashboardLoadPath]: {
    path: storeIdDashboardLoadPath,
    get: {
      request: { params: storeIdParams, query: storeDashboardLoadQuery },
      response: { body: CrewMerchantUi.supersetTokenSchema },
    },
  },
  [storeIdDashboardPromptPath]: {
    path: storeIdDashboardPromptPath,
    get: {
      request: {
        params: storeIdParams,
        query: storeDashboardPromptQuery,
      },
      response: {
        body: z.object({
          answer: z.string(),
        }),
      },
    },
  },
  [storeCustomFieldPath]: {
    path: storeCustomFieldPath,
    get: {
      request: {},
      response: { body: z.array(CrewMerchantUi.customFieldSchema) },
    },
    post: {
      request: {
        params: storeIdParams,
        body: CrewMerchantUi.customFieldCreateSchema,
      },
      response: { body: CrewMerchantUi.customFieldSchema },
    },
  },
  [storeCustomFieldIdPath]: {
    path: storeCustomFieldIdPath,
    get: {
      request: { params: customFieldIdParams },
      response: { body: CrewMerchantUi.customFieldSchema },
    },
    put: {
      request: {
        params: customFieldIdParams,
        body: CrewMerchantUi.customFieldUpdateSchema,
      },
      response: { body: CrewMerchantUi.customFieldSchema },
    },
    delete: {
      request: {
        params: customFieldIdParams,
      },
      response: { body: CrewMerchantUi.apiClientRespSchema },
    },
  },
  [claimTagPath]: {
    path: claimTagPath,
    get: {
      request: { params: storeIdParams },
      response: { body: z.array(CrewMerchantUi.claimTagSchema) },
    },
  },
  [storeRulePath]: {
    path: storeRulePath,
    get: {
      request: { params: storeIdParams },
      response: { body: z.array(CrewMerchantUi.storeRuleSchema) },
    },
    post: {
      request: {
        params: storeIdParams,
        body: CrewMerchantUi.storeRuleCreateSchema,
      },
      response: { body: CrewMerchantUi.storeRuleSchema },
    },
  },
  [storeRuleIdPath]: {
    path: storeRuleIdPath,
    get: {
      request: { params: storeRuleIdParams },
      response: { body: CrewMerchantUi.storeRuleSchema },
    },
    put: {
      request: {
        params: storeRuleIdParams,
        body: CrewMerchantUi.storeRuleUpdateSchema,
      },
      response: { body: CrewMerchantUi.storeRuleSchema },
    },
    delete: {
      request: {
        params: storeRuleIdParams,
      },
      response: { body: CrewMerchantUi.apiClientRespSchema },
    },
  },
  [factValuesPath]: {
    path: factValuesPath,
    get: {
      request: { params: storeIdParams },
      response: { body: CrewMerchantUi.arrayFactDataSchema },
    },
  },
  [settingsThemePath]: {
    path: settingsThemePath,
    get: {
      request: { params: storeIdParams },
      response: { body: CrewMerchantUi.settingsThemeSchema },
    },
    put: {
      request: {
        params: storeIdParams,
        body: CrewMerchantUi.settingsThemeSchema,
      },
      response: { body: CrewMerchantUi.settingsThemeSchema },
    },
  },
  [customerPaymentIdRefundPath]: {
    path: customerPaymentIdRefundPath,
    post: {
      request: {
        params: customerRefundIdParams,
      },
      response: { body: CrewMerchantUi.claimCustomerPaymentSchema },
    },
  },
  [settingsIntegrationPath]: {
    path: settingsIntegrationPath,
    get: {
      request: { params: storeIdParams },
      response: { body: CrewMerchantUi.settingsIntegrationSchema },
    },
    put: {
      request: {
        params: storeIdParams,
        body: CrewMerchantUi.settingsIntegrationUpdateSchema,
      },
      response: { body: CrewMerchantUi.settingsIntegrationSchema },
    },
  },
  [settingsConfigPath]: {
    path: settingsConfigPath,
    get: {
      request: { params: storeIdParams },
      response: { body: CrewMerchantUi.settingsConfigSchema },
    },
    put: {
      request: {
        params: storeIdParams,
        body: CrewMerchantUi.settingsConfigSchema,
      },
      response: { body: CrewMerchantUi.settingsConfigSchema },
    },
  },

  [storeProductPath]: {
    path: storeProductPath,
    get: {
      request: { params: storeIdParams, query: storeProductQuery },
      response: { body: z.array(CrewMerchantUi.storeProductSchema) },
    },
  },
  [storeProductIdPath]: {
    path: storeProductIdPath,
    get: {
      request: { params: storeIdProductIdFromPlatformParams },
      response: { body: variantExchange },
    },
  },
  [storeProductTagPath]: {
    path: storeProductTagPath,
    get: {
      request: { params: storeIdParams },
      response: {
        body: z.array(CrewMerchantUi.storeProductTagSchema),
      },
    },
  },
  [storeProductCollectionPath]: {
    path: storeProductCollectionPath,
    get: {
      request: { params: storeIdParams },
      response: {
        body: z.array(CrewMerchantUi.storeProductCollectionSchema),
      },
    },
  },
  [storeProductTypePath]: {
    path: storeProductTypePath,
    get: {
      request: { params: storeIdParams },
      response: {
        body: z.array(CrewMerchantUi.storeProductTypeSchema),
      },
    },
  },
  [storeProductGroupPath]: {
    path: storeProductGroupPath,
    get: {
      request: { params: storeIdParams },
      response: { body: z.array(CrewMerchantUi.storeProductGroupSchema) },
    },
    post: {
      request: {
        params: storeIdParams,
        body: CrewMerchantUi.storeProductGroupCreateSchema,
      },
      response: { body: CrewMerchantUi.storeProductGroupSchema },
    },
  },
  [storeProductGroupIdPath]: {
    path: storeProductGroupIdPath,
    put: {
      request: {
        params: storeProductGroupIdParams,
        body: CrewMerchantUi.storeProductGroupUpdateSchema,
      },
      response: { body: CrewMerchantUi.storeProductGroupSchema },
    },
    delete: {
      request: {
        params: storeProductGroupIdParams,
      },
      response: { body: CrewMerchantUi.apiClientRespSchema },
    },
  },
  [storeProductGroupIdListProductsPath]: {
    path: storeProductGroupIdListProductsPath,
    get: {
      request: {
        params: storeProductGroupIdParams,
      },
      response: { body: z.array(CrewMerchantUi.storeProductsForGroupSchema) },
    },
  },

  [returnLocationPath]: {
    path: returnLocationPath,
    get: {
      request: { params: storeIdParams },
      response: { body: z.array(CrewMerchantUi.returnLocationSchema) },
    },
    post: {
      request: {
        params: storeIdParams,
        body: CrewMerchantUi.returnLocationCreateSchema,
      },
      response: { body: CrewMerchantUi.returnLocationSchema },
    },
  },

  [returnLocationIdPath]: {
    path: returnLocationIdPath,
    put: {
      request: {
        params: returnLocationIdParams,
        body: CrewMerchantUi.returnLocationSchema,
      },
      response: { body: CrewMerchantUi.returnLocationSchema },
    },
    delete: {
      request: {
        params: returnLocationIdParams,
      },
      response: { body: CrewMerchantUi.apiClientRespSchema },
    },
  },
  [claimReasonPath]: {
    path: claimReasonPath,
    get: {
      request: {
        params: storeIdParams,
      },
      response: { body: z.array(CrewMerchantUi.claimReasonSchema) },
    },
    post: {
      request: {
        params: storeIdParams,
        body: CrewMerchantUi.claimReasonCreateSchema,
      },
      response: { body: CrewMerchantUi.claimReasonSchema },
    },
  },
  [claimReasonIdPath]: {
    path: claimReasonIdPath,
    put: {
      request: {
        params: claimReasonIdParams,
        body: CrewMerchantUi.claimReasonUpdateSchema,
      },
      response: { body: CrewMerchantUi.claimReasonSchema },
    },
  },
  [claimReasonGroupPath]: {
    path: claimReasonGroupPath,
    get: {
      request: {
        params: storeIdPath,
      },
      response: { body: z.array(CrewMerchantUi.claimReasonGroupSchema) },
    },
    post: {
      request: {
        params: storeIdPath,
        body: CrewMerchantUi.claimReasonGroupCreateSchema,
      },
      response: { body: CrewMerchantUi.claimReasonGroupSchema },
    },
  },
  [claimReasonGroupIdPath]: {
    path: claimReasonGroupIdPath,
    put: {
      request: {
        params: claimReasonGroupIdParams,
        body: CrewMerchantUi.claimReasonGroupUpdateSchema,
      },
      response: { body: CrewMerchantUi.claimReasonGroupSchema },
    },
    delete: {
      request: {
        params: claimReasonGroupIdParams,
      },
      response: { body: CrewMerchantUi.apiClientRespSchema },
    },
  },
  [claimLineItemIdInspectionSettingsPath]: {
    path: claimLineItemIdInspectionSettingsPath,
    get: {
      request: {
        params: storeIdParams,
      },
      response: {
        body: CrewMerchantUi.storeInspectionSettingsSchema,
      },
    },
  },
  [storeUserPath]: {
    path: storeUserPath,
    get: {
      request: {
        params: storeIdParams,
      },
      response: {
        body: z.array(CrewMerchantUi.storeUserSchema),
      },
    },
    post: {
      request: {
        body: CrewMerchantUi.storeUserCreateSchema,
        params: storeIdParams,
      },
      response: {
        body: CrewMerchantUi.storeUserSchema,
      },
    },
  },
  [storeUserSignupPath]: {
    path: storeUserSignupPath,

    post: {
      request: {
        body: CrewMerchantUi.storeUserSignupCreateSchema,
        params: storeIdParams,
      },
      response: {
        body: CrewMerchantUi.storeUserSignupCreateSchema,
      },
    },
  },
  [storeUserIdPath]: {
    path: storeUserIdPath,
    put: {
      request: {
        params: storeUserIdParams,
        body: CrewMerchantUi.storeUserUpdateSchema,
      },
      response: {
        body: CrewMerchantUi.storeUserSchema,
      },
    },
    delete: {
      request: {
        params: storeUserIdParams,
      },
      response: {
        body: CrewMerchantUi.apiClientRespSchema,
      },
    },
  },
  [storeUserPasswordResetPath]: {
    path: storeUserPasswordResetPath,
    post: {
      request: {
        params: storeUserIdParams,
        body: CrewMerchantUi.storeUserSchema,
      },
      response: {
        body: CrewMerchantUi.storeUserSchema,
      },
    },
  },
} as const;
// ? we may want some kind of satisfier on the schema to ensure it matches requirements of URLs; i.e. all path params and query params are strings unless coerced

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CrewMerchantApi {
  type Api = typeof crewMerchantApi &
    typeof crewMerchantClaimApi &
    typeof crewMerchantShippingClaimApi &
    typeof crewMerchantRegistrationApi; // * this merges all API objects into one type to help avoid the exceeding the inference depth
  export type ApiPath = keyof Api;

  // TODO remove these types and use the `RequestQuery` alternatives instead to keep the namespace slim
  export type ClaimListQuery = z.infer<typeof claimListQuery>;
  export type ShippingClaimListQuery = z.infer<typeof shippingClaimListQuery>;
  export type ClaimCountQuery = z.infer<typeof claimCountQuery>;
  export type StoreProductQuery = z.infer<typeof storeProductQuery>;
  export type DashboardLoadQuery = z.infer<typeof storeDashboardLoadQuery>;
  export const loggedInUserName = 'x-crew-user-name';

  export type MethodOfPath<Path extends ApiPath> = keyof Api[Path] & HttpMethod;

  // * helper request / response body types
  export type ResponseBody<
    Path extends ApiPath,
    Method extends MethodOfPath<Path>,
  > =
    Api[Path] extends Record<Method, { response: { body: z.ZodSchema } }> ?
      z.infer<Api[Path][Method]['response']['body']>
    : never;

  export type RequestBody<
    Path extends ApiPath,
    Method extends MethodOfPath<Path>,
  > =
    Api[Path] extends Record<Method, { request: { body: z.ZodSchema } }> ?
      z.infer<Api[Path][Method]['request']['body']>
    : never;

  export type RequestQuery<
    Path extends ApiPath,
    Method extends MethodOfPath<Path>,
  > =
    Api[Path][Method] extends (
      {
        request: { query: z.ZodSchema };
      }
    ) ?
      z.infer<Api[Path][Method]['request']['query']> // TODO find a nicer way to extract this, and other properties/types
    : never;
}
