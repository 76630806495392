import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { CrewMerchantUi } from 'corso-types/crew/merchant/schema';
import { NavLink, useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import Panel from '~/components/Panel';
import RelativeDateTime from '~/components/RelativeDateTime';
import Skeleton from '~/components/Skeleton';

function RegistrationSkeleton() {
  return (
    <div className="flex flex-col gap-2 p-2">
      <Skeleton.Rectangle height="20px" width="60%" />
      <Skeleton.TextBlock lines={2} />
    </div>
  );
}

export default function RegistrationList({
  registrations = [],
  isLoading = false,
}: {
  registrations?: CrewMerchantUi.RegistrationList;
  isLoading?: boolean;
}) {
  const location = useLocation();

  return (
    <Panel className="overflow-hidden p-0 lg:p-0">
      <Skeleton
        instances={1}
        skeleton={RegistrationSkeleton}
        isLoading={isLoading}
      >
        <ul className="divide-y divide-corso-gray-200 overflow-y-auto lg:max-h-[1200px]">
          {registrations.length === 0 && !isLoading && (
            <li className="p-4">
              <p className="font-semibold">No Registrations</p>
              <p className="text-sm text-corso-gray-500">
                There are no registration found.
              </p>
            </li>
          )}
          {registrations.map((reg) => (
            <li key={`claim-${reg.id}`} className="group/claim">
              <NavLink
                className={({ isActive }) =>
                  twMerge(
                    'flex items-center justify-between gap-2 p-4 hover:bg-corso-gray-100 focus:bg-corso-gray-100 group-first/claim:rounded-t-md group-last/claim:rounded-b-md',
                    isActive &&
                      'bg-corso-gray-100 hover:bg-corso-gray-100 focus:bg-corso-gray-100',
                  )
                }
                to={{
                  pathname: `${reg.id}`,
                  search: location.search,
                }}
              >
                <div className="flex flex-col gap-2">
                  <div className="flex flex-col justify-center">
                    <div className="flex items-center gap-2">
                      <p className="font-semibold">#{reg.externalId}</p>
                    </div>
                    <span className="text-sm">
                      {reg.registrationChannelName}
                    </span>
                  </div>
                  <div className="flex flex-col gap-1">
                    <p className="text-xs text-corso-gray-500">
                      {reg.customerName}
                    </p>

                    <p className="text-xs text-corso-gray-500">{reg.email}</p>
                    <p className="text-xs text-corso-gray-500">
                      Created{' '}
                      <RelativeDateTime dateTime={reg.createdOn} compact />
                    </p>
                  </div>
                </div>
                <ChevronRightIcon
                  className="h-5 w-5 text-corso-gray-500"
                  aria-hidden="true"
                />
              </NavLink>
            </li>
          ))}
        </ul>
      </Skeleton>
    </Panel>
  );
}
