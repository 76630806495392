import LineItem from '~/components/LineItem';
import { useMerchantContext } from '~/providers/MerchantProvider';
import { formatter } from '~/utils/formatter';
import ResolutionLineItemTitle from './ReslutionLineItemTitle';
import { MonetaryResolutionLineItemProps } from './types';

export default function GiftCardLineItem({
  compareAmount,
  amount,
  merchandiseAmount,
  incentiveAmount,
  replacementOrder,
}: MonetaryResolutionLineItemProps & {
  merchandiseAmount?: number;
  incentiveAmount?: number;
  // * replacement order is not always present on gift-cards
  // * ex: shopify plus customers will be able to issue gift-cards without a replacement order
  replacementOrder?: {
    nameFromPlatform: string | undefined;
    adminLink: string | undefined;
  };
}) {
  const {
    storeUser: {
      store: { currencyCode },
    },
  } = useMerchantContext();

  return (
    <ResolutionLineItemTitle
      title="Gift Card"
      orderNumber={replacementOrder?.nameFromPlatform}
      orderHref={replacementOrder?.adminLink}
    >
      <LineItem
        variant="no-image"
        name="Total Amount"
        compareUnitPrice={compareAmount}
        unitPrice={amount}
      >
        {merchandiseAmount !== undefined && (
          <p className="flex justify-between gap-2 text-sm text-corso-gray-500">
            <span>Merchandise Amount</span>
            <span>{formatter.currency(merchandiseAmount, currencyCode)}</span>
          </p>
        )}
        {incentiveAmount !== undefined && (
          <p className="flex justify-between gap-2 text-sm text-corso-gray-500">
            <span>Bonus Incentive Offered</span>
            <span>{formatter.currency(incentiveAmount, currencyCode)}</span>
          </p>
        )}
      </LineItem>
    </ResolutionLineItemTitle>
  );
}
