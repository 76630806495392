import { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

/**
 * A simple wrapper element to help provide consistent spacing between children in a flow-like context.
 * Provides a `className` prop to allow customization of the wrapper element; however, customizations should be limited to spacing and not layout.
 * The classes of this wrapper will override any classes provided by the `className` prop to keep it consistent.
 */
export default function ContentWrapper({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) {
  return (
    // * the order of these classes prevents overriding these default styles
    <div className={twMerge(className, 'flex flex-col gap-2')}>{children}</div>
  );
}
