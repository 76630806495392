import {
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline';
import { JSX } from 'react';
import Button from './Button';
import Modal from './Modal';

// ? possibly abstract a generic, reusable modal component

type ConfirmVariant = 'danger' | 'warning';

const variantIcon = {
  danger: (
    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-corso-red-50 sm:mx-0 sm:h-10 sm:w-10">
      <ExclamationTriangleIcon
        className="h-6 w-6 text-corso-red-600"
        aria-hidden="true"
      />
    </div>
  ),
  warning: (
    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-amber-100 sm:mx-0 sm:h-10 sm:w-10">
      <ExclamationCircleIcon
        className="h-6 w-6 text-amber-600"
        aria-hidden="true"
      />
    </div>
  ),
} satisfies Record<ConfirmVariant, JSX.Element>;

type ConfirmModalProps = {
  title: string;
  prompt: string;
  show: boolean;
  onCancel: () => void;
  cancelText?: string;

  onConfirm: () => void;
  confirmText?: string;

  variant?: ConfirmVariant;
};

export default function ConfirmModal({
  title,
  prompt,
  show,
  onCancel,
  cancelText = 'Cancel',
  onConfirm,
  confirmText = 'OK',
  variant = 'danger',
}: ConfirmModalProps) {
  return (
    <Modal
      show={show}
      onClose={onCancel}
      title={title}
      icon={variantIcon[variant]}
      actions={
        <>
          <Button onClick={onCancel}>{cancelText}</Button>
          <Button variant={variant} onClick={onConfirm}>
            {confirmText}
          </Button>
        </>
      }
    >
      <p className="text-sm text-corso-gray-500">{prompt}</p>
    </Modal>
  );
}
