import { useQuery } from '@tanstack/react-query';
import { useDebounce } from '@uidotdev/usehooks';
import { ClaimReasonEnum, ClaimResolutionMethodEnum } from 'corso-types';
import { endOfDay, startOfDay, subDays } from 'date-fns';
import { useMemo } from 'react';
import { Navigate, Outlet, useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import api from '~/api';
import BackAction from '~/components/BackAction';
import EmptyDetails from '~/components/claim/EmptyDetails';
import ContentWrapper from '~/components/ContentWrapper';
import Page from '~/components/Page';
import Panel from '~/components/Panel';
import ShippingClaimList from '~/components/shippingClaim/ShippingClaimList';
import ShippingClaimSearch from '~/components/shippingClaim/ShippingClaimSearch';
import useIsDesktop from '~/hooks/useIsDesktop';
import { usePathParams } from '~/hooks/usePathParams';

const searchParamsSchema = z.preprocess(
  (input) =>
    input instanceof URLSearchParams ?
      [...input.entries()].reduce(
        (acc, [key, value]) => ({ ...acc, [key]: value }),
        {},
      )
    : input,
  z.object({
    searchTerm: z.string().default(''),
    startDate: z.coerce.date().default(subDays(new Date(), 30)),
    endDate: z.coerce.date().default(new Date()),
    // TODO: everything above here is reused from the claim search
    resolutionMethod: z
      .nativeEnum(ClaimResolutionMethodEnum)
      .or(z.literal('all'))
      .default('all'),
    reason: z.nativeEnum(ClaimReasonEnum).or(z.literal('all')).default('all'),
  }),
);

const paramsSchema = z.object({
  storeId: z.string(),
  shippingClaimId: z.string().optional(),
});

/** Represents a sidebar and main content layout, which intelligently shows both on desktop, while separating the sidebar list navigation and individual items on mobile. */
export default function ShippingClaimsLayout() {
  const [searchParams] = useSearchParams();
  const params = usePathParams(paramsSchema);
  const searchData = useMemo(
    () => searchParamsSchema.parse(searchParams),
    [searchParams],
  );

  /** Debounce data, so that the query parameter and derived state can be updated in real time, but the query key can be debounced. */
  const debouncedSearchData = useDebounce(searchData, 500);

  // TODO paginated/virtualized
  // TODO loading skeleton / disabled state of form while query loading
  // TODO error state
  const { data: claimSearchResults = [], isLoading } = useQuery({
    refetchOnMount: true,
    queryKey: ['shippingClaims', params.storeId, debouncedSearchData],
    queryFn: async () => {
      const { searchTerm, startDate, endDate, reason, resolutionMethod } =
        debouncedSearchData;
      const searchParameters = {
        ...(resolutionMethod === 'all' ? null : { resolutionMethod }),
        ...(reason === 'all' ? null : { reason }),
        // regarding range, it's inclusive, but this just makes the range explicit to the expectation
        startDate: startOfDay(startDate),
        endDate: endOfDay(endDate),
        searchTerm,
      };

      const shippingClaimList = await api
        .store(params.storeId)
        .shippingProtection.listClaims(searchParameters);

      return shippingClaimList;
    },
    retry: 1,
  });

  const isDesktop = useIsDesktop();
  const headline = `Shipping`;

  const [firstClaim, ...additionalClaims] = claimSearchResults;
  // if there is only one claim found, and it differs from the current, redirect to the first claim
  if (
    firstClaim &&
    !additionalClaims.length &&
    params.shippingClaimId !== `${firstClaim.id}`
  ) {
    return (
      <Navigate
        to={{
          pathname: `${firstClaim.id}`,
          search: searchParams.toString(),
        }}
      />
    );
  }

  if (!isDesktop) {
    return (
      <Page headline={headline}>
        <ContentWrapper>
          {params.shippingClaimId ?
            <>
              <BackAction.Link text="Claims" />
              <Panel className="gap-0 p-0 lg:p-0">
                <Outlet />
              </Panel>
            </>
          : <>
              <ShippingClaimSearch
                resolutionMethod={searchData.resolutionMethod}
                searchTerm={searchData.searchTerm}
                dateRange={{
                  from: searchData.startDate,
                  to: searchData.endDate,
                }}
                reason={searchData.reason}
              />
              <ShippingClaimList
                shippingClaims={claimSearchResults}
                isLoading={isLoading}
              />
            </>
          }
        </ContentWrapper>
      </Page>
    );
  }

  // desktop layout with list and details in a multi-column layout
  return (
    <Page headline={headline}>
      <ContentWrapper>
        <div className="grid grid-cols-4 items-start gap-4">
          {/*
           * // * top-20 is a magic value so that it doesn't slip behind the app layout navigation
           * // * z index is required to ensure any absolute positioned elements such as the date picker are above the app main content
           */}
          <ContentWrapper className="sticky top-20 z-10 w-full self-start">
            <ShippingClaimSearch
              resolutionMethod={searchData.resolutionMethod}
              searchTerm={searchData.searchTerm}
              dateRange={{
                from: searchData.startDate,
                to: searchData.endDate,
              }}
              reason={searchData.reason}
            />
            <ShippingClaimList
              shippingClaims={claimSearchResults}
              isLoading={isLoading}
            />
          </ContentWrapper>
          <div className="col-span-3">
            {params.shippingClaimId ?
              <Outlet />
            : <EmptyDetails
                title="No Shipping Claim Selected"
                description="Select a claim from the list to review."
                claimType="Shipping"
              />
            }
          </div>
        </div>
      </ContentWrapper>
    </Page>
  );
}
