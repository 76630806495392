import { CrewMerchantUi } from 'corso-types/crew/merchant/schema';
import Disclosure, { SimpleSummary } from '~/components/Disclosure';
import MediaGallery from '~/components/MediaGallery';
import { Badge } from '~/components/ui/primitives/Badge';

export default function CustomerMedia({
  assets,
}: {
  assets: CrewMerchantUi.ClaimLineItem['images'];
}) {
  if (!assets?.length) return null;

  return (
    <div className="rounded-md border">
      <Disclosure
        defaultOpen
        renderSummary={
          <SimpleSummary>
            <span className="ml-2 text-corso-gray-500">
              {/* // ? maybe extract to pluralize function */}
              Customer Images
            </span>
            <Badge className="ml-auto">{assets.length}</Badge>
          </SimpleSummary>
        }
      >
        <MediaGallery
          media={assets.map((src) => ({ src, alt: 'Customer Media' }))}
        />
      </Disclosure>
    </div>
  );
}
