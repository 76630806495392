export default function NoSubscription() {
  return (
    <main
      className="item-center flex min-h-full place-items-center justify-center bg-black"
      data-testid="subscription-expired"
    >
      <div className="relative flex max-w-[400px] flex-grow flex-col overflow-hidden rounded-md bg-white p-12 text-[16px] text-corso-gray-900 shadow-sm">
        <img
          className="mx-auto block max-h-[52px] max-w-full object-contain"
          src="https://cdn.corso.com/img/Corso_Logo_Auth0.png"
          alt="Corso "
        />
        <h1 className="mb-4 mt-8 text-center text-lg">Subscription Expired</h1>
        <div className="flex flex-col gap-4">
          <p className="text-center text-sm">
            You have reached the end of your subscription.
          </p>
          <p className="text-center text-sm [text-wrap:balance]">
            <a
              className="underline hover:underline"
              href="https://corso.com/book-a-time"
              target="_blank"
              rel="noreferrer"
            >
              Contact Us
            </a>{' '}
            to continue using the Corso App, free forever.
          </p>
        </div>
      </div>
    </main>
  );
}
