import { CrewClaimTypeEnum } from 'corso-types';
import { Navigate } from 'react-router-dom';
import { useEnabledClaimType } from '~/hooks/useEnabledClaimType';

/** Given the current configuration, changes what the default route is when initially opening the app. */
export default function DefaultStoreNavigation() {
  const {
    isLoading,
    isWarrantyEnabled,
    isReturnsEnabled,
    isShippingProtectionEnabled,
  } = useEnabledClaimType();

  if (isLoading) return null; // ? might need some kind of better loader here, once this changes to use the `actions`, `loaders` model it'll be better

  if (isReturnsEnabled)
    return (
      <Navigate
        to={`claims/${CrewClaimTypeEnum.return.toLowerCase()}`}
        replace
      />
    );

  if (isWarrantyEnabled)
    return (
      <Navigate
        to={`claims/${CrewClaimTypeEnum.warranty.toLowerCase()}`}
        replace
      />
    );

  if (isShippingProtectionEnabled)
    return <Navigate to="claims/shipping" replace />;

  return <Navigate to="analytics" replace />;
}
