import { useAuth0, User } from '@auth0/auth0-react';
import { createContext, ReactNode, useContext, useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import FullPageLoader from '~/components/FullPageLoader';
import { useSearchParam } from '~/hooks/useSearchParam';
import { ACCESS_CODE_KEY, LoadingContext } from '~/types';

type AuthenticationProviderContext = LoadingContext<{ auth0User: User }>;

const AuthenticationContext = createContext<AuthenticationProviderContext>({
  isLoading: true,
});

export function useAuthenticationContext() {
  const context = useContext(AuthenticationContext);
  // similar to throwing when not in a provider context
  if (context.isLoading) {
    throw new Error('Authentication Context Still Loading');
  }

  return context;
}

/** Renders children only once a user has authenticated via Auth0, and the user data is loaded. */
export function AuthenticationProvider({ children }: { children?: ReactNode }) {
  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    user: auth0User,
  } = useAuth0();

  const accessCode = useSearchParam(ACCESS_CODE_KEY);

  const value = useMemo<AuthenticationProviderContext>(() => {
    if (isLoading || !auth0User) return { isLoading: true };
    return {
      isLoading,
      auth0User,
    };
  }, [isLoading, auth0User]);

  if (isLoading) return <FullPageLoader />;

  if (accessCode && !isAuthenticated) {
    return (
      <>
        <FullPageLoader />
        <Navigate to={`/welcome?${ACCESS_CODE_KEY}=${accessCode}`} replace />;
      </>
    );
  }

  // not logged in; redirect to login page, but show loading while redirecting
  if (!isAuthenticated) {
    loginWithRedirect({
      appState: { returnTo: window.location.pathname },
    }).catch((error) => console.error(error));

    return <FullPageLoader />;
  }

  return (
    <AuthenticationContext.Provider value={value}>
      {children}
    </AuthenticationContext.Provider>
  );
}
