import DetailInfoItem from '~/components/DetailInfoItem';

export default function ResolutionLineItemTitle({
  children,
  title,
  orderNumber,
  orderHref: link,
}: {
  children: React.ReactNode;
  title: string;
  orderNumber?: string;

  orderHref?: string;
}) {
  return (
    <div className="flex flex-col gap-2">
      <div className="mb-4 flex flex-col gap-0.5">
        <span className="text-base font-medium text-corso-gray-500">
          {title}
        </span>{' '}
        {link && (
          <div className="flex items-center align-middle">
            <DetailInfoItem
              variant="link"
              icon={
                <img
                  className="w-5"
                  alt="shopify-order"
                  src="https://cdn.corso.com/img/shopify-logo-gray.png"
                />
              }
              href={link}
              content={orderNumber ? `Shopify Order ${orderNumber}` : undefined}
            />
          </div>
        )}
      </div>

      {children}
    </div>
  );
}
