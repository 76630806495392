import { ConfigSettings, ReturnSettingsFormValues } from '~/types';
import {
  useConfigSettings,
  useConfigSettingsUpdate,
} from './useConfigSettings';

const select = ({
  returns,
  giftCards,
  ...config
}: ConfigSettings): ReturnSettingsFormValues => {
  const { giftCardValidityDays } = giftCards;
  // * need to do these checks to appease the discriminated union literal types
  const giftCardsControl =
    giftCardValidityDays > 0 ?
      { isGiftCardEnabled: true as const, giftCardValidityDays }
    : {
        isGiftCardEnabled: false as const,
        giftCardValidityDays: 0 as const,
      };
  const variantExchanges =
    config.variantExchanges.variantExchangeValidityDays > 0 ?
      {
        ...config.variantExchanges,
        isExchangeEnabled: true as const,
      }
    : {
        ...config.variantExchanges,
        isExchangeEnabled: false as const,
        variantExchangeValidityDays: 0 as const,
      };
  const refunds =
    config.refunds.refundValidityDays > 0 ?
      {
        ...config.refunds,
        isRefundsEnabled: true as const,
      }
    : {
        ...config.refunds,
        isRefundsEnabled: false as const,
        refundValidityDays: 0 as const,
      };

  return {
    returns,
    refunds,
    giftCards: {
      ...giftCards,
      giftCardIncentiveAmount:
        giftCards.giftCardIncentiveType !== 'Percent' ?
          giftCards.giftCardIncentiveAmount
        : giftCards.giftCardIncentiveAmount * 100, // percent to whole number display
    },
    giftCardsControl,
    variantExchanges,
  };
};

const transform = ({
  returns,
  refunds: { isRefundsEnabled, ...returnsRefunds },
  giftCards: returnsGiftCards,
  giftCardsControl,
  variantExchanges: { isExchangeEnabled, ...returnsVariantExchanges },
}: ReturnSettingsFormValues): Partial<ConfigSettings> => {
  const { ...refundsData } = returnsRefunds;
  return {
    returns,
    refunds: {
      ...refundsData,
    },
    giftCards: {
      ...returnsGiftCards,
      giftCardValidityDays: giftCardsControl.giftCardValidityDays,
      giftCardIncentiveAmount:
        returnsGiftCards.giftCardIncentiveType !== 'Percent' ?
          returnsGiftCards.giftCardIncentiveAmount
        : returnsGiftCards.giftCardIncentiveAmount / 100, // whole number to percent when persisted
    },
    variantExchanges: returnsVariantExchanges,
  };
};

export const useReturnSettings = () => useConfigSettings(select);

export const useReturnSettingsUpdate = () => {
  const mutation = useConfigSettingsUpdate();

  return {
    ...mutation,
    mutate: (values: ReturnSettingsFormValues) =>
      mutation.mutate(transform(values)),
    mutateAsync: (values: ReturnSettingsFormValues) =>
      mutation.mutateAsync(transform(values)),
  };
};
