import { Transition } from '@headlessui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { CrewMerchantUi } from 'corso-types/crew/merchant/schema';
import { CrewIncentiveTypeEnum } from 'corso-types/enums/crew';
import { FormEventHandler, useEffect } from 'react';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';
import Alert from '~/components/Alert';
import ClipboardButton from '~/components/ClipboardButton';
import {
  NumberInput,
  SwitchInput,
  TextInput,
  UrlInput,
} from '~/components/field';
import MonetaryRateInput from '~/components/field/MonetaryRateInput';
import Panel from '~/components/Panel';
import { Label } from '~/components/ui/primitives/Label';
import {
  useReturnSettings,
  useReturnSettingsUpdate,
} from '~/hooks/useReturnSettings';
import { useSettingsLayoutContext } from '~/hooks/useSettingsLayoutContext';
import { returnSettingsFormSchema, ReturnSettingsFormValues } from '~/types';

function ReturnsGeneralPanel() {
  const {
    register,
    watch,
    formState: { errors },
    control,
  } = useFormContext<ReturnSettingsFormValues>();

  const isEnabled = watch('returns.isReturnsEnabled');

  return (
    <Panel
      headline="Returns"
      actions={
        <Controller
          control={control}
          name="returns.isReturnsEnabled"
          render={({ field: { onChange, value }, fieldState }) => (
            <SwitchInput
              id="returns-offered"
              label="Returns Offered"
              checked={!!value}
              onChange={(e) => {
                onChange(e);
              }}
              error={fieldState.error?.message}
            />
          )}
        />
      }
    >
      <Transition
        show={Boolean(isEnabled)}
        className="flex flex-col gap-4"
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        {/* // ? do we want an addon or require full URLs that are HTTPS; this would better with input masking */}
        <UrlInput
          id="return-policy-url"
          label="Return Policy URL"
          placeholder="https://company.com/returns-policy"
          {...register('returns.returnPolicyUrl')}
          error={errors.returns?.returnPolicyUrl?.message}
        />

        <Controller
          control={control}
          name="returns.isClaimImageFromCustomerRequired"
          defaultValue={false}
          render={({ field: { onChange, value }, fieldState }) => (
            <SwitchInput
              id="claim-image-from-customer-required"
              label="Require Media Upload"
              details="Enforce that customers provide an image or video when submitting a return request."
              checked={value}
              onChange={onChange}
              error={fieldState.error?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="returns.isReturnInspectionEnabled"
          defaultValue={false}
          render={({ field: { onChange, value }, fieldState }) => (
            <SwitchInput
              id="return-inspection-enabled"
              label="Inspections"
              details="Ability to add an inspection to a return request."
              checked={value}
              onChange={onChange}
              error={fieldState.error?.message}
            />
          )}
        />

        <NumberInput
          id="return-expiration-days"
          label="Return Expiration Days"
          details="The number of days after the date the return was created that a customer can access the label or packing slip to return the item. "
          addon={{
            insideEnd: 'days',
          }}
          required
          {...register('returns.defaultReturnExpirationDays', {
            valueAsNumber: true,
          })}
          error={errors.returns?.defaultReturnExpirationDays?.message}
        />

        <Label>Order Value Threshold For Free Shipping</Label>
        <Alert
          variant="DEFAULT"
          message="This feature has been moved to the Automations section, under Order Value Threshold for Free Shipping."
        />
      </Transition>
    </Panel>
  );
}

function ReturnsECommercePanel() {
  const {
    register,
    formState: { errors },
    getValues,
    control,
  } = useFormContext<ReturnSettingsFormValues>();

  return (
    <Panel
      // ? maybe display store platform as a subtitle in the header
      headline="E-Commerce Platform"
    >
      <Controller
        control={control}
        name="returns.isReplenishInventoryWhenReturned"
        defaultValue={false}
        render={({ field: { onChange, value }, fieldState }) => (
          <SwitchInput
            id="replenish-inventory-when-returned"
            label="Restock to Shopify"
            details="When a return is approved, inventory will be added back to the location from the corresponding Shipping Policy."
            checked={value}
            onChange={onChange}
            error={fieldState.error?.message}
          />
        )}
      />

      <TextInput
        id="product-tag-final-sale"
        label="Final Sale Product Tag"
        details="Apply this tag to any product that is ineligible for refunds, store credit, and exchanges."
        placeholder="Final Sale"
        required
        {...register('returns.productTagFinalSale')}
        error={errors.returns?.productTagFinalSale?.message}
        addon={{
          outsideEnd: (
            <ClipboardButton
              onClick={() => getValues('returns.productTagFinalSale')}
            />
          ),
        }}
      />
      <TextInput
        id="product-tag-exchange-only"
        label="Exchange Only Product Tag"
        details="Apply this tag to any product that only eligible for exchanges."
        placeholder="Exchange Only"
        required
        {...register('returns.productTagExchangeOnly')}
        error={errors.returns?.productTagExchangeOnly?.message}
        addon={{
          outsideEnd: (
            <ClipboardButton
              onClick={() => getValues('returns.productTagExchangeOnly')}
            />
          ),
        }}
      />
    </Panel>
  );
}

function RefundsPanel() {
  const {
    register,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useFormContext<ReturnSettingsFormValues>();
  const isEnabled = watch('refunds.isRefundsEnabled');

  return (
    <Panel
      headline="Refunds"
      actions={
        <Controller
          control={control}
          name="refunds.isRefundsEnabled"
          defaultValue={false}
          render={({ field: { onChange, value }, fieldState }) => (
            <SwitchInput
              id="refunds-offered"
              label="Refunds Offered"
              checked={value}
              onChange={(e) => {
                const { checked } = e.target;
                setValue('refunds.refundValidityDays', checked ? 30 : 0, {
                  shouldDirty: true,
                });
                onChange(e);
              }}
              error={fieldState.error?.message}
            />
          )}
        />
      }
    >
      <Transition
        show={Boolean(isEnabled)}
        className="flex flex-col gap-4"
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <NumberInput
          id="refund-validity-days"
          label="Refund Window"
          details="The number of days from the date an order was fulfilled that a customer can request a refund."
          addon={{
            insideEnd: 'days',
          }}
          required
          {...register('refunds.refundValidityDays', {
            valueAsNumber: true,
          })}
          error={errors.refunds?.refundValidityDays?.message}
        />

        <Label>Refund Handling Fee</Label>
        <Alert
          variant="DEFAULT"
          message="This feature has been moved to the Automations section, under Refund Handling Fee."
        />

        <Label>Deduct Shipping Cost from Refund Total</Label>
        <Alert
          variant="DEFAULT"
          message="This feature has been moved to the Automations section, under Deduct Shipping Cost from Refund Total."
        />
      </Transition>
    </Panel>
  );
}

function GiftCardsPanel() {
  const {
    register,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useFormContext<ReturnSettingsFormValues>();
  const isEnabled = watch('giftCardsControl.isGiftCardEnabled');

  const incentiveType = watch('giftCards.giftCardIncentiveType');
  const incentiveAmount = watch('giftCards.giftCardIncentiveAmount');

  return (
    <Panel
      headline="Store Credit"
      actions={
        <Controller
          control={control}
          name="giftCardsControl.isGiftCardEnabled"
          defaultValue={false}
          render={({ field: { onChange, value }, fieldState }) => (
            <SwitchInput
              id="store-credit-offered"
              label="Store Credit Offered"
              checked={value}
              onChange={(e) => {
                const { checked } = e.target;
                setValue(
                  'giftCardsControl.giftCardValidityDays',
                  checked ? 30 : 0,
                  { shouldDirty: true },
                );
                onChange(e);
              }}
              error={fieldState.error?.message}
            />
          )}
        />
      }
    >
      <Transition
        show={Boolean(isEnabled)}
        className="flex flex-col gap-4"
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <NumberInput
          id="gift-card-validity-days"
          label="Store Credit Window"
          details="The number of days from the date an order was fulfilled that a customer can request store credit."
          addon={{
            insideEnd: 'days',
          }}
          required
          {...register('giftCardsControl.giftCardValidityDays', {
            valueAsNumber: true,
          })}
          error={errors.giftCardsControl?.giftCardValidityDays?.message}
        />
        <TextInput
          id="gift-card-product-id-from-platform"
          label="Store Credit Gift Card Product ID"
          details="The product ID of the gift card product that will be added to the order when a customer requests store credit."
          {...register('giftCards.giftCardProductIdFromPlatform')}
          error={errors.giftCards?.giftCardProductIdFromPlatform?.message}
        />
        <MonetaryRateInput
          id="gift-card-incentive-amount"
          label="Store Credit Incentive Amount"
          details="The amount that will be added to the store credit amount when a customer requests store credit."
          required
          value={{
            type:
              incentiveType !== CrewIncentiveTypeEnum.none ?
                incentiveType
              : CrewIncentiveTypeEnum.fixed,
            amount: incentiveAmount,
          }}
          // TODO review efficiency
          onChange={({ type, amount }) => {
            setValue('giftCards.giftCardIncentiveType', type, {
              shouldDirty: true,
              shouldValidate: true,
              shouldTouch: true,
            });
            setValue('giftCards.giftCardIncentiveAmount', amount, {
              shouldDirty: true,
              shouldValidate: true,
              shouldTouch: true,
            });
          }}
          error={errors.giftCards?.giftCardIncentiveAmount?.message}
        />
        <TextInput
          id="gift-card-order-prefix"
          label="Store Credit Order Prefix"
          details="This prefix will be added gift card orders."
          readOnly
          disabled
          required
          value={CrewMerchantUi.CREW_STORE_CREDIT_ORDER_PREFIX}
        />
      </Transition>
    </Panel>
  );
}

function VariantExchangesPanel() {
  const {
    control,
    formState: { errors },
    register,
    setValue,
    watch,
  } = useFormContext<ReturnSettingsFormValues>();

  const isEnabled = watch('variantExchanges.isExchangeEnabled');

  return (
    <Panel
      headline="Variant Exchanges"
      actions={
        <Controller
          control={control}
          name="variantExchanges.isExchangeEnabled"
          defaultValue={false}
          render={({ field: { onChange, value }, fieldState }) => (
            <SwitchInput
              id="variant-exchanges-offered"
              label="Variant Exchanges Offered"
              checked={value}
              onChange={(e) => {
                const { checked } = e.target;
                setValue(
                  'variantExchanges.variantExchangeValidityDays',
                  checked ? 30 : 0,
                  { shouldDirty: true },
                );
                onChange(e);
              }}
              error={fieldState.error?.message}
            />
          )}
        />
      }
    >
      <Transition
        show={Boolean(isEnabled)}
        className="flex flex-col gap-4"
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <NumberInput
          id="variant-exchange-validity-days"
          label="Exchange Window"
          details="The number of days from the date an order was fulfilled that a customer can request an exchange."
          addon={{
            insideEnd: 'days',
          }}
          required
          {...register('variantExchanges.variantExchangeValidityDays', {
            valueAsNumber: true,
          })}
          error={errors.variantExchanges?.variantExchangeValidityDays?.message}
        />
        <TextInput
          id="exchange-order-discount-code"
          label="Exchange Order Discount Code"
          details="The discount code used to do zero cost variant exchanges."
          placeholder="crew-discount"
          readOnly
          disabled
          required
          value={CrewMerchantUi.CREW_DISCOUNT_CODE}
        />
        <TextInput
          id="exchange-order-prefix"
          label="Exchange Order Prefix"
          details="This prefix will be added to orders created from variant exchanges. If no prefix is provided, the order number will be generated by the platform."
          placeholder="#"
          {...register('returns.exchangeOrderPrefix')}
          error={errors.returns?.exchangeOrderPrefix?.message}
        />
        <TextInput
          id="exchange-order-shipping-title"
          label="Shipping Title"
          details="The shipping title to use for exchange orders."
          {...register('variantExchanges.variantExchangeShippingTitle')}
          error={errors.variantExchanges?.variantExchangeShippingTitle?.message}
        />
        <NumberInput
          id="min-inventory-available-to-customer"
          label="Minimum Inventory Available to Customer"
          details="The minimum inventory available for a variant to be eligible for exchange; otherwise, the variant will be considered out of stock."
          required
          {...register('variantExchanges.minInventoryAvailableToCustomer', {
            valueAsNumber: true,
          })}
          error={
            errors.variantExchanges?.minInventoryAvailableToCustomer?.message
          }
        />
        <Controller
          control={control}
          name="variantExchanges.isExchangeOfExchangeAllowed"
          defaultValue={false}
          render={({ field: { onChange, value }, fieldState }) => (
            <SwitchInput
              id="exchange-of-exchange-allowed"
              label="Allow Exchange of Exchange"
              details="When enabled, customers can create an exchange from a previously created exchange. The eligibility window will always be based on the original order."
              checked={value}
              onChange={onChange}
              error={fieldState.error?.message}
            />
          )}
        />
      </Transition>
    </Panel>
  );
}

const formId = 'returns-settings';
export default function ReturnsSettings() {
  const { data } = useReturnSettings();
  const { mutateAsync: saveConfigChanges } = useReturnSettingsUpdate();

  const setPageForm = useSettingsLayoutContext();
  const methods = useForm<ReturnSettingsFormValues>({
    resolver: zodResolver(returnSettingsFormSchema),
    values: data,
  });
  const submitHandler: FormEventHandler = (event) => {
    methods
      .handleSubmit((values) => saveConfigChanges(values))(event)
      .catch(console.error);
  };

  useEffect(() => {
    setPageForm({
      id: formId,
      reset: methods.reset,
      state: {
        isDirty: methods.formState.isDirty,
        isSubmitting: methods.formState.isSubmitting,
        hasErrors: Object.values(methods.formState.errors).length > 0,
      },
    });
  }, [
    methods.formState.errors,
    methods.formState.isDirty,
    methods.formState.isSubmitting,
    methods.reset,
    setPageForm,
  ]);

  const isEnabled = methods.watch('returns.isReturnsEnabled');

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...methods}>
      <form
        id={formId}
        className="flex flex-col gap-4"
        onSubmit={submitHandler}
      >
        <ReturnsGeneralPanel />

        <Transition
          show={Boolean(isEnabled)}
          className="flex flex-col gap-4"
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <ReturnsECommercePanel />
          <RefundsPanel />
          <GiftCardsPanel />
          <VariantExchangesPanel />
        </Transition>
      </form>
    </FormProvider>
  );
}
