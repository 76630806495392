import { ArrayCallback } from './array.js';

/**
 * Maps an array of `items` to a `Map` using the specified `iteratee` function to generate keys and values, and merges values with the same key using the `merge` function.
 */
export function mapBy<T, K, V>(
  items: T[],
  iteratee: ArrayCallback<T, [key: K, value: V]>,
  merge: (current: V, value: V) => V,
) {
  return items.reduce((map, item, index, array) => {
    const [key, value] = iteratee(item, index, array);
    const current = map.get(key);
    map.set(key, current !== undefined ? merge(current, value) : value);
    return map;
  }, new Map<K, V>());
}

// ? there is probably a better name for this
/**
 * Extracts values from an array of `items` into a `Map`, grouping them by keys generated by the `iteratee` function.
 * Values with the same `key` are collected in an array.
 * The resulting `Map` has keys mapped to arrays of values.
 */
export function extractBy<T, K, V>(
  items: T[],
  iteratee: ArrayCallback<T, [key: K, value: V]>,
) {
  return mapBy<T, K, V[]>(
    items,
    (item, index, array) => {
      const [key, value] = iteratee(item, index, array);
      return [key, [value]];
    },
    (current, value) => {
      // * spreading the value is better than spreading both into a new array, especially since this only spreads one item to push
      current.push(...value);
      return current;
    },
  );
}

/**
 * Groups `items` from an array into a `Map` based on the keys generated by the `keyed` function.
 */
// TODO revisit `map` utilities to create equivalents with `Record` types, at least for `groupBy` for support of discriminated unions
export function groupBy<T, K extends PropertyKey>(
  items: T[],
  keyed: ArrayCallback<T, K>,
) {
  return extractBy<T, K, T>(items, (item, index, array) => {
    const key = keyed(item, index, array);
    return [key, item];
  });
}

/**
 * Calculates the sum of values from an array of `items`, grouped by keys generated by the `iteratee` function.
 */
export function sumBy<T, K extends PropertyKey>(
  items: T[],
  iteratee: ArrayCallback<T, [key: K, value: number]>,
) {
  return mapBy(
    items,
    (item, index, array) => {
      const [key, value] = iteratee(item, index, array);
      return [key, value];
    },
    (current, value) => current + value,
  );
}

/**
 * Counts the occurrences of `items` in an array, grouped by keys generated by the `keyed` function.
 */
export function countBy<T, K extends PropertyKey>(
  items: T[],
  keyed: ArrayCallback<T, K>,
) {
  return sumBy(items, (item, index, array) => {
    const key = keyed(item, index, array);
    return [key, 1];
  });
}
