import {
  CrewClaimResolutionMethodEnum,
  crewClaimResolutionMethodEnumName,
  CrewClaimStatusCode,
  crewClaimStatuses,
  CrewClaimTypeEnum,
} from 'corso-types/enums/crew';
import { useState } from 'react';
import Button from '~/components/Button';
import ButtonGroup from '~/components/ButtonGroup';
import Disclosure, { SimpleSummary } from '~/components/Disclosure';
import { TextInput } from '~/components/field';
import { Badge } from '~/components/ui/primitives/Badge';
import SimpleSelect from '~/components/ui/SimpleSelect';
import {
  canChangeResolution,
  createDenial,
  createRequestedApproval,
  createUndecided,
  hasDefaultApproval,
  ReviewLineItem,
  ReviewMetaStatus,
} from '~/providers/ClaimReviewProvider';
import ExchangeOrderLineItem from './resolutionLineItems/ExchangeOrderLineItem';
import GiftCardLineItem from './resolutionLineItems/GiftCardLineItem';
import RefundLineItem from './resolutionLineItems/RefundLineItem';
import ResolutionModifier from './resolutionModifiers/ResolutionModifier';

const deniedStatuses =
  crewClaimStatuses[CrewClaimStatusCode.denied].claimStatusDetail;

type ReviewMetaStatusPartial = Exclude<
  ReviewMetaStatus,
  ReviewMetaStatus.approvingWithModification
>;

const badgeLookupText = {
  [ReviewMetaStatus.undecided]: 'Approval Method Pending',
  [ReviewMetaStatus.approvingAsRequested]: 'Approve as Requested',
  [ReviewMetaStatus.denying]: 'Deny',
} satisfies Record<ReviewMetaStatusPartial, string>;

function ResolutionBadge({
  reviewLineItem,
}: {
  reviewLineItem: ReviewLineItem;
}) {
  const { reviewMetadata } = reviewLineItem;

  const badgeText =
    reviewMetadata.status === ReviewMetaStatus.approvingWithModification ?
      `Approve as ${
        crewClaimResolutionMethodEnumName[
          reviewMetadata.approval.resolutionMethodEnum
        ]
      }`
    : badgeLookupText[reviewMetadata.status];
  return <Badge> {badgeText} </Badge>;
}

const DEFAULT_DENIAL_STATUS_CODE = deniedStatuses[2].code; // TODO remove magic number

type ClaimLineItemActionsProps = {
  reviewLineItem: ReviewLineItem;
  claimType: CrewClaimTypeEnum;
  onChange: (reviewLineItem: ReviewLineItem) => void;
};

export const resolutionButtonTextOptions = {
  [CrewClaimTypeEnum.warranty]: 'Approve',
  [CrewClaimTypeEnum.return]: 'Modify Resolution',
} satisfies Record<CrewClaimTypeEnum, string>;

export default function ClaimLineItemActions({
  reviewLineItem,
  claimType,
  onChange,
}: ClaimLineItemActionsProps) {
  const { claimLineItem, reviewMetadata } = reviewLineItem;
  const [noteToCustomer, setNoteToCustomer] = useState('');

  const resolutionButtonText = resolutionButtonTextOptions[claimType];

  return (
    <div className="rounded-md border">
      <Disclosure
        defaultOpen
        renderSummary={
          <SimpleSummary>
            <span className="ml-2 text-corso-gray-500">
              {/* // ? maybe extract to pluralize function */}
              Resolution
            </span>
            <div className="ml-auto">
              <ResolutionBadge reviewLineItem={reviewLineItem} />
            </div>
          </SimpleSummary>
        }
      >
        <div className="flex flex-col gap-2 p-3 lg:gap-4 lg:p-4">
          {/* // ? might need a different button to visually represent when a modified approval has been confirmed */}
          <ButtonGroup>
            {claimType === CrewClaimTypeEnum.return && (
              <Button
                variant={
                  (
                    reviewMetadata.status ===
                      ReviewMetaStatus.approvingAsRequested &&
                    claimLineItem.requestedResolutionMethodEnum ===
                      reviewMetadata.approval.resolutionMethodEnum
                  ) ?
                    'successLight'
                  : 'DEFAULT'
                }
                disabled={!hasDefaultApproval(claimLineItem)}
                onClick={() => {
                  if (!hasDefaultApproval(claimLineItem)) {
                    throw new Error(
                      `ClaimLineItem, ${claimLineItem.id} is not resolvable.`,
                    );
                  }
                  onChange({
                    claimLineItem,
                    noteToCustomer: reviewLineItem.noteToCustomer,
                    reviewMetadata:
                      createRequestedApproval[
                        claimLineItem.requestedResolutionMethodEnum
                      ](claimLineItem),
                  });
                }}
              >
                Approve as Requested
              </Button>
            )}

            {/* resolution can be changed to valid alternatives if any exist */}
            <Button
              disabled={!canChangeResolution(reviewLineItem)}
              variant={
                /* eslint-disable-next-line no-nested-ternary */ // TODO address and fix eventually
                reviewMetadata.status === ReviewMetaStatus.undecided ?
                  claimType === CrewClaimTypeEnum.return ?
                    'primaryLight'
                  : 'successLight'
                : 'DEFAULT'
              }
              onClick={() =>
                onChange({
                  claimLineItem,
                  noteToCustomer: reviewLineItem.noteToCustomer,
                  reviewMetadata: createUndecided(),
                })
              }
            >
              {resolutionButtonText}
            </Button>
            <Button
              variant={
                reviewMetadata.status === ReviewMetaStatus.denying ?
                  'dangerLight'
                : 'DEFAULT'
              }
              onClick={() =>
                // TODO if partial/modified revert to editing that instead of starting over
                onChange({
                  claimLineItem,
                  noteToCustomer: reviewLineItem.noteToCustomer,
                  reviewMetadata: createDenial(
                    claimLineItem,
                    DEFAULT_DENIAL_STATUS_CODE,
                  ),
                })
              }
            >
              Deny
            </Button>
          </ButtonGroup>

          {reviewMetadata.status === ReviewMetaStatus.undecided &&
            // should always be true, but need to re-evaluate to guarantee
            canChangeResolution(reviewLineItem) && (
              <ResolutionModifier
                reviewLineItem={reviewLineItem}
                onChange={onChange}
              />
            )}

          {/* // TODO clean up and ensure all modified approvals are represented appropriately */}
          {/* ===== Modified Resolution Content ===== */}
          {reviewMetadata.status ===
            ReviewMetaStatus.approvingWithModification &&
            reviewMetadata.approval.resolutionMethodEnum ===
              CrewClaimResolutionMethodEnum.refund && (
              <RefundLineItem amount={reviewMetadata.approval.amount} />
            )}
          {reviewMetadata.status ===
            ReviewMetaStatus.approvingWithModification &&
            reviewMetadata.approval.resolutionMethodEnum ===
              CrewClaimResolutionMethodEnum.giftCard && (
              <GiftCardLineItem amount={reviewMetadata.approval.amount} />
            )}
          {/* // TODO this could use a review of how it appears visually */}
          {reviewMetadata.status ===
            ReviewMetaStatus.approvingWithModification &&
            reviewMetadata.approval.resolutionMethodEnum ===
              CrewClaimResolutionMethodEnum.replacementOrder && (
              <ExchangeOrderLineItem
                lineItems={reviewMetadata.approval.replacementItems.map(
                  (replacementItem) => ({
                    key: replacementItem.idFromPlatform,
                    name: replacementItem.name,
                    imageUrl: replacementItem.imgUrl,
                    quantity: replacementItem.quantity,
                    price: null, // TODO get a value to display the price
                  }),
                )}
              />
            )}

          {/* ===== Denial Flow ===== */}
          {/* // ? enforce required reason when denying instead of a default selection */}
          {reviewMetadata.status === ReviewMetaStatus.denying && (
            <SimpleSelect
              label="Why are you denying this claim?"
              options={deniedStatuses.map((deniedStatus) => ({
                label: deniedStatus.name,
                value: deniedStatus.code,
              }))}
              value={
                deniedStatuses.find(
                  (deniedStatus) =>
                    deniedStatus.code ===
                    reviewMetadata.denial.claimStatusDetailCode,
                )?.code ?? DEFAULT_DENIAL_STATUS_CODE
              }
              onChange={(selectedDenialStatus) =>
                onChange({
                  claimLineItem,
                  noteToCustomer: reviewLineItem.noteToCustomer,
                  reviewMetadata: createDenial(
                    claimLineItem,
                    selectedDenialStatus,
                  ),
                })
              }
            />
          )}

          {/* ===== Note for Either Flow ===== */}
          <TextInput
            id={`line-item-comment-${claimLineItem.id}`}
            label="Note to Customer"
            value={noteToCustomer}
            onChange={(e) => {
              setNoteToCustomer(e.currentTarget.value);
              onChange({
                claimLineItem,
                noteToCustomer: e.currentTarget.value,
                reviewMetadata,
              });
            }}
          />
        </div>
      </Disclosure>
    </div>
  );
}
