import { Transition } from '@headlessui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormEventHandler, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { NumberInput, SwitchInput, UrlInput } from '~/components/field';
import Panel from '~/components/Panel';
import {
  useConfigSettings,
  useConfigSettingsUpdate,
} from '~/hooks/useConfigSettings';
import { useSettingsLayoutContext } from '~/hooks/useSettingsLayoutContext';
import {
  warrantySettingsFormSchema,
  WarrantySettingsFormValues,
} from '~/types';

const formId = 'warranty-settings';

export default function WarrantiesSettings() {
  // const { data } = useWarrantySettings();
  const { data } = useConfigSettings(({ warranties }) => warranties);
  const { mutateAsync: saveChanges } = useConfigSettingsUpdate();
  // const { mutateAsync: saveChanges } = useWarrantySettingsUpdate();
  const setPageForm = useSettingsLayoutContext();
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
    register,
    reset,

    watch,
  } = useForm<WarrantySettingsFormValues>({
    resolver: zodResolver(warrantySettingsFormSchema),
    values: data,
  });

  const submitHandler: FormEventHandler = (event) => {
    handleSubmit((values) => saveChanges({ warranties: values }))(event).catch(
      console.error,
    );
  };
  useEffect(() => {
    setPageForm({
      id: formId,
      reset,
      state: {
        isDirty,
        isSubmitting,
        hasErrors: Object.values(errors).length > 0,
      },
    });
  }, [errors, isDirty, isSubmitting, reset, setPageForm]);

  const isEnabled = watch('isWarrantiesEnabled');

  return (
    <form id={formId} onSubmit={submitHandler}>
      <Panel
        headline="Warranties"
        actions={
          <Controller
            control={control}
            name="isWarrantiesEnabled"
            render={({ field: { onChange, value }, fieldState }) => (
              <SwitchInput
                id="warranties-offered"
                label="Warranties Offered"
                checked={!!value}
                onChange={(e) => {
                  onChange(e);
                }}
                error={fieldState.error?.message}
              />
            )}
          />
        }
      >
        <Transition
          show={Boolean(isEnabled)}
          className="flex flex-col gap-4"
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          {/* // ? do we want an addon or require full URLs that are HTTPS; this would better with input masking */}
          <UrlInput
            id="warranty-policy-url"
            label="Policy URL"
            placeholder="https://company.com/warranties-policy"
            required
            {...register('warrantyPolicyUrl')}
            error={errors.warrantyPolicyUrl?.message}
          />
          <NumberInput
            id="warranty-validity-days"
            label="Warranty Window"
            placeholder="30"
            details="The number of days from the date an order was fulfilled that a customer can request a warranty review."
            required
            {...register('defaultWarrantyDays', {
              valueAsNumber: true,
            })}
            error={errors.isWarrantyInspectionEnabled?.message}
            addon={{
              insideEnd: 'days',
            }}
          />
          <SwitchInput // this is just for visual reference to the user
            defaultChecked
            disabled
            id="customer-image-required"
            label="Require Media Upload"
            details="Enforce that customers provide an image or video when submitting a warranty request."
          />

          <Controller
            control={control}
            name="isWarrantyInspectionEnabled"
            defaultValue={false}
            render={({ field: { onChange, value }, fieldState }) => (
              <SwitchInput
                id="warranty-inspection-enabled"
                label="Inspections"
                details="Ability to add an inspection to a warranty request."
                checked={!!value}
                onChange={onChange}
                error={fieldState.error?.message}
              />
            )}
          />
        </Transition>
      </Panel>
    </form>
  );
}
