import { Popover } from '@headlessui/react';
import { CalendarIcon } from '@heroicons/react/24/outline';
import { useMemo, useState } from 'react';
import { DateRange, DayPicker } from 'react-day-picker';

// TODO add presets; i.e. last 7 days, last 30 days, last 90 days, etc.

type DateRangeProps = {
  defaultRange: DateRange | undefined;
  onSelect: (range: DateRange | undefined) => void;
};

// inspired by https://ui.shadcn.com/docs/components/date-picker#date-range-picker
export default function DateRangePicker({
  defaultRange,
  onSelect,
}: DateRangeProps) {
  const [dateRange, setDateRange] = useState<DateRange | undefined>(
    defaultRange,
  );

  const label = useMemo(() => {
    const { from, to } = dateRange ?? {};
    if (from && to) {
      return (
        <span>
          {from.toLocaleDateString()} - {to.toLocaleDateString()}
        </span>
      );
    }
    if (from) return <span>{from.toLocaleDateString()}</span>;
    return <span className="text-corso-gray-500">Select Date Range</span>;
  }, [dateRange]);

  return (
    <Popover className="relative">
      <Popover.Button
        // TODO hover, active, error, and disabled states
        className="flex w-full items-center gap-2 rounded-md bg-white px-3 py-1.5 text-corso-gray-800 shadow-sm ring-1 ring-inset ring-corso-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-corso-blue-600 sm:text-sm"
      >
        <CalendarIcon className="h-5 w-5" />
        {label}
      </Popover.Button>
      <Popover.Panel>
        {/* styling from https://ui.shadcn.com/docs/components/calendar // TODO consider radix ui instead of headless ui */}
        <DayPicker
          initialFocus
          defaultMonth={defaultRange?.from}
          mode="range"
          className="absolute left-0 top-12 z-10 rounded-md bg-white p-4 text-sm shadow-lg"
          classNames={{
            months: 'flex gap-4 flex-col sm:flex-row',
            month: 'flex flex-col gap-4',
            caption: 'flex justify-center relative items-center',
            caption_label: 'font-medium',
            nav: 'flex items-center',
            nav_button:
              'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100',
            nav_button_previous: 'absolute left-1',
            nav_button_next: 'absolute right-1',
            table: 'w-full border-collapse',
            head_row: 'flex',
            head_cell: 'w-9 font-normal text-sm',
            row: 'flex w-full',
            cell: 'text-center p-0',
            day: 'w-9 h-9 p-0 font-normal hover:bg-corso-blue-400 hover:text-white', // ? maybe rounded on hover
            day_today: 'border border-corso-blue-800',
            day_range_start: 'bg-corso-blue-600 text-white rounded-l-md',
            day_range_middle: 'bg-corso-blue-500 text-white',
            day_range_end: 'bg-corso-blue-600 text-white rounded-r-md',
            day_disabled: 'opacity-50',
            day_outside: 'opacity-50',
            day_hidden: 'invisible',
          }}
          numberOfMonths={2}
          showOutsideDays
          selected={dateRange}
          onSelect={(selected) => {
            setDateRange(selected);
            onSelect(selected);
          }}
        />
      </Popover.Panel>
    </Popover>
  );
}
