import {
  DetailedHTMLProps,
  forwardRef,
  ReactNode,
  Ref,
  TextareaHTMLAttributes,
} from 'react';
import { twMerge } from 'tailwind-merge';
import { Label } from '~/components/ui/primitives/Label';
import SupportingText from './SupportingText';

export type TextareaProps = Omit<
  DetailedHTMLProps<
    TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  >,
  'className' | 'ref'
> & {
  /**
   * Required `id` to be associated with the `input` element.
   * Also used to derive further `id` attributes for the wrappers of the `description` and `error` elements.
   * These derived `id` attributes are then and used for the `aria-describedby` attribute on the primary `input` element.
   */
  id: string;
  label: string;
  labelVisuallyHidden?: boolean;
  /**
   * Descriptive information to further clarify the intended data.
   * Although this accepts a `ReactNode`, it's recommended to mostly use `string` values or a fragment with multiple paragraphs for simplicity.
   */
  details?: ReactNode;
  /**
   * Descriptive information about the `error` state of the `input`.
   * Although this accepts a `ReactNode`, it's recommended to mostly use `string` values or a fragment with multiple paragraphs for simplicity.
   */
  error?: ReactNode;
};

// ? might need support to customize the colors of the different states and the associated wrappers
// ? might need `forwardRef` support
const TextAreaInput = forwardRef(
  (
    {
      id,
      label,
      labelVisuallyHidden = false,
      details,
      error,
      required,
      disabled,
      hidden,
      rows = 3,
      ...rest
    }: TextareaProps,
    ref: Ref<HTMLTextAreaElement>,
  ) => {
    const detailsId = `${id}-details`;
    const errorId = `${id}-error`;

    return (
      <div
        // `hidden` class necessary, because the base attribute is insufficient with applied `display` classes
        className={twMerge('flex flex-col gap-2', hidden && 'hidden')}
      >
        <div
          className={twMerge(
            'flex justify-between',
            labelVisuallyHidden && 'sr-only', // applied to label group wrapper
          )}
        >
          <Label htmlFor={id}>{label}</Label>
          {!required && (
            <span
              className="text-xs leading-6 text-corso-gray-500"
              id="email-optional"
            >
              Optional
            </span>
          )}
        </div>
        <div
          className={twMerge(
            // base styles for default/neutral state
            'flex w-full gap-1 rounded-md px-3 py-1.5 text-corso-gray-800 shadow-sm ring-1 ring-inset ring-corso-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-corso-blue-600 sm:text-sm sm:leading-6',
            // disabled styles; the `disabled:` pseudo-class won't work on this wrapper, as it's needed on the `input` itself
            disabled &&
              'cursor-not-allowed bg-corso-gray-50 text-corso-gray-500 ring-corso-gray-200',
            // error state style overrides
            !!error &&
              'text-corso-red-900 ring-corso-red-300 focus-within:ring-corso-red-600',
          )}
        >
          <textarea
            id={id}
            className={twMerge(
              'flex-1 text-ellipsis border-0 bg-transparent p-0 text-sm focus:ring-0',
              'placeholder:text-corso-gray-400',
              !!error && 'placeholder:text-corso-red-300',
              // file selector button styles
            )}
            // combine `id` into a space-delimited string
            aria-invalid={!!error}
            aria-describedby={[details && detailsId, error && errorId]
              .filter(Boolean)
              .join(' ')}
            required={required}
            disabled={disabled}
            ref={ref}
            rows={rows}
            {...rest}
          />
        </div>
        {error && (
          <SupportingText error id={errorId}>
            {error}
          </SupportingText>
        )}
        {details && <SupportingText id={detailsId}>{details}</SupportingText>}
      </div>
    );
  },
);

export default TextAreaInput;
