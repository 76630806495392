import LineItem from '~/components/LineItem';
import LineItemList from '~/components/LineItemList';
import ResolutionLineItemTitle from './ReslutionLineItemTitle';
import { ReviewResolutionLineItemProps } from './types';

// basically the same as an `ExchangeOrderLineItem` but with a different name
export default function WarrantyReviewLineItem({
  lineItems,
}: ReviewResolutionLineItemProps) {
  return (
    <ResolutionLineItemTitle title="Warranty Review">
      <LineItem variant="no-image">
        <LineItemList lineItems={lineItems} />
      </LineItem>
    </ResolutionLineItemTitle>
  );
}
