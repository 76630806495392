import { InformationCircleIcon } from '@heroicons/react/24/outline';
import {
  CrewClaimResolutionMethodEnum,
  crewClaimTypeEnumName,
} from 'corso-types/enums/crew';
import { isTruthy } from 'corso-types/typing';
import { formatDistanceToNow } from 'date-fns';
import { ReactNode } from 'react';
import Alert from '~/components/Alert';
import ContentWrapper from '~/components/ContentWrapper';
import { LineItemListLineItem } from '~/components/LineItemList';
import { Badge } from '~/components/ui/primitives/Badge';
import {
  ClaimReview,
  ResolvableResolutionMethod,
  ResolvableReviewItem,
  ReviewLineItem,
  ReviewMetaStatus,
  SupportedResolutionMethod,
  claimLineItemTotalPrice,
  isDeniedAs,
  isMetaStatus,
  isRequestedAs,
  isResolvedAs,
  reviewableResolutionMethods,
  useClaimReviewContext,
} from '~/providers/ClaimReviewProvider';
import { formatter } from '~/utils/formatter';
import ExchangeOrderLineItem from './resolutionLineItems/ExchangeOrderLineItem';
import GiftCardLineItem from './resolutionLineItems/GiftCardLineItem';
import RefundLineItem from './resolutionLineItems/RefundLineItem';
import WarrantyReviewLineItem from './resolutionLineItems/WarrantyReviewLineItem';

function sumBy<T>(items: T[], accessor: (item: T) => number, initialValue = 0) {
  return items.reduce((total, item) => total + accessor(item), initialValue);
}

function monetarySummary<T extends ResolvableResolutionMethod>(
  reviewLineItems: ResolvableReviewItem<T>[],
) {
  return {
    requestedValue: sumBy(reviewLineItems, ({ claimLineItem }) =>
      claimLineItemTotalPrice(claimLineItem),
    ),
    approvedValue: sumBy(reviewLineItems, ({ reviewMetadata }) => {
      if ('amount' in reviewMetadata.approval) {
        return reviewMetadata.approval.amount;
      }
      // TODO handle value of non-monetary resolution methods
      return 0;
    }),
  };
}

type SummaryCalculation = {
  key: PropertyKey;
  value: number | null; // TODO address nullability
  summary: ReactNode;
};

// ! NOTE, THIS IS USED TO SHOW THE VARIANT EXCHANGE LINE ITEM
// ! IT CAN'T JUST SHOW THE STORE ORDER LINE ITEM DETAILS, BECAUSE THAT'S WRONG
const reviewLineToLineItem = ({
  claimLineItem: {
    originalStoreOrderLineItem,
    quantity,
    variantExchangeLineItem,
  },
}: ReviewLineItem) =>
  ({
    key: originalStoreOrderLineItem.idFromPlatform,
    name:
      variantExchangeLineItem ?
        variantExchangeLineItem.name
      : originalStoreOrderLineItem.name,
    imageUrl:
      variantExchangeLineItem ?
        variantExchangeLineItem.imgUrl
      : originalStoreOrderLineItem.imgUrl,
    options: variantExchangeLineItem?.optionsFromPlatform,
    sku:
      variantExchangeLineItem ?
        variantExchangeLineItem.sku
      : originalStoreOrderLineItem.sku,

    quantity:
      variantExchangeLineItem ? variantExchangeLineItem.quantity : quantity,
    // would need determine how to best extract and display the value and unit price if desired
  }) satisfies LineItemListLineItem;

const monetaryCalculationLookup = {
  [CrewClaimResolutionMethodEnum.variantExchange]: ({
    approvals,
    denials,
  }: {
    approvals: ResolvableReviewItem<CrewClaimResolutionMethodEnum.variantExchange>[];
    denials: ReviewLineItem[];
  }) => {
    if (!approvals.length && !denials.length) return null; // skip if there are no calculations to display
    const { approvedValue: approvedAmount } = monetarySummary(approvals);

    return {
      key: CrewClaimResolutionMethodEnum.variantExchange,
      value: approvedAmount,
      // ? maybe show each item in the exchange instead of the total
      summary: (
        <ExchangeOrderLineItem
          lineItems={[
            ...approvals.map(reviewLineToLineItem).map((lineItem) => ({
              ...lineItem,
              // * intentionally not showing a badge
            })),
            ...denials.map(reviewLineToLineItem).map((lineItem) => ({
              ...lineItem,
              subtitle: (
                <div>
                  <Badge variant="danger">Denied</Badge>
                </div>
              ),
            })),
          ]}
        />
      ),
    };
  },
  [CrewClaimResolutionMethodEnum.refund]: ({
    claim,
    approvals,
    denials,
  }: {
    claim: ClaimReview['claim'];
    approvals: ResolvableReviewItem<CrewClaimResolutionMethodEnum.refund>[];
    denials: ReviewLineItem[];
  }) => {
    if (!approvals.length && !denials.length) return null; // skip if there are no calculations to display
    const { requestedValue: requestedAmount, approvedValue: approvedAmount } =
      monetarySummary(approvals);

    const refundHandlingFeeApplied =
      approvedAmount > 0 && requestedAmount > 0 ? claim.feeApplied : 0;

    const refundReturnShippingAmountApplied =
      approvedAmount > 0 && requestedAmount > 0 ?
        claim.returnShippingAmountApplied
      : 0;

    // if all denied (approved value is zero), cannot be negative due to fees, so clamp to 0; otherwise, show refund from approved amount
    const refundAmount =
      approvedAmount <= 0 ? 0 : (
        approvedAmount -
        refundHandlingFeeApplied -
        refundReturnShippingAmountApplied
      );

    const compareAmount =
      requestedAmount -
      refundHandlingFeeApplied -
      refundReturnShippingAmountApplied;

    return {
      key: CrewClaimResolutionMethodEnum.refund,
      value: refundAmount,
      summary: (
        <RefundLineItem
          amount={refundAmount}
          compareAmount={compareAmount}
          handlingFee={refundHandlingFeeApplied}
          shippingFee={refundReturnShippingAmountApplied}
        />
      ),
    };
  },
  [CrewClaimResolutionMethodEnum.giftCard]: ({
    claim,
    approvals,
    denials,
  }: {
    claim: ClaimReview['claim'];
    approvals: ResolvableReviewItem<CrewClaimResolutionMethodEnum.giftCard>[];
    denials: ReviewLineItem[];
  }) => {
    if (!approvals.length && !denials.length) return null; // skip if there are no calculations to display
    const { requestedValue: requestedAmount, approvedValue: approvedAmount } =
      monetarySummary(approvals);

    // don't apply the incentive if the requested amount is zero and approved amount is zeroGet a gift card for

    const incentiveAmount =
      requestedAmount > 0 && approvedAmount > 0 ?
        claim.giftCardIncentiveAmountApplied
      : 0;

    // if all denied (approved value is zero), zero out incentives; otherwise, show gift card from approved amount
    const giftCardAmount =
      approvedAmount <= 0 ? 0 : approvedAmount + incentiveAmount;

    const compareAmount = requestedAmount + incentiveAmount;
    return {
      key: CrewClaimResolutionMethodEnum.giftCard,
      value: giftCardAmount,
      summary: (
        <GiftCardLineItem
          amount={giftCardAmount}
          compareAmount={compareAmount}
          merchandiseAmount={requestedAmount}
          incentiveAmount={
            giftCardAmount && claim.giftCardIncentiveAmountApplied
          }
        />
      ),
    };
  },
  [CrewClaimResolutionMethodEnum.warrantyReview]: ({
    undecided,
    denials,
  }: {
    undecided: ReviewLineItem[];
    denials: ReviewLineItem[];
  }) => {
    if (!undecided.length && !denials.length) return null; // skip if there are no calculations to display

    return {
      key: CrewClaimResolutionMethodEnum.warrantyReview,
      // TODO: get a value to display prices of replacement line items
      value: null, // ! cannot determine a monetary value, as the `replacementItems` do not have a price associated with them
      summary: (
        <WarrantyReviewLineItem
          lineItems={[
            ...undecided.map(reviewLineToLineItem).map((lineItem) => ({
              ...lineItem,
              subtitle: (
                <span className="flex gap-1 text-xs text-corso-gray-500">
                  <span className="font-medium">Resolution:</span>Approval
                  Method Pending
                </span>
              ),
            })),
            ...denials.map(reviewLineToLineItem).map((lineItem) => ({
              ...lineItem,
              subtitle: (
                <span className="flex gap-1 text-xs text-corso-gray-500">
                  <span className="font-medium">Resolution:</span>Deny
                </span>
              ),
            })),
          ]}
        />
      ),
    };
  },
  [CrewClaimResolutionMethodEnum.replacementOrder]: ({
    approvals,
  }: {
    approvals: ResolvableReviewItem<CrewClaimResolutionMethodEnum.replacementOrder>[];
  }) => {
    if (!approvals.length) return null; // skip if there are no calculations to display

    const approved = approvals.filter(
      isResolvedAs(CrewClaimResolutionMethodEnum.replacementOrder),
    );
    const replacementItems = approved.flatMap(
      ({ reviewMetadata }) => reviewMetadata.approval.replacementItems,
    );

    return {
      key: CrewClaimResolutionMethodEnum.replacementOrder,
      // TODO get a value to display prices of replacement line items
      value: null, // ! cannot determine a monetary value, as the `replacementItems` do not have a price associated with them
      summary: (
        <ExchangeOrderLineItem
          lineItems={replacementItems.map((replacementItem) => ({
            key: replacementItem.idFromPlatform,
            name: replacementItem.name,
            imageUrl: replacementItem.imgUrl,
            quantity: replacementItem.quantity,
            sku: replacementItem.sku,
            options: replacementItem.optionsFromPlatform,
          }))}
        />
      ),
    };
  },
};

// ! TODO correct calculations for partial approvals and modifications
function getCalculations(claim: ClaimReview['claim']) {
  const allReviewLineItems = reviewableResolutionMethods.flatMap(
    (resolutionMethod) => claim.reviewLineItems[resolutionMethod],
  );

  // this repetition sucks, but it ensures it's exhaustive
  return Object.values({
    [CrewClaimResolutionMethodEnum.refund]: monetaryCalculationLookup[
      CrewClaimResolutionMethodEnum.refund
    ]({
      claim,
      approvals: allReviewLineItems.filter(
        isResolvedAs(CrewClaimResolutionMethodEnum.refund),
      ),
      denials: allReviewLineItems.filter(
        isDeniedAs(CrewClaimResolutionMethodEnum.refund),
      ),
    }),
    [CrewClaimResolutionMethodEnum.giftCard]: monetaryCalculationLookup[
      CrewClaimResolutionMethodEnum.giftCard
    ]({
      claim,
      approvals: allReviewLineItems.filter(
        isResolvedAs(CrewClaimResolutionMethodEnum.giftCard),
      ),
      denials: allReviewLineItems.filter(
        isDeniedAs(CrewClaimResolutionMethodEnum.giftCard),
      ),
    }),
    [CrewClaimResolutionMethodEnum.variantExchange]: monetaryCalculationLookup[
      CrewClaimResolutionMethodEnum.variantExchange
    ]({
      approvals: allReviewLineItems.filter(
        isResolvedAs(CrewClaimResolutionMethodEnum.variantExchange),
      ),
      denials: allReviewLineItems.filter(
        isDeniedAs(CrewClaimResolutionMethodEnum.variantExchange),
      ),
    }),
    [CrewClaimResolutionMethodEnum.warrantyReview]: monetaryCalculationLookup[
      CrewClaimResolutionMethodEnum.warrantyReview
    ]({
      undecided: allReviewLineItems
        .filter(isMetaStatus(ReviewMetaStatus.undecided))
        .filter(isRequestedAs(CrewClaimResolutionMethodEnum.warrantyReview)),
      denials: allReviewLineItems.filter(
        isDeniedAs(CrewClaimResolutionMethodEnum.warrantyReview),
      ),
    }),
    [CrewClaimResolutionMethodEnum.replacementOrder]: monetaryCalculationLookup[
      CrewClaimResolutionMethodEnum.replacementOrder
    ]({
      approvals: allReviewLineItems.filter(
        isResolvedAs(CrewClaimResolutionMethodEnum.replacementOrder),
      ),
    }),
  } satisfies Record<
    SupportedResolutionMethod,
    SummaryCalculation | null
  >).filter(isTruthy);
}

function CustomerPaymentDetailNote({
  payment,
}: {
  payment: NonNullable<ClaimReview['claim']['customerPayment']>;
}) {
  const { status, authorizationExpiresOn } = payment;

  if (status === 'Authorized' && authorizationExpiresOn) {
    return `Authorization expires in ${formatDistanceToNow(
      new Date(authorizationExpiresOn),
    )}`;
  }

  if (status === 'Refunded') {
    return 'Refunded';
  }

  if (status === 'Expired' && payment.amount === 0) {
    return 'Items Returned';
  }

  return null;
}

function CustomerPaymentDetail({
  payment,
}: {
  payment: NonNullable<ClaimReview['claim']['customerPayment']>;
}) {
  const { amount, currencyCode, note, status } = payment;

  return (
    <div className="flex items-center gap-4 py-2 text-sm text-corso-gray-500">
      <span className="grow">{note ?? 'Additional Funds'} (via Stripe)</span>

      {CustomerPaymentDetailNote({ payment })}

      <span className={status === 'Refunded' ? 'line-through' : ''}>
        {formatter.currency(amount, currencyCode)}
      </span>
    </div>
  );
}

function RequestSummary({ claim }: { claim: ClaimReview['claim'] }) {
  const calculations = getCalculations(claim);

  return (
    <ContentWrapper>
      <p className="font-medium">
        {crewClaimTypeEnumName[claim.claimType]} Summary
      </p>
      <div className="divide-y rounded-md border p-4 shadow-sm [&>*]:py-4 first:[&>*]:pt-0 last:[&>*]:pb-0">
        {calculations.map(({ key, summary }) => (
          <div key={key}>{summary}</div>
        ))}
        {claim.customerPayment && (
          <CustomerPaymentDetail payment={claim.customerPayment} />
        )}
      </div>
    </ContentWrapper>
  );
}

function ResolutionSummary({
  claim,
}: {
  // ? does summary already have incentives and fees applied to amounts
  claim: ClaimReview['claim'];
}) {
  const {
    resolutionSummary,
    originalStoreOrder: { platformAdminUrl },
  } = claim;

  return resolutionSummary ?
      <ContentWrapper>
        <p className="font-medium">Resolution Summary</p>

        {resolutionSummary.exchangeOrder && (
          <div className="flex flex-col gap-4 divide-y rounded-md border p-4 shadow-sm">
            <ExchangeOrderLineItem
              lineItems={resolutionSummary.exchangeOrder.lineItems.map(
                (lineItem) => ({
                  key: lineItem.id,
                  name: lineItem.name,
                  imageUrl: lineItem.imgUrl,
                  price: lineItem.unitPrice,
                  quantity: lineItem.quantity,
                  sku: lineItem.sku,
                  options: lineItem.optionsFromPlatform,
                }),
              )}
              replacementOrder={{
                adminLink: resolutionSummary.exchangeOrder.platformAdminUrl,
                nameFromPlatform:
                  resolutionSummary.exchangeOrder.orderNameFromPlatform,
              }}
            />
            {claim.customerPayment && (
              <CustomerPaymentDetail payment={claim.customerPayment} />
            )}
          </div>
        )}

        {resolutionSummary.refund && (
          <div className="flex flex-col gap-4 rounded-md border p-4 shadow-sm">
            <RefundLineItem
              amount={resolutionSummary.refund.amount}
              handlingFee={resolutionSummary.refund.handlingFee}
              shippingFee={resolutionSummary.refund.shippingFee}
            />

            {resolutionSummary.refund.isRefundPending && platformAdminUrl && (
              <Alert
                message={
                  <p>
                    Refund is pending on Shopify see details{' '}
                    <a
                      className="text-corso-blue-600 hover:text-corso-blue-500 hover:underline"
                      href={platformAdminUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      here
                    </a>
                  </p>
                }
              />
            )}
          </div>
        )}
        {resolutionSummary.giftCard && (
          <div className="flex flex-col gap-4 rounded-md border p-4 shadow-sm">
            <GiftCardLineItem
              amount={resolutionSummary.giftCard.amount}
              incentiveAmount={resolutionSummary.giftCard.incentiveAmount}
              replacementOrder={{
                adminLink: resolutionSummary.giftCard.platformAdminUrl,
                nameFromPlatform:
                  resolutionSummary.giftCard.orderNameFromPlatform,
              }}
            />
          </div>
        )}
      </ContentWrapper>
    : <ContentWrapper>
        <p className="text-md font-medium">Resolution Summary</p>
        <div className="flex items-center gap-x-1 rounded-md border p-4 shadow-sm">
          <InformationCircleIcon className="h-6 w-6 text-corso-gray-500" />
          <p className="ml-2 text-sm text-corso-gray-800">
            Nothing was issued for this request
          </p>
        </div>
      </ContentWrapper>;
}

export default function ClaimSummary() {
  const { claimReview } = useClaimReviewContext();
  const claim = claimReview.watch('claim');

  // resolution summary is only available if the claim is completed
  const showResolutionSummary = claim.claimRollup.code === 'Completed';

  // ? possibly unify the design of the resolution and request summaries
  return showResolutionSummary ?
      <ResolutionSummary claim={claim} />
    : <RequestSummary claim={claim} />;
}
