import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ShopifyAppSubscriptionStatusEnum } from 'corso-types/enums/gsp';
import api from '~/api';
import { IntegrationSettingsFormValues, UIStatusVariant } from '~/types';
import { isErrorMessage } from '~/utils/error';
import { useStoreId } from './useStoreId';
import { useToast } from './useToast';

const appSubscriptionStatusContent: Record<
  ShopifyAppSubscriptionStatusEnum,
  {
    badgeVariant: Exclude<UIStatusVariant, 'DEFAULT'>;
    needsAction: boolean;
    supportingText: string;
  }
> = {
  [ShopifyAppSubscriptionStatusEnum.active]: {
    badgeVariant: 'success',
    needsAction: false,
    supportingText: 'To manage the Corso app, please visit the Shopify admin.',
  },
  [ShopifyAppSubscriptionStatusEnum.pending]: {
    badgeVariant: 'warning',
    needsAction: true,
    supportingText:
      'Before Corso will be made available to your customers, you must approve the app subscription.',
  },
  [ShopifyAppSubscriptionStatusEnum.expired]: {
    badgeVariant: 'danger',
    needsAction: true,
    supportingText:
      'Your app subscription token has expired, so before Corso will be made available to your customers, you must generate and approve a new subscription.',
  },
  [ShopifyAppSubscriptionStatusEnum.uninstalled]: {
    badgeVariant: 'danger',
    needsAction: false,
    supportingText:
      'Corso has been uninstalled from your store. Reinstall Corso to continue using the app.',
  },
  [ShopifyAppSubscriptionStatusEnum.declined]: {
    badgeVariant: 'danger',
    needsAction: true,
    supportingText:
      'Corso app subscription has been declined, so before Corso will be made available to your customers, you must generate and approve a new subscription.',
  },
  [ShopifyAppSubscriptionStatusEnum.cancelled]: {
    badgeVariant: 'danger',
    needsAction: true,
    supportingText:
      'Corso app subscription has been cancelled, so before Corso will be made available to your customers, you must generate and approve a new subscription.',
  },
  [ShopifyAppSubscriptionStatusEnum.frozen]: {
    badgeVariant: 'info',
    needsAction: false,
    supportingText:
      'Corso app subscription has been frozen for non-payment. It will re-activate after payments resume.',
  },
  [ShopifyAppSubscriptionStatusEnum.accepted]: {
    badgeVariant: 'info',
    needsAction: false,
    supportingText:
      'Corso app subscription has been accepted, Corso will soon be made available to your customers, once this becomes active.',
  },
};

const useQueryKey = () => {
  const storeId = useStoreId();
  return ['integrations', storeId] as const;
};

export const useIntegrationSettingsData = () => {
  const queryKey = useQueryKey();

  return useQuery({
    queryKey,
    queryFn: ({ queryKey: [, storeId] }) =>
      api.store(storeId).settings.integrations.get(),
    select: (data) => ({
      ...data,
      appSubscriptionStatusContent:
        appSubscriptionStatusContent[
          data.appSubscription.appSubscriptionStatus
        ],
    }),
  });
};

export const useIntegrationSettingsUpdate = () => {
  const storeId = useStoreId();
  const queryKey = useQueryKey();
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation({
    mutationFn: (values: IntegrationSettingsFormValues) =>
      api.store(storeId).settings.integrations.update(values),
    //* invalidate the query to refetch the data for other consumers off the integration settings data such as the settings actions panel
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
    onError: (error) => {
      const message =
        (
          isErrorMessage(error) && !(error instanceof SyntaxError) // TODO improve error handling/validating for an API error with a user-friendly message
        ) ?
          error.message
        : 'Something went wrong. Please try again.';

      toast.show(message);
      return error;
    },
  });
};

export const useStripeDisconnect = () => {
  const queryKey = useQueryKey();
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation({
    mutationFn: (disconnectUrl: string) => fetch(disconnectUrl),
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
    onError: (error) => {
      toast.show('Failed to Disconnect Stripe. Please try again.');
      return error;
    },
  });
};

export const useKlaviyoDisconnect = () => {
  const queryKey = useQueryKey();
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation({
    mutationFn: (disconnectUrl: string) => fetch(disconnectUrl),
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
    onError: (error) => {
      toast.show('Failed to Disconnect Klaviyo. Please try again.');
      return error;
    },
  });
};
