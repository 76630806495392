import { EyeIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useMutation } from '@tanstack/react-query';
import { CrewMerchantUi } from 'corso-types/crew/merchant/schema';
import { FormEventHandler, useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useBeforeUnload } from 'react-router-dom';
import { z } from 'zod';
import api from '~/api';
import Button from '~/components/Button';
import DescriptionList from '~/components/DescriptionList';
import Disclosure, { SimpleSummary } from '~/components/Disclosure';
import { SupportingText, TextAreaInput, TextInput } from '~/components/field';
import MediaUploader, { MediaAsset } from '~/components/field/MediaUploader';
import IconAction from '~/components/IconAction';
import MediaGallery from '~/components/MediaGallery';
import Modal from '~/components/Modal';
import RelativeDateTime from '~/components/RelativeDateTime';
import { Badge } from '~/components/ui/primitives/Badge';
import SimpleSelect from '~/components/ui/SimpleSelect';
import { useClaimLineItemInspectionSettings } from '~/hooks/useClaimLineItemInspection';
import { usePathParams } from '~/hooks/usePathParams';
import { useStoreId } from '~/hooks/useStoreId';
import { useInvalidateClaimReview } from '~/providers/ClaimReviewProvider';
import { useMerchantContext } from '~/providers/MerchantProvider';
import { formatter } from '~/utils/formatter';

// we might want to move this to be a shared hook, or maybe just have it available more easily via the review context
function useClaimId() {
  return usePathParams(z.object({ claimId: z.coerce.number() })).claimId;
}

function InspectionDisplay({
  lineItemId,
  inspection,
}: {
  lineItemId: number;
  inspection: CrewMerchantUi.ClaimLineItemInspection;
}) {
  const [showFullInspection, setShowFullInspection] = useState(false);
  const storeId = useStoreId();
  const claimId = useClaimId();
  const { userFullName } = useMerchantContext();
  const invalidateClaim = useInvalidateClaimReview();

  const { mutate: deleteInspection, isPending } = useMutation({
    mutationFn: () =>
      api
        .store(storeId)
        .claim(`${claimId}`, userFullName)
        .lineItem(`${lineItemId}`)
        .inspection.delete(`${inspection.id}`),
    onSuccess: () => invalidateClaim(),
  });

  return (
    <div className="grid grid-cols-2 gap-2">
      <div>
        <p>{inspection.name}</p>
        <p className="text-xs text-corso-gray-500">
          Created <RelativeDateTime dateTime={inspection.createdOn} />
        </p>
      </div>
      <div className="flex items-center gap-2 justify-self-end">
        <IconAction.Button
          icon={EyeIcon}
          title={`View ${inspection.name}`}
          onClick={() => setShowFullInspection(true)}
        />
        <Modal
          show={showFullInspection}
          onClose={() => setShowFullInspection(false)}
          title={inspection.name}
        >
          <DescriptionList
            descriptions={[
              {
                term: 'Created',
                details: <RelativeDateTime dateTime={inspection.createdOn} />,
              },
              {
                term: 'Grade',
                details: inspection.storeInspectionGrade.name,
              },
              { term: 'Comment', details: inspection.comment },
              {
                term: 'Media',
                details: !!inspection.images.length && (
                  <MediaGallery
                    media={inspection.images.map(({ url }) => ({
                      src: url,
                      alt: 'Inspection Media',
                    }))}
                  />
                ),
              },
            ].filter(({ details }) => !!details)} // omit terms with no details
          />
        </Modal>
        <IconAction.Button
          icon={TrashIcon}
          loading={isPending}
          onClick={deleteInspection}
          title={`Delete ${inspection.name ?? 'Inspection'}`}
        />
      </div>
    </div>
  );
}

type InspectionCreateFormValues = {
  name: CrewMerchantUi.ClaimLineItemInspection['name'];
  storeInspectionGrade:
    | CrewMerchantUi.ClaimLineItemInspection['storeInspectionGrade']
    | null;
  comment?: CrewMerchantUi.ClaimLineItemInspectionCreate['comment'];
  images?: MediaAsset[];
};

/**
 * The type for the form values is different than what is needed to create the inspection
 * mainly to allow the storeInspectionGrade to be nullable for the select
 */
const createSchema = z.object({
  name: z.string().min(1),
  storeInspectionGrade: z.object({
    id: z.number(),
  }),
  comment: z.string().optional(),
  images: z
    .array(
      z.object({
        name: z.string(),
      }),
    )
    .optional(),
});

function InspectionCreate({
  lineItem,
}: {
  lineItem: CrewMerchantUi.ClaimLineItem;
}) {
  const [showForm, setShowForm] = useState(false);
  const { userFullName } = useMerchantContext();

  const storeId = useStoreId();
  const claimId = useClaimId();

  const invalidateClaim = useInvalidateClaimReview();

  const { data: settings } = useClaimLineItemInspectionSettings(
    claimId,
    lineItem.id,
  );

  const inspectionGrades = settings?.inspectionGrades ?? [];

  // TODO custom fields

  const {
    mutate: createInspection,
    isPending,
    isError,
  } = useMutation({
    mutationFn: ({
      name,
      storeInspectionGrade,
      comment,
      images,
    }: z.infer<typeof createSchema>) => {
      if (!claimId) throw new Error('Missing Claim ID');

      return api
        .store(storeId)
        .claim(`${claimId}`, userFullName)
        .lineItem(`${lineItem.id}`)
        .inspection.create({
          name,
          comment,
          customFields: [],
          storeInspectionGrade: { id: storeInspectionGrade.id },
          images: images?.map(({ name: filename }) => ({
            filename,
          })),
        });
    },
    onSuccess: () => invalidateClaim(),
  });

  const {
    control,
    register,
    formState: { errors, isDirty },
    handleSubmit,
    reset,
  } = useForm<InspectionCreateFormValues>({
    defaultValues: {
      storeInspectionGrade: null,
      comment: '',
      images: [],
    },
  });

  const onSubmit: FormEventHandler = (event) => {
    handleSubmit((values) => {
      createInspection(createSchema.parse(values));
      setShowForm(false);
      reset();
    })(event).catch(console.error);
  };

  // * This  handles the browser's beforeunload event such as a refresh
  const onBeforeUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      if (!isDirty) return;

      // cancel the event as stated by the standard
      event.preventDefault();
      /** set `returnValue` to help with compatibility @see https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event#compatibility_notes */
      // eslint-disable-next-line no-param-reassign
      event.returnValue = '';
    },
    [isDirty],
  );

  useBeforeUnload(onBeforeUnload);

  return (
    <>
      <Button
        variant="primary"
        onClick={() => setShowForm(true)}
        className="self-end md:max-w-fit"
      >
        <PlusIcon className="h-5 w-5" aria-hidden="true" />
        Add Inspection
      </Button>

      <Modal
        show={showForm}
        title={`Add Inspection for ${lineItem.originalStoreOrderLineItem.name ?? 'Claim Line Item'}`}
        onClose={() => {
          setShowForm(false);
          reset();
        }}
      >
        <form onSubmit={onSubmit} className="flex flex-col gap-4">
          <TextInput
            id="inspection-name"
            label="Name"
            required // schema validates as optional, but required for all new inspections
            {...register('name')}
            error={errors.name?.message}
          />
          <Controller
            control={control}
            name="storeInspectionGrade"
            rules={{ required: 'Inspection grade is required' }}
            render={({ field: { value, onChange }, fieldState }) => (
              <SimpleSelect
                label="Grade"
                placeholder="Select an Inspection Grade"
                options={inspectionGrades
                  .map((inspectionGrade) => ({
                    label: inspectionGrade.name,
                    value: `${inspectionGrade.id}`,
                  }))
                  .sort((a, b) => a.label.localeCompare(b.label))}
                onChange={(selected) =>
                  onChange(
                    inspectionGrades.find(
                      ({ id }) => id === Number.parseInt(selected, 10),
                    ),
                  )
                }
                value={`${value?.id ?? ''}`}
                error={fieldState.error?.message}
              />
            )}
          />
          <TextAreaInput
            id="inspection-comment"
            label="Comment"
            {...register('comment')}
            error={errors.comment?.message}
          />
          <Controller
            control={control}
            name="images"
            render={({ field: { value, onChange } }) => (
              <MediaUploader
                id="inspection-uploads"
                label="Media"
                assets={value}
                onChange={onChange}
              />
            )}
          />
          {isError && (
            <SupportingText error>
              There was an error saving the inspection. Please try again.
            </SupportingText>
          )}
          <Button variant="primary" type="submit" loading={isPending}>
            Save Inspection
          </Button>
        </form>
      </Modal>
    </>
  );
}

export default function Inspection({
  lineItem,
}: {
  lineItem: CrewMerchantUi.ClaimLineItem;
}) {
  return (
    <div className="rounded-md border">
      <Disclosure
        renderSummary={
          <SimpleSummary>
            <span className="ml-2 text-corso-gray-500">Inspections</span>
            <Badge className="ml-auto">
              {formatter.count(lineItem.inspections.length)}
            </Badge>
          </SimpleSummary>
        }
      >
        <div className="flex flex-col gap-2 p-3 lg:gap-4 lg:p-4">
          {!!lineItem.inspections.length && (
            <ol className="flex flex-col gap-px bg-gray-200">
              {lineItem.inspections.map((inspection) => (
                <li className="bg-white p-2 first:pt-0 last:pb-0">
                  <InspectionDisplay
                    key={inspection.id}
                    lineItemId={lineItem.id}
                    inspection={inspection}
                  />
                </li>
              ))}
            </ol>
          )}
          <InspectionCreate lineItem={lineItem} />
        </div>
      </Disclosure>
    </div>
  );
}
