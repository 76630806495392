import { CorsoClaimType, CrewMerchantUi } from 'corso-types';
import { useState } from 'react';
import ClaimCreateForm from '~/components/claimCreate/ClaimCreateForm';
import ClaimCreateLookup from '~/components/claimCreate/ClaimCreateLookup';
import ClaimTypeSelection from '~/components/claimCreate/ClaimTypeSelection';
import Page from '~/components/Page';
import Panel from '~/components/Panel';

export default function ClaimCreate() {
  const [order, setOrder] = useState<CrewMerchantUi.CrewOrder | null>(null);
  const [claimType, setClaimType] = useState<CorsoClaimType | null>(null);

  if (!order) return <ClaimCreateLookup onOrderLookup={setOrder} />;

  return (
    <Page headline="Create Claim" variant="narrow">
      <Panel headline={`Order ${order.orderNo}`} className="gap-6">
        <ClaimTypeSelection onClaimTypeSelect={setClaimType} />
        {claimType && (
          <ClaimCreateForm
            // reconciliation resets the form when the claim type changes
            key={claimType}
            order={order}
            claimType={claimType}
            onCancel={() => {
              setOrder(null);
              setClaimType(null);
            }}
          />
        )}
      </Panel>
    </Page>
  );
}
