import { z } from 'zod';
import {
  CrewClaimResolutionMethodEnum,
  FinalizationEmailTypeEnum,
} from '../../enums/crew.js';
import { enumToZodLiteralUnion } from '../../zodExtensions.js';
import { claim } from './claim.js';
import { claimLineItem, variantExchangeLineItem } from './claimLineItem.js';

// ----------  Claim Line Item Process ----------

export const baseClaimLineItemProcess = claimLineItem.pick({
  id: true,
  noteToCustomer: true,
  errors: true,
  quantity: true,
  returnLineItemIdFromPlatform: true,
});

// ? on claim finalization if you want to notify the customer by email, you can pass in the following payload
export const claimNotifyByEmail = z.object({
  emailType: enumToZodLiteralUnion(FinalizationEmailTypeEnum),
  subject: z.string(),
  header: z.string(),
  body: z.string(),
});

// ----------  Claim Line Item Deny  ----------

export const denyClaimLineItem = baseClaimLineItemProcess.merge(
  z.object({
    claimStatusDetailCode: z.string(), // ! note to future us, we should make this use the enum values someday
  }),
);

// ----------  Claim Line Item Approve Variant Exchange ----------

export const approvedVariantExchange = baseClaimLineItemProcess
  .merge(
    z.object({
      resolutionMethodEnum: z.literal(
        CrewClaimResolutionMethodEnum.variantExchange,
      ),
    }),
  )
  .merge(
    claimLineItem
      .pick({
        variantExchangeLineItem: true,
      })
      .required(),
  );

// ----------  Claim Line Item Approve Replacement Order ----------

export const approvedReplacementOrder = baseClaimLineItemProcess.merge(
  z.object({
    resolutionMethodEnum: z.literal(
      CrewClaimResolutionMethodEnum.replacementOrder,
    ),
    replacementItems: z.array(variantExchangeLineItem),
  }),
);

// ----------  Claim Line Item Approve Monetary ----------

const baseApprovedMonetary = baseClaimLineItemProcess
  .merge(
    z.object({
      amount: z.number(),
      requestedAmount: z.number(), // we will want to remove this in favor of deriving the value from the claim
    }),
  )
  .merge(baseClaimLineItemProcess);

export const approvedGiftCard = baseApprovedMonetary.merge(
  z.object({
    resolutionMethodEnum: z.literal(CrewClaimResolutionMethodEnum.giftCard),
  }),
);

export const approvedRefund = baseApprovedMonetary.merge(
  z.object({
    resolutionMethodEnum: z.literal(CrewClaimResolutionMethodEnum.refund),
  }),
);

const claimProcess = claim
  .pick({
    id: true,
    noteToCustomer: true,
    giftCardIncentiveAmountApplied: true,
    feeApplied: true,
    returnShippingAmountApplied: true,
    billingAddress: true,
    shippingAddress: true,
    customerEmail: true,
    externalId: true,
    exchangeOrderShippingAmountApplied: true,
    originalStoreOrder: true,
    claimType: true,
    createdOn: true,
    returnIdFromPlatform: true,
  })
  .merge(
    z.object({
      claimLineItems: z.object({
        approved: z.object({
          refunds: z.array(approvedRefund),
          giftCards: z.array(approvedGiftCard),
          variantExchanges: z.array(approvedVariantExchange),
          replacementOrders: z.array(approvedReplacementOrder),
        }),
        denied: z.array(denyClaimLineItem),
      }),
    }),
  );

export const claimFinalizePayload = z.object({
  notifyCustomerByEmail: claimNotifyByEmail.optional(),
  claim: claimProcess,
});

export const claimNeedsMoreInfoPayload = z.object({
  notifyCustomerByEmail: claimNotifyByEmail,
  claim: claimProcess.pick({
    shippingAddress: true,
    externalId: true,
    customerEmail: true,
    id: true,
  }),
});
