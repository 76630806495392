import LineItem from '~/components/LineItem';
import { useMerchantContext } from '~/providers/MerchantProvider';
import { formatter } from '~/utils/formatter';
import ResolutionLineItemTitle from './ReslutionLineItemTitle';
import { MonetaryResolutionLineItemProps } from './types';

export default function RefundLineItem({
  compareAmount,
  amount,
  handlingFee,
  shippingFee,
}: MonetaryResolutionLineItemProps & {
  handlingFee?: number;
  shippingFee?: number;
}) {
  const {
    storeUser: {
      store: { currencyCode },
    },
  } = useMerchantContext();

  return (
    <ResolutionLineItemTitle title="Refund to Original Payment Method">
      <LineItem
        variant="no-image"
        name="Total Amount"
        compareUnitPrice={compareAmount}
        unitPrice={amount}
      >
        {handlingFee !== undefined && (
          <p className="flex justify-between gap-2 text-sm text-corso-gray-500">
            <span>Handling Fee</span>
            <span>{formatter.currency(handlingFee, currencyCode)}</span>
          </p>
        )}
        {shippingFee !== undefined && (
          <p className="flex justify-between gap-2 text-sm text-corso-gray-500">
            <span>Shipping Fee</span>
            <span>{formatter.currency(shippingFee, currencyCode)}</span>
          </p>
        )}
      </LineItem>
    </ResolutionLineItemTitle>
  );
}
