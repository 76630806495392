// ---------- Store Rules (Pegasus) ------------

import { z } from 'zod';
import { enumToZodLiteralUnion, helperSchema } from '../../zodExtensions.js';
import { Pegasus } from './pegasus.js';

export const storeRule = z.object({
  id: z.number(),
  isEnabled: z.boolean(),
  name: helperSchema.nonEmptyString,
  hook: enumToZodLiteralUnion(Pegasus.Hook),
  rule: Pegasus.ruleSchema,
});

export const storeRuleUpdate = storeRule;

export const storeRuleCreate = storeRule.omit({ id: true });

// ---------- Array Fact Data (Pegasus) ------------

const labeledFactValueSchema = <T extends Pegasus.DefiniteFact>(fact: T) =>
  z.array(
    z.object({
      label: z.string(),
      // ! EXPLICIT TYPE ASSERTION; this narrows the type to the specific fact passed
      value: Pegasus.valueSchemaForFact(fact).element as z.ZodSchema<
        Pegasus.FactValueData<T>[number]
      >,
    }),
  );

// TODO determine if there's a better way to generate this; however, it thankfully is explicit
export const arrayFactData = z.object({
  [Pegasus.ArrayFactType.arbitrary]: z.object({
    [Pegasus.Fact.orderTags]: Pegasus.valueSchemaForFact(
      Pegasus.Fact.orderTags,
    ),
    [Pegasus.Fact.customerTags]: Pegasus.valueSchemaForFact(
      Pegasus.Fact.customerTags,
    ),
    [Pegasus.Fact.rmaStatus]: Pegasus.valueSchemaForFact(
      Pegasus.Fact.rmaStatus,
    ),
    [Pegasus.Fact.productTags]: Pegasus.valueSchemaForFact(
      Pegasus.Fact.productTags,
    ),
    [Pegasus.Fact.productTypes]: Pegasus.valueSchemaForFact(
      Pegasus.Fact.productTypes,
    ),
    [Pegasus.Fact.productCollections]: Pegasus.valueSchemaForFact(
      Pegasus.Fact.productCollections,
    ),
    [Pegasus.Fact.orderNumber]: Pegasus.valueSchemaForFact(
      Pegasus.Fact.orderNumber,
    ),
    [Pegasus.Fact.customerEmail]: Pegasus.valueSchemaForFact(
      Pegasus.Fact.customerEmail,
    ),
    [Pegasus.Fact.orderDiscountCode]: Pegasus.valueSchemaForFact(
      Pegasus.Fact.orderDiscountCode,
    ),
  }) satisfies z.ZodSchema<{
    [K in Pegasus.ArbitraryFact]: Pegasus.FactValueData<K>;
  }>,
  [Pegasus.ArrayFactType.definite]: z.object({
    [Pegasus.Fact.orderCountry]: labeledFactValueSchema(
      Pegasus.Fact.orderCountry,
    ),
    [Pegasus.Fact.claimLineItemRequestedResolutionMethod]:
      labeledFactValueSchema(
        Pegasus.Fact.claimLineItemRequestedResolutionMethod,
      ),
    [Pegasus.Fact.returnShipmentStatus]: labeledFactValueSchema(
      Pegasus.Fact.returnShipmentStatus,
    ),
    [Pegasus.Fact.reasonId]: labeledFactValueSchema(Pegasus.Fact.reasonId),
    [Pegasus.Fact.reasonCategoryCode]: labeledFactValueSchema(
      Pegasus.Fact.reasonCategoryCode,
    ),
    [Pegasus.Fact.reasonDetailId]: labeledFactValueSchema(
      Pegasus.Fact.reasonDetailId,
    ),
    [Pegasus.Fact.claimType]: labeledFactValueSchema(Pegasus.Fact.claimType),
    [Pegasus.Fact.orderChannel]: labeledFactValueSchema(
      Pegasus.Fact.orderChannel,
    ),
    [Pegasus.Fact.claimLineItemCustomFieldResponse]: labeledFactValueSchema(
      Pegasus.Fact.claimLineItemCustomFieldResponse,
    ),
    [Pegasus.Fact.orderGateway]: labeledFactValueSchema(
      Pegasus.Fact.orderGateway,
    ),
    [Pegasus.Fact.registrationChannel]: labeledFactValueSchema(
      Pegasus.Fact.registrationChannel,
    ),
    [Pegasus.Fact.orderFulfillmentLocation]: labeledFactValueSchema(
      Pegasus.Fact.orderFulfillmentLocation,
    ),
  }) satisfies z.ZodSchema<{
    [K in Pegasus.DefiniteFact]: {
      label: string;
      value: Pegasus.FactValueData<K>[number];
    }[];
  }>,
});
