import { useQuery } from '@tanstack/react-query';
import api from '~/api';
import { useStoreId } from './useStoreId';

export function useClaimLineItemInspectionSettings(
  claimId: number,
  claimLineItemId: number,
) {
  const storeId = useStoreId();

  return useQuery({
    queryKey: ['inspectionSettings', storeId, claimId, claimLineItemId],
    queryFn: () =>
      api
        .store(`${storeId}`)
        .claim(`${claimId}`, '')
        .lineItem(`${claimLineItemId}`)
        .inspection.settings(),
  });
}
