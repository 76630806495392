const intervalMs = 1_000 * 60 * 5; // 5 minutes
/**
 * Given a `registration` for a `serviceWorkerScriptUrl`, triggers periodic `update` calls; basically acting as a polling mechanism to ensure the service worker is up to date.
 * Will not force the service worker to claim clients, or skip waiting, but will ensure the service worker is up to date.
 * Extremely useful for SPAs lacking true navigation within the service worker `scope` that may be open for long periods of time, or for users who may not refresh their browser often.
 *
 * For details around this implementation, learn more from the [Vite PWA guide on periodic service worker updates](https://vite-pwa-org.netlify.app/guide/periodic-sw-updates.html#handling-edge-cases).
 *
 * @see https://vite-pwa-org.netlify.app/guide/periodic-sw-updates.html#periodic-service-worker-updates
 * @see https://web.dev/articles/service-worker-lifecycle
 */
export function periodicServiceWorkerUpdates(
  serviceWorkerScriptUrl: string,
  registration: ServiceWorkerRegistration | undefined,
) {
  if (!registration) return;
  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  setInterval(async () => {
    // check for user movement or activity, and skip the interval if the user is active
    if (document.hasFocus()) return;

    if (registration.installing) return;

    if ('connection' in navigator && !navigator.onLine) return;

    try {
      const response = await fetch(serviceWorkerScriptUrl, {
        cache: 'no-store',
        headers: { cache: 'no-store', 'cache-control': 'no-cache' },
      });
      if (!response.ok) return;
    } catch (error) {
      // eslint-disable-next-line no-console -- we want to log this as a warning and continue on
      console.warn(error);
      return;
    }

    await registration.update();
  }, intervalMs);
}
