import { Fragment, ReactNode } from 'react';

export type Description = { term: string; details: ReactNode };
/**
 * Simple extension of the native `dl`, `dt`, and `dd` HTML elements.
 */
export default function DescriptionList({
  descriptions,
}: {
  /** It's expected that each `term` is unique, as these are used as a `key` for each. */
  descriptions: Description[];
}) {
  return (
    <dl className="sm:grid-auto-cols grid text-sm text-corso-gray-500 sm:gap-x-3 sm:gap-y-2">
      {descriptions.map(({ term, details }) => (
        <Fragment key={term}>
          <dt className="col-span-2 font-medium sm:col-span-1 sm:col-start-1">
            {term}
          </dt>
          <dd className="mb-2 break-words sm:col-span-3 sm:col-start-2 sm:mb-0">
            {details}
          </dd>
        </Fragment>
      ))}
    </dl>
  );
}
