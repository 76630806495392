import { useEffect } from 'react';
import { useRouteError } from 'react-router-dom';
import FullPageStatus from '~/components/FullPageStatus';

export default function FullPageError() {
  const error = useRouteError(); // ? maybe try to parse to see if we have a user-friendly error available too

  useEffect(() => {
    console.error(error);
  }, [error]);

  return (
    <FullPageStatus title="Oops.." message="Sorry, something went wrong." />
  );
}
