import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  CrewClaimResolutionMethodEnum,
  CrewIncentiveTypeEnum,
  isNonNullable,
  isTruthy,
  Pegasus,
  ShipmentMethod,
  toEnum,
} from 'corso-types';
import { ComponentProps, FC, ReactNode, useEffect } from 'react';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import Alert from '~/components/Alert';
import BackAction from '~/components/BackAction';
import Button from '~/components/Button';
import ContentWrapper from '~/components/ContentWrapper';
import {
  DateInput,
  EmailInput,
  Fieldset,
  NumberInput,
  RadioGroupInput,
  SwitchInput,
  TextInput,
} from '~/components/field';
import MonetaryRateInput, {
  MonetaryType,
} from '~/components/field/MonetaryRateInput';
import IconAction from '~/components/IconAction';
import Panel from '~/components/Panel';
import Skeleton from '~/components/Skeleton';
import { MultiSelect } from '~/components/ui/MultiSelect';
import { Badge } from '~/components/ui/primitives/Badge';
import SimpleSelect from '~/components/ui/SimpleSelect';
import { useClaimTagOptions } from '~/hooks/useClaimTags';
import { useCustomFields } from '~/hooks/useCustomFields';
import { useFactValues } from '~/hooks/useFactValues';
import useIsTest from '~/hooks/useIsTest';
import { usePathParams } from '~/hooks/usePathParams';
import { useProductGroups } from '~/hooks/useProductGroups';
import { useReturnLocations } from '~/hooks/useReturnLocations';
import { useSearchParam } from '~/hooks/useSearchParam';
import { useSettingsLayoutContext } from '~/hooks/useSettingsLayoutContext';
import { useStoreRules, useStoreRuleUpsert } from '~/hooks/useStoreRules';
import { useMerchantContext } from '~/providers/MerchantProvider';
import {
  shipmentTypeLabels,
  StoreRule,
  StoreRuleCreate,
  storeRule as storeRuleSchema,
  StoreRuleUpdate,
} from '~/types';
import { formatter } from '~/utils/formatter';
import {
  StoreRuleTemplate,
  storeRuleTemplatesWithIds,
} from './StoreRuleTemplates';

/** Mapping of enums to user-friendly display content for labels, descriptions, etc. */
export const displayName = {
  eventType: {
    [Pegasus.EventType.allowClaimTypeSelection]:
      'Set Return vs. Warranty Options',
    [Pegasus.EventType.modifyResolutionWindow]: 'Modify Claim Windows',
    [Pegasus.EventType.autoFinalizeClaim]:
      'Set Claim Auto-Finalization Options',
    [Pegasus.EventType.askAPolicyEnforcingQuestion]:
      'Set A Policy Enforcing Question',
    [Pegasus.EventType.offerInstantExchange]: 'Set Instant Exchange Options',
    [Pegasus.EventType.collectCustomFields]:
      'Modify What Information is Collected',
    [Pegasus.EventType.applyClaimTags]: 'Set Claim Tags',
    [Pegasus.EventType.customizeClaimFinalizationSettings]:
      'Modify Claim Finalization Settings',

    [Pegasus.EventType.modifyIncentives]: 'Modify Exchange Incentives',
    [Pegasus.EventType.applyFee]: 'Apply Or Waive A Fee',
    [Pegasus.EventType.chargeForReturnLabel]: 'Charge For Return Label',
    [Pegasus.EventType.chargeForExchangeOrderShipping]:
      'Charge For Exchange Order Shipping',
    [Pegasus.EventType.allowCustomerToKeepItem]: 'Allow Customer To Keep Item',
    [Pegasus.EventType.productRegistrationSelection]:
      'Offer Products For Registration',
    [Pegasus.EventType.returnShippingConfig]: 'Set A Return Shipping Policy',
    [Pegasus.EventType.applyOrderTags]: 'Set Order Tags In Shopify',
  } as const satisfies Record<Pegasus.EventType, string>,
  hook: {
    [Pegasus.Hook.orderLookup]: 'Order Lookup',
    [Pegasus.Hook.claimCreated]: 'Claim Created',
    [Pegasus.Hook.rmaWebhook]: 'WMS RMA Event',
    [Pegasus.Hook.returnShipmentWebhook]: 'Return Shipment Event',
    [Pegasus.Hook.afterReasonSelection]: 'After Reason Selection',
    [Pegasus.Hook.claimFinalized]: 'Claim Finalized',
    [Pegasus.Hook.beforeClaimSubmission]: 'Before Claim Submission',
    [Pegasus.Hook.returnShipmentCreated]: 'Return Shipment Created',
    [Pegasus.Hook.registrationProductSelection]:
      'Registration Product Selection',
    [Pegasus.Hook.returnShipmentQuote]: 'Return Shipment Quote',
  } as const satisfies Record<Pegasus.Hook, string>,
  fact: {
    [Pegasus.Fact.orderFulfillmentLocation]: 'Order Fulfillment Location',
    [Pegasus.Fact.orderDiscountCode]: 'Order Discount',
    [Pegasus.Fact.customerEmail]: 'Customer Email',
    [Pegasus.Fact.orderCreated]: 'Order Created',
    [Pegasus.Fact.orderCountry]: 'Order Country',
    [Pegasus.Fact.orderTotal]: 'Order Total',
    [Pegasus.Fact.orderTags]: 'Order Tags',
    [Pegasus.Fact.claimLineItemTotal]: 'Line Item Total',
    [Pegasus.Fact.customerTags]: 'Customer Tags',
    [Pegasus.Fact.rmaStatus]: 'RMA Status',
    [Pegasus.Fact.rmaAllItemsReceived]: 'RMA All Items Marked As Received',
    [Pegasus.Fact.returnShipmentStatus]: 'Return Shipment Status',
    [Pegasus.Fact.productTags]: 'Product Tags',
    [Pegasus.Fact.productTypes]: 'Product Type',
    [Pegasus.Fact.claimType]: 'Claim Type',
    [Pegasus.Fact.reasonCategoryCode]: 'Reason Category',
    [Pegasus.Fact.reasonId]: 'Reason',
    [Pegasus.Fact.reasonDetailId]: 'Reason Detail',
    [Pegasus.Fact.orderNumber]: 'Order Number',
    [Pegasus.Fact.orderChannel]: 'Order Channel',
    [Pegasus.Fact.orderProtectedByCorso]: 'Order Protected By Corso',
    [Pegasus.Fact.claimLineItemRequestedResolutionMethod]:
      'Requested Resolution',
    [Pegasus.Fact.exchangeOrderTotal]: 'Exchange Order Total',
    [Pegasus.Fact.productCollections]: 'Product Collection',
    [Pegasus.Fact.claimLineItemCustomFieldResponse]: 'Custom Field Response',
    [Pegasus.Fact.orderDaysSinceCreated]: 'Order Days Since Created',
    [Pegasus.Fact.statusCustomer]: 'Status Account Customer',
    [Pegasus.Fact.orderGateway]: 'Order Gateway',
    [Pegasus.Fact.registrationChannel]: 'Registration Channel',
    [Pegasus.Fact.registrationEstimatedPurchaseDate]:
      'Registration Estimated Purchase Date',
  } as const satisfies Record<Pegasus.Fact, string>,
  operator: {
    [Pegasus.Operator.equal]: 'Equals',
    [Pegasus.Operator.notEqual]: 'Not Equals',
    [Pegasus.Operator.lessThan]: 'Less Than',
    [Pegasus.Operator.greaterThan]: 'Greater Than',
    [Pegasus.Operator.in]: 'Any Of',
    [Pegasus.Operator.notIn]: 'Not Any Of',
  } as const satisfies Record<Pegasus.Operator, string>,
  // ? maybe include boolean expressions
};

// ? maybe merge with `displayName`
const valueTypeOperator: Partial<
  Record<Pegasus.ValueType, Partial<Record<Pegasus.Operator, string>>>
> = {
  [Pegasus.ValueType.date]: {
    [Pegasus.Operator.lessThan]: 'Before',
    [Pegasus.Operator.greaterThan]: 'After',
  },
} satisfies {
  [V in Pegasus.ValueType]?: Record<
    (typeof Pegasus.valueOperators)[V][number],
    string
  >;
};

// ? maybe merge with `displayName`
export const eventTypeDescription = {
  [Pegasus.EventType.returnShippingConfig]:
    'Configure where customers return items, and whether to use return labels or packing slips.',
  [Pegasus.EventType.modifyResolutionWindow]:
    'Update the allowed window for a customer to submit a claim for each resolution method.',
  [Pegasus.EventType.autoFinalizeClaim]:
    'Finalize a claim with customer-requested resolutions.',
  [Pegasus.EventType.askAPolicyEnforcingQuestion]:
    'Ask a yes/no question to enforce requirements for your return policy.',
  [Pegasus.EventType.offerInstantExchange]:
    'Allow a customer to exchange an item instantly, without waiting for the original item to be returned.',
  [Pegasus.EventType.allowClaimTypeSelection]:
    'Allow the customer to select between a Return or Warranty, when both are available.',
  [Pegasus.EventType.collectCustomFields]:
    'Modify the information collected during the claim process, including custom fields and media uploads.',
  [Pegasus.EventType.applyClaimTags]:
    'Use tags to organize and categorize claims.',
  [Pegasus.EventType.applyOrderTags]:
    'Apply tags to the original or replacement order in Shopify.',
  [Pegasus.EventType.customizeClaimFinalizationSettings]:
    'Customize the finalization settings for claims, including sending notification emails, out of stock scenarios, and more.',
  [Pegasus.EventType.modifyIncentives]:
    'Modify or apply incentives dynamically based on the claim.',
  [Pegasus.EventType.applyFee]:
    'Dynamically apply or waive a fee on the claim.',
  [Pegasus.EventType.chargeForReturnLabel]:
    'Charge the cost of a return label, if applicable. A Stripe integration is recommended, to enable charging in all scenarios.',
  [Pegasus.EventType.chargeForExchangeOrderShipping]:
    'Charge for the cost of the exchange order shipping, if applicable.  A Stripe integration is recommended, to enable charging in all scenarios.',
  [Pegasus.EventType.allowCustomerToKeepItem]:
    'Omit items from being added to a return shipment. If all items are omitted, no return shipment will be created, and no postage will be purchased.',
  [Pegasus.EventType.productRegistrationSelection]:
    'Define the product registration selection process, including what products are offered during the registration process.',
} as const satisfies Record<Pegasus.EventType, string>;

// ? maybe merge with `displayName`
const matchCondition = {
  all: 'Matches all of the following',
  any: 'Matches any of the following',
} as const;

const booleanExpressionBadge = {
  all: <Badge variant="info">AND</Badge>,
  any: <Badge variant="info">OR</Badge>,
} as const; // ? maybe extend to include `NOT`, or possibly merge with `matchCondition`

const resolutionKind = {
  returnableUntil: 'Returnable Until Date',
  eligibilityDays: 'Eligibility Window Days',
} as const;

const arbitraryValueTypeFormatter: {
  [V in Pegasus.ArbitraryValueType]: (
    value: Pegasus.ValueTypeData[V],
  ) => ReactNode;
} = {
  [Pegasus.ValueType.stringArray]: ([...values]) => (
    <Badge>
      <strong className="font-medium">
        {values.sort((a, b) => a.localeCompare(b)).join(', ')}
      </strong>
    </Badge>
  ),
};

/** If values exceeds this amount, a fallback is displayed.
 * This might need to be dependent on the fact; i.e. country vs tags
 */
const MAX_ARRAY_VALUES_VISIBLE = 3;
const definiteValueTypeFormatter: {
  [V in Pegasus.DefiniteValueType]: (
    values: Pegasus.ValueTypeData[V],
    options: { label: string; value: Pegasus.ValueTypeData[V][number] }[],
  ) => ReactNode;
} = {
  [Pegasus.ValueType.stringArray]: ([...values], [...options]) => {
    const labels = values
      .map((value) => options.find((option) => option.value === value))
      .filter(isTruthy) // ? maybe have a fallback for values not found
      .map((option) => option.label)
      .sort((a, b) => a.localeCompare(b));

    return (
      <Badge>
        {labels.length > MAX_ARRAY_VALUES_VISIBLE ?
          <>
            <strong className="font-medium">
              {labels.slice(0, MAX_ARRAY_VALUES_VISIBLE).join(', ')}
            </strong>
            {', '}
            and {labels.length - MAX_ARRAY_VALUES_VISIBLE} More
          </>
        : <strong className="font-medium">{labels.join(', ')}</strong>}
      </Badge>
    );
  },
  [Pegasus.ValueType.numberArray]: ([...values], [...options]) => {
    const labels = values
      .map((value) => options.find((option) => option.value === value))
      .filter(isTruthy) // ? maybe have a fallback for values not found
      .map((option) => option.label)
      .sort((a, b) => a.localeCompare(b));

    return (
      <Badge>
        {labels.length > MAX_ARRAY_VALUES_VISIBLE ?
          <>
            <strong className="font-medium">
              {labels.slice(0, MAX_ARRAY_VALUES_VISIBLE).join(', ')}
            </strong>
            {', '}
            and {labels.length - MAX_ARRAY_VALUES_VISIBLE} More
          </>
        : <strong className="font-medium">{labels.join(', ')}</strong>}
      </Badge>
    );
  },
};

const singleValueTypeFormatter: {
  [V in Exclude<Pegasus.ValueType, Pegasus.ArrayValueType>]: (
    value: Pegasus.ValueTypeData[V],
  ) => ReactNode;
} = {
  // ? maybe need a separate currency value type
  [Pegasus.ValueType.number]: (value) => (
    <Badge>
      <strong className="font-medium">{value}</strong>
    </Badge>
  ),
  [Pegasus.ValueType.boolean]: (value) => (
    <Badge>
      <strong className="font-medium">{`${value}`}</strong>
    </Badge>
  ),
  // date string with no time component
  [Pegasus.ValueType.date]: (value) => (
    <Badge>
      <strong className="font-medium">{formatter.date(value)}</strong>
    </Badge>
  ),
};

const valueTypeDefault: {
  [V in Pegasus.ValueType]: () => Pegasus.ValueTypeData[V];
} = {
  [Pegasus.ValueType.numberArray]: () => [],
  [Pegasus.ValueType.stringArray]: () => [],
  [Pegasus.ValueType.number]: () => 0, // could cause issues if used for ID selection; however, that should be a definite lookup instead
  [Pegasus.ValueType.boolean]: () => false,
  [Pegasus.ValueType.date]: () => new Date().toISOString(),
} as const satisfies {
  [V in Pegasus.ValueType]: () => Pegasus.ValueTypeData[V];
};

type ValueTypeInputProps<V extends Pegasus.ValueType> = {
  id: string;
  error?: string;
  fact: Pegasus.Fact;
  value: Pegasus.ValueTypeData[V];
  onChange: (value: Pegasus.ValueTypeData[V]) => void;
};

const arbitraryValueTypeInput: {
  [V in Pegasus.ArbitraryValueType]: FC<
    ValueTypeInputProps<V> & { options: Pegasus.ValueTypeData[V] }
  >;
} = {
  [Pegasus.ValueType.stringArray]: ({
    fact,
    value,
    onChange,
    options,
    error,
  }) => (
    <div className="flex-grow">
      <MultiSelect
        label={`${displayName.fact[fact]} Value`}
        placeholder={`Find or Add ${displayName.fact[fact]}`}
        labelVisuallyHidden
        options={options.map((option) => ({
          label: option,
          searchValue: option,
          value: option,
        }))}
        value={value.map((option) => ({
          label: option,
          searchValue: option,
          value: option,
        }))}
        onChange={(selections) =>
          onChange(selections.map((selection) => selection.value))
        }
        error={error}
        creatable
      />
    </div>
  ),
};

const definiteValueTypeInput: {
  [V in Pegasus.DefiniteValueType]: FC<
    ValueTypeInputProps<V> & {
      options: { label: string; value: Pegasus.ValueTypeData[V][number] }[];
    }
  >;
} = {
  [Pegasus.ValueType.stringArray]: ({
    fact,
    value,
    onChange,
    options,
    error,
  }) => {
    const definiteStringOptions = options.map((option) => ({
      label: option.label,
      value: option.value,
      searchValue: option.label,
    }));

    return (
      <div className="flex-grow">
        <MultiSelect
          label={`${displayName.fact[fact]} Value`}
          labelVisuallyHidden
          placeholder={`Select ${displayName.fact[fact]}`}
          options={definiteStringOptions}
          value={value
            .map((definiteOption) =>
              definiteStringOptions.find(
                (option) => option.value === definiteOption,
              ),
            )
            .filter(isTruthy)}
          onChange={(change) => onChange(change.map((option) => option.value))}
          error={error}
          togglable
          visibleLimit={MAX_ARRAY_VALUES_VISIBLE}
        />
      </div>
    );
  },
  [Pegasus.ValueType.numberArray]: ({
    fact,
    value,
    onChange,
    options,
    error,
  }) => {
    const definiteNumberOptions = options.map((option) => ({
      label: option.label,
      value: `${option.value}`,
      searchValue: option.label,
    }));

    return (
      <div className="flex-grow">
        <MultiSelect
          label={`${displayName.fact[fact]} Value`}
          labelVisuallyHidden
          placeholder={`Select ${displayName.fact[fact]}`}
          options={definiteNumberOptions}
          value={value
            .map((definiteOption) =>
              definiteNumberOptions.find(
                (option) =>
                  Number.parseInt(option.value, 10) === definiteOption,
              ),
            )
            .filter(isTruthy)}
          onChange={(change) =>
            onChange(change.map((option) => Number.parseInt(option.value, 10)))
          }
          error={error}
          togglable
          visibleLimit={MAX_ARRAY_VALUES_VISIBLE}
        />
      </div>
    );
  },
};

const singleValueTypeInput: {
  [V in Exclude<Pegasus.ValueType, Pegasus.ArrayValueType>]: FC<
    ValueTypeInputProps<V>
  >;
} = {
  [Pegasus.ValueType.number]: ({ id, fact, value, onChange, error }) => (
    <NumberInput
      id={id}
      label={`${displayName.fact[fact]} Value`}
      labelVisuallyHidden
      required
      value={value}
      onChange={(e) => onChange(e.target.valueAsNumber)}
      error={error}
    />
  ),
  [Pegasus.ValueType.boolean]: ({ fact, value, onChange, error }) => (
    <SimpleSelect
      label={`${displayName.fact[fact]} Value`}
      labelVisuallyHidden
      value={`${value}`}
      onChange={(selected) => onChange(selected === 'true')} // convert literal back to boolean through condition
      options={[true, false].map((v) => ({ label: `${v}`, value: `${v}` }))}
      error={error}
    />
  ),
  [Pegasus.ValueType.date]: ({ id, fact, value, onChange, error }) => (
    <DateInput
      id={id}
      label={`${displayName.fact[fact]} Value`}
      labelVisuallyHidden
      required
      value={value}
      onChange={onChange}
      error={error}
    />
  ),
};

// ? maybe this could leverage `Intl.PluralRules` // TODO better name
function zeroNone(
  {
    zero,
    none,
  }: {
    zero: string;
    none: string;
  },
  value: number | null,
) {
  if (value === null) return none;
  if (value === 0) return zero;
  // ? maybe support `one` value lookup, among other ordinals via `Partial`
  return undefined;
}

function EventModifyResolutionWindow() {
  const { control, watch } = useFormContext<StoreRuleFormData>();

  const selectedResolutionKind = watch('event.params.kind');

  return (
    <>
      <Controller
        control={control}
        name="event.params.kind"
        defaultValue="returnableUntil"
        render={({ field, fieldState }) => (
          <RadioGroupInput
            label="Kind" // TODO better text
            labelVisuallyHidden
            id="kind"
            options={resolutionKind}
            value={field.value}
            // TODO reset other parameter fields when changing kind
            onChange={field.onChange}
            error={fieldState.error?.message}
          />
        )}
      />
      {/* // TODO evaluate if there's a nice way to create all of these controlled inputs programmatically for each type */}
      {/* // * all these inputs are controlled to aid with the discerning error messages from discriminated union merging in the form */}
      {selectedResolutionKind === 'returnableUntil' ?
        // TODO `details` representing these as UTC dates
        <>
          <Controller
            control={control}
            name="event.params.refund"
            render={({ field, fieldState }) => (
              <DateInput
                id="refund"
                label="Refund"
                value={(field.value as string | undefined) ?? null} // ! EXPLICIT TYPE ASSERTION // TODO resolve and remove
                onChange={field.onChange}
                error={fieldState.error?.message}
                details={
                  !(field.value as string | undefined) &&
                  'Using Store Defaults for Refunds'
                } // ! EXPLICIT TYPE ASSERTION // TODO resolve and remove
              />
            )}
          />
          <Controller
            control={control}
            name="event.params.giftCard"
            render={({ field, fieldState }) => (
              <DateInput
                id="gift-card"
                label="Gift Card"
                value={(field.value as string | undefined) ?? null} // ! EXPLICIT TYPE ASSERTION // TODO resolve and remove
                onChange={field.onChange}
                error={fieldState.error?.message}
                details={
                  !(field.value as string | undefined) &&
                  'Using Store Defaults for Gift Cards'
                } // ! EXPLICIT TYPE ASSERTION // TODO resolve and remove
              />
            )}
          />
          <Controller
            control={control}
            name="event.params.exchange"
            render={({ field, fieldState }) => (
              <DateInput
                id="variant-exchange"
                label="Variant Exchange"
                value={(field.value as string | undefined) ?? null} // ! EXPLICIT TYPE ASSERTION // TODO resolve and remove
                onChange={field.onChange}
                error={fieldState.error?.message}
                details={
                  !(field.value as string | undefined) &&
                  'Using Store Defaults for Variant Exchanges'
                } // ! EXPLICIT TYPE ASSERTION // TODO resolve and remove
              />
            )}
          />
          <Controller
            control={control}
            name="event.params.warrantyReview"
            render={({ field, fieldState }) => (
              <DateInput
                id="warranty-review"
                label="Warranty Review"
                value={(field.value as string | undefined) ?? null} // ! EXPLICIT TYPE ASSERTION // TODO resolve and remove
                onChange={field.onChange}
                error={fieldState.error?.message}
                details={
                  !(field.value as string | undefined) &&
                  'Using Store Defaults for Warranty Reviews'
                } // ! EXPLICIT TYPE ASSERTION // TODO resolve and remove
              />
            )}
          />
        </>
      : <>
          <Controller
            control={control}
            name="event.params.refund"
            render={({ field, fieldState }) => (
              <NumberInput
                id="refund"
                label="Refund"
                addon={{ insideEnd: 'days' }}
                value={(field.value as number | undefined) ?? null} // ! EXPLICIT TYPE ASSERTION // TODO resolve and remove
                onChange={(event) => {
                  const value = event.target.valueAsNumber;
                  field.onChange(Number.isNaN(value) ? null : value);
                }}
                error={fieldState.error?.message}
                details={zeroNone(
                  {
                    zero: 'Refunds Not Offered',
                    none: 'Using Store Defaults for Refunds',
                  },
                  (field.value as number | undefined) ?? null, // ! EXPLICIT TYPE ASSERTION // TODO resolve and remove
                )}
              />
            )}
          />
          <Controller
            control={control}
            name="event.params.giftCard"
            render={({ field, fieldState }) => (
              <NumberInput
                id="gift-card"
                label="Gift Card"
                addon={{ insideEnd: 'days' }}
                value={(field.value as number | undefined) ?? null} // ! EXPLICIT TYPE ASSERTION // TODO resolve and remove
                onChange={(event) => {
                  const value = event.target.valueAsNumber;
                  field.onChange(Number.isNaN(value) ? null : value);
                }}
                error={fieldState.error?.message}
                details={zeroNone(
                  {
                    zero: 'Gift Cards Not Offered',
                    none: 'Using Store Defaults for Gift Cards',
                  },
                  (field.value as number | undefined) ?? null, // ! EXPLICIT TYPE ASSERTION // TODO resolve and remove
                )}
              />
            )}
          />
          <Controller
            control={control}
            name="event.params.exchange"
            render={({ field, fieldState }) => (
              <NumberInput
                id="variant-exchange"
                label="Variant Exchange"
                addon={{ insideEnd: 'days' }}
                value={(field.value as number | undefined) ?? null} // ! EXPLICIT TYPE ASSERTION // TODO resolve and remove
                onChange={(event) => {
                  const value = event.target.valueAsNumber;
                  field.onChange(Number.isNaN(value) ? null : value);
                }}
                error={fieldState.error?.message}
                details={zeroNone(
                  {
                    zero: 'Variant Exchanges Not Offered',
                    none: 'Using Store Defaults for Variant Exchanges',
                  },
                  (field.value as number | undefined) ?? null, // ! EXPLICIT TYPE ASSERTION // TODO resolve and remove
                )}
              />
            )}
          />
          <Controller
            control={control}
            name="event.params.warrantyReview"
            render={({ field, fieldState }) => (
              <NumberInput
                id="warranty-review"
                label="Warranty Review"
                addon={{ insideEnd: 'days' }}
                value={(field.value as number | undefined) ?? null} // ! EXPLICIT TYPE ASSERTION // TODO resolve and remove
                onChange={(event) => {
                  const value = event.target.valueAsNumber;
                  field.onChange(Number.isNaN(value) ? null : value);
                }}
                error={fieldState.error?.message}
                details={zeroNone(
                  {
                    zero: 'Warranty Reviews Not Offered',
                    none: 'Using Store Defaults for Warranty Review',
                  },
                  (field.value as number | undefined) ?? null, // ! EXPLICIT TYPE ASSERTION // TODO resolve and remove
                )}
              />
            )}
          />
        </>
      }
    </>
  );
}

function EventModifyIncentives() {
  const { watch, setValue, getFieldState } =
    useFormContext<StoreRuleFormData>();

  const shouldModifyGiftCardIncentive = watch(
    'event.params.giftCardIncentive.shouldModify',
  );
  const incentiveType = watch('event.params.giftCardIncentive.type');
  const incentiveAmount = watch('event.params.giftCardIncentive.amount');

  return (
    <>
      <SwitchInput
        id="gift-card-incentive-offered"
        label="Modify Store Credit Incentive"
        checked={shouldModifyGiftCardIncentive}
        onChange={(e) => {
          // * handle must resolve values to match the discriminated union with additional defaults
          if (e.target.checked) {
            setValue(
              'event.params.giftCardIncentive',
              {
                shouldModify: true,
                type: CrewIncentiveTypeEnum.fixed,
                amount: 0,
              },
              {
                shouldDirty: true,
                shouldValidate: true,
                shouldTouch: true,
              },
            );
          } else {
            setValue(
              'event.params.giftCardIncentive',
              {
                shouldModify: false,
              },
              {
                shouldDirty: true,
                shouldValidate: true,
                shouldTouch: true,
              },
            );
          }
        }}
      />
      {shouldModifyGiftCardIncentive && (
        <MonetaryRateInput
          id="gift-card-incentive-amount"
          label="Store Credit Incentive Amount"
          details="The amount to offer the customer as store credit incentive."
          required
          value={{
            type:
              incentiveType === CrewIncentiveTypeEnum.none ?
                CrewIncentiveTypeEnum.fixed
              : toEnum(incentiveType, MonetaryType),
            amount: incentiveAmount,
          }}
          onChange={({ type, amount }) => {
            setValue('event.params.giftCardIncentive.amount', amount, {
              shouldDirty: true,
              shouldValidate: true,
              shouldTouch: true,
            });
            setValue('event.params.giftCardIncentive.type', type, {
              shouldDirty: true,
              shouldValidate: true,
              shouldTouch: true,
            });
          }}
          error={
            getFieldState('event.params.giftCardIncentive.amount').error
              ?.message ??
            getFieldState('event.params.giftCardIncentive.type').error?.message
          }
        />
      )}
    </>
  );
}

function EventChargeForReturnLabel() {
  const { watch, setValue, getFieldState } =
    useFormContext<StoreRuleFormData>();

  const shouldApplyLabelMarkup = watch(
    'event.params.applyLabelMarkup.shouldModify',
  );
  const incentiveType = watch('event.params.applyLabelMarkup.type');
  const incentiveAmount = watch('event.params.applyLabelMarkup.amount');

  return (
    <>
      <SwitchInput
        id="gift-card-incentive-offered"
        label="Markup The Cost of the Return Label"
        checked={shouldApplyLabelMarkup}
        onChange={(e) => {
          // * handle must resolve values to match the discriminated union with additional defaults
          if (e.target.checked) {
            setValue(
              'event.params.applyLabelMarkup',
              {
                shouldModify: true,
                type: CrewIncentiveTypeEnum.fixed,
                amount: 0,
              },
              {
                shouldDirty: true,
                shouldValidate: true,
                shouldTouch: true,
              },
            );
          } else {
            setValue(
              'event.params.applyLabelMarkup',
              {
                shouldModify: false,
              },
              {
                shouldDirty: true,
                shouldValidate: true,
                shouldTouch: true,
              },
            );
          }
        }}
      />

      {shouldApplyLabelMarkup && (
        <MonetaryRateInput
          labelVisuallyHidden
          label=""
          id="label-markup-amount"
          details="The amount to markup the cost of the label."
          required
          value={{
            type:
              incentiveType === CrewIncentiveTypeEnum.none ?
                CrewIncentiveTypeEnum.fixed
              : toEnum(incentiveType, MonetaryType),
            amount: incentiveAmount,
          }}
          onChange={({ type, amount }) => {
            setValue('event.params.applyLabelMarkup.amount', amount, {
              shouldDirty: true,
              shouldValidate: true,
              shouldTouch: true,
            });
            setValue('event.params.applyLabelMarkup.type', type, {
              shouldDirty: true,
              shouldValidate: true,
              shouldTouch: true,
            });
          }}
          error={
            getFieldState('event.params.applyLabelMarkup.amount').error
              ?.message ??
            getFieldState('event.params.applyLabelMarkup.type').error?.message
          }
        />
      )}
    </>
  );
}

function EventChargeForExchangeOrderShipping() {
  const { watch, setValue, getFieldState } =
    useFormContext<StoreRuleFormData>();

  const shouldApplyExchangeOrderShippingMarkup = watch(
    'event.params.applyExchangeOrderShippingMarkup.shouldModify',
  );
  const incentiveType = watch(
    'event.params.applyExchangeOrderShippingMarkup.type',
  );
  const incentiveAmount = watch(
    'event.params.applyExchangeOrderShippingMarkup.amount',
  );

  return (
    <>
      <SwitchInput
        id="gift-card-incentive-offered"
        label="Markup The Cost of the Exchange Order Shipping"
        checked={shouldApplyExchangeOrderShippingMarkup}
        onChange={(e) => {
          // * handle must resolve values to match the discriminated union with additional defaults
          if (e.target.checked) {
            setValue(
              'event.params.applyExchangeOrderShippingMarkup',
              {
                shouldModify: true,
                type: CrewIncentiveTypeEnum.fixed,
                amount: 0,
              },
              {
                shouldDirty: true,
                shouldValidate: true,
                shouldTouch: true,
              },
            );
          } else {
            setValue(
              'event.params.applyExchangeOrderShippingMarkup',
              {
                shouldModify: false,
              },
              {
                shouldDirty: true,
                shouldValidate: true,
                shouldTouch: true,
              },
            );
          }
        }}
      />

      {shouldApplyExchangeOrderShippingMarkup && (
        <MonetaryRateInput
          labelVisuallyHidden
          label=""
          id="exchange-order-shipping-markup-amount"
          details="The amount to markup the cost of the label."
          required
          value={{
            type:
              incentiveType === CrewIncentiveTypeEnum.none ?
                CrewIncentiveTypeEnum.fixed
              : toEnum(incentiveType, MonetaryType),
            amount: incentiveAmount,
          }}
          onChange={({ type, amount }) => {
            setValue(
              'event.params.applyExchangeOrderShippingMarkup.amount',
              amount,
              {
                shouldDirty: true,
                shouldValidate: true,
                shouldTouch: true,
              },
            );
            setValue(
              'event.params.applyExchangeOrderShippingMarkup.type',
              type,
              {
                shouldDirty: true,
                shouldValidate: true,
                shouldTouch: true,
              },
            );
          }}
          error={
            getFieldState(
              'event.params.applyExchangeOrderShippingMarkup.amount',
            ).error?.message ??
            getFieldState('event.params.applyExchangeOrderShippingMarkup.type')
              .error?.message
          }
        />
      )}
    </>
  );
}

function EventAllowCustomerToKeepItem() {
  return null;
}

function EventProductRegistrationSelection() {
  const { control } = useFormContext<StoreRuleFormData>();

  const { data: productGroups } = useProductGroups();

  const productGroupOptions =
    productGroups?.length ?
      productGroups.map((pg) => ({
        value: `${pg.id}`,
        label: pg.name,
      }))
    : [];

  return (
    <Controller
      control={control}
      name="event.params.productRegistrationProductGroupId"
      render={({ field: { onChange, value }, fieldState }) => (
        <SimpleSelect
          options={productGroupOptions}
          label="Product Group"
          details="The product group to offer for registration."
          placeholder="Add tags"
          value={`${value}`}
          onChange={onChange}
          error={fieldState.error?.message}
        />
      )}
    />
  );
}

function EventReturnShippingConfig() {
  const { control } = useFormContext<StoreRuleFormData>();

  const { data: returnLocations } = useReturnLocations();

  const returnLocationOptions =
    returnLocations?.length ?
      returnLocations.map((rl) => ({
        value: `${rl.id}`,
        label: rl.name,
      }))
    : [];

  const returnMethodOptions = Object.values(ShipmentMethod).map((type) => ({
    value: type,
    label: shipmentTypeLabels[type],
  }));

  return (
    <>
      <Controller
        control={control}
        name="event.params.returnLocationId"
        render={({ field: { onChange, value }, fieldState }) => (
          <SimpleSelect
            options={returnLocationOptions}
            label="Return Location"
            value={`${value}`}
            onChange={onChange}
            error={fieldState.error?.message}
            details="The location items will be returned to."
          />
        )}
      />

      <Controller
        control={control}
        name="event.params.returnMethods"
        render={({ field, fieldState }) => (
          <MultiSelect
            label="Return Methods"
            details="Methods to offer customers for returning items."
            placeholder="Select Methods"
            options={returnMethodOptions}
            value={field.value.map((value) => ({
              label: shipmentTypeLabels[value],
              value,
            }))}
            onChange={(selected) =>
              field.onChange(selected.map(({ value }) => value))
            }
            error={fieldState.error?.message}
          />
        )}
      />
    </>
  );
}

function EventApplyFee() {
  const { control, watch } = useFormContext<StoreRuleFormData>();

  const {
    storeUser: {
      store: { currencyCode, currencySymbol },
    },
  } = useMerchantContext();

  const feeAmount = watch('event.params.feeAmount');

  return (
    <>
      <Controller
        control={control}
        name="event.params.feeAmount"
        render={({ field, fieldState }) => (
          <NumberInput
            id="fee-amount"
            label="Amount"
            step="0.01"
            details={
              feeAmount === 0 ?
                'Fee will be waived.'
              : 'The amount to charge the customer.'
            }
            addon={{
              insideStart: currencySymbol,
              insideEnd: currencyCode,
            }}
            required
            value={field.value}
            onChange={(e) => field.onChange(e.target.valueAsNumber)}
            error={fieldState.error?.message}
          />
        )}
      />

      {feeAmount > 0 && (
        <Controller
          control={control}
          name="event.params.feeDisplayName"
          render={({ field, fieldState }) => (
            <TextInput
              id="fee-display-name"
              label="Display Name"
              details="The name of the fee, this will be shown to the customer."
              required
              {...field}
              error={fieldState.error?.message}
            />
          )}
        />
      )}
    </>
  );
}

function EventAutoFinalizeClaim() {
  return (
    <Alert
      variant="DEFAULT"
      message="The resolution methods selection on this rule has been moved to the Conditions section of the Automation. When omitting a resolution method, remember to utilize a Not Any Of condition."
    />
  );
}

function EventAskAPolicyEnforcingQuestion() {
  const { control } = useFormContext<StoreRuleFormData>();
  return (
    <>
      <Controller
        control={control}
        name="event.params.question"
        render={({ field, fieldState }) => (
          <TextInput
            id="question"
            label="Question"
            details="Ask a yes/no question to enforce requirements for your return policy."
            required
            {...field}
            error={fieldState.error?.message}
          />
        )}
      />
      <Controller
        control={control}
        name="event.params.onYesMessage"
        render={({ field, fieldState }) => (
          <TextInput
            id="on-yes-message"
            label="Yes Message"
            details="Message to display when the customer answers yes, making the line item final sale and ineligible for return."
            {...field}
            error={fieldState.error?.message}
          />
        )}
      />
    </>
  );
}

function EventOfferInstantExchanges() {
  const { control } = useFormContext<StoreRuleFormData>();
  return (
    <Controller
      control={control}
      name="event.params.timeToReturnBeforeCharging"
      render={({ field, fieldState }) => (
        <NumberInput
          id="number-of-days"
          label="Number of days"
          details={
            <div>
              <p>
                Number of days the customer has to return the original item
                before being charged. If you would like to increase this time,
                please reach out.
              </p>
            </div>
          }
          {...field}
          addon={{ insideEnd: '' }}
          error={fieldState.error?.message}
          disabled
        />
      )}
    />
  );
}

function EventAllowClaimTypeSelection() {
  const { control } = useFormContext<StoreRuleFormData>();
  return (
    <Controller
      control={control}
      name="event.params.claimTypeSelectionDetailText"
      render={({ field, fieldState }) => (
        <TextInput
          id="claim-type-selection-detail-text"
          label="Detail Text"
          details="The text to display to give the customer additional information when deciding between a Return or Warranty."
          placeholder='e.g. "Please select select the type of request, which best fits your needs."'
          required
          {...field}
          error={fieldState.error?.message}
        />
      )}
    />
  );
}

function EventCollectCustomFields() {
  const { control, watch, setValue } = useFormContext<StoreRuleFormData>();

  const { data: customFieldOptions = [] } = useCustomFields();
  const isMediaUploadRequired = watch('event.params.isMediaUploadRequired');
  const showDefaultCommentField = watch('event.params.showDefaultCommentField');

  return (
    <>
      <Controller
        control={control}
        name="event.params.customFieldIds"
        render={({ field, fieldState }) => (
          <MultiSelect
            label="Fields to Collect"
            details="Select the fields to collect from the customer, fields can be added from the Custom Fields section in the Settings."
            placeholder="Select Fields to Collect"
            options={customFieldOptions.map((customField) => ({
              label: customField.displayName,
              value: `${customField.id}`,
            }))}
            value={field.value.map((value) => ({
              label: customFieldOptions.find(
                (customField) => customField.id === value,
              )?.displayName,
              value: `${value}`,
            }))}
            onChange={(selections) =>
              field.onChange(
                selections.map(({ value }) => Number.parseInt(value, 10)),
              )
            }
            error={fieldState.error?.message}
          />
        )}
      />

      <Controller
        control={control}
        name="event.params.showDefaultCommentField"
        render={({ field: { onChange, value } }) => (
          <SwitchInput
            id="show-default-comment-field"
            label="Show Default Comment Field"
            details="Show the default comment field, in addition to the selected custom fields."
            checked={value}
            onChange={onChange}
          />
        )}
      />

      <Controller
        control={control}
        name="event.params.isDefaultCommentFieldRequired"
        render={({ field: { onChange, value } }) => (
          <SwitchInput
            id="show-default-comment-field"
            label="Require Default Comment Field"
            details="Require the customer to provide a default comment when submitting a claim."
            checked={value}
            hidden={!showDefaultCommentField}
            onChange={onChange}
          />
        )}
      />

      <Controller
        control={control}
        name="event.params.isMediaUploadRequired"
        render={({ field: { onChange, value } }) => (
          <SwitchInput
            id="is-media-upload-required"
            label="Require Media Uploads"
            details="Require the customer to upload media files when submitting a claim."
            checked={value}
            onChange={(e) => {
              // clear out the media upload instructions and minimum media upload amount when media upload is disabled
              if (!e.target.checked) {
                setValue('event.params.mediaUploadInstructions', undefined);
                setValue('event.params.minimumMediaUploadAmount', undefined);
              }
              onChange(e.target.checked);
            }}
          />
        )}
      />

      <Controller
        control={control}
        name="event.params.mediaUploadInstructions"
        render={({ field, fieldState }) => (
          <TextInput
            id="media-upload-instructions"
            label="Media Upload Instructions"
            details="Instructions to display to the customer when uploading media, such as images or videos. "
            {...field}
            hidden={!isMediaUploadRequired}
            value={field.value}
            error={fieldState.error?.message}
          />
        )}
      />

      <Controller
        control={control}
        name="event.params.minimumMediaUploadAmount"
        render={({ field, fieldState }) => (
          <NumberInput
            id="minimum-media-upload-amount"
            label="Minimum Media Files Required"
            details="Minimum number of media files required to be uploaded by the customer."
            value={field.value}
            onChange={(e) => field.onChange(e.target.valueAsNumber)}
            hidden={!isMediaUploadRequired}
            error={fieldState.error?.message}
          />
        )}
      />
    </>
  );
}

function EventApplyClaimTags() {
  const { control } = useFormContext<StoreRuleFormData>();
  const { data: tagOptions } = useClaimTagOptions();

  return (
    <Controller
      control={control}
      name="event.params.claimTags"
      render={({ field, fieldState }) => (
        <MultiSelect
          label="Claim Tags"
          options={(tagOptions ?? []).map((tag) => ({
            label: tag,
            value: tag,
          }))}
          details="The tags to apply to the claim."
          placeholder="Add tags"
          value={field.value.map(({ name }) => ({ label: name, value: name }))}
          onChange={(selections) =>
            field.onChange(selections.map(({ value }) => ({ name: value })))
          }
          error={fieldState.error?.message}
          creatable
        />
      )}
    />
  );
}

function EventApplyOrderTags() {
  const { control, setValue, watch } = useFormContext<StoreRuleFormData>();
  const { data: factValues } = useFactValues();

  const tagOptions = factValues?.arbitrary['order/tags'];
  const selectedHook = watch('hook');
  const selectedAction = watch('event.params.orderTagAction');

  const orderTagActions =
    selectedHook === 'Claim_Created' ?
      (['Original Order'] as const)
    : (['Original Order', 'Replacement Order'] as const);

  if (
    selectedAction === 'Replacement Order' &&
    selectedHook === 'Claim_Created'
  ) {
    setValue('event.params.orderTagAction', 'Original Order');
  }

  return (
    <>
      <Controller
        control={control}
        name="event.params.orderTagAction"
        render={({ field, fieldState }) => (
          <SimpleSelect
            label="Which order would you like to tag?"
            details={
              selectedAction === 'Original Order' ?
                'The original order that the claim was created from will be tagged.'
              : 'The replacement order that was created to resolve the claim will be tagged.'
            }
            placeholder="Select Order"
            options={orderTagActions.map((action) => ({
              value: action,
              label: action,
            }))}
            onChange={field.onChange}
            value={field.value}
            error={fieldState.error?.message}
          />
        )}
      />

      <Controller
        control={control}
        name="event.params.orderTags"
        render={({ field, fieldState }) => (
          <MultiSelect
            label="Order Tags"
            options={(tagOptions ?? []).map((tag) => ({
              label: tag,
              value: tag,
            }))}
            details="The tags to apply."
            placeholder="Add tags"
            value={field.value.map(({ name }) => ({
              label: name,
              value: name,
            }))}
            onChange={(selections) =>
              field.onChange(selections.map(({ value }) => ({ name: value })))
            }
            error={fieldState.error?.message}
            creatable
          />
        )}
      />
    </>
  );
}

function EventAutoFinalizeClaimSettings() {
  const { register, control } = useFormContext<StoreRuleFormData>();

  return (
    <>
      <Controller
        control={control}
        name="event.params.failOnOutOfStockScenarios"
        defaultValue={false} // prevent uncontrolled input warning
        render={({ field: { onChange, value }, fieldState }) => (
          <SwitchInput
            id="fail-on-out-of-stock-scenarios"
            label="Fail on Out of Stock Scenarios"
            details="Fail to finalize the claim if the item is out of stock."
            checked={value}
            onChange={(event) => {
              onChange(event);
            }}
            error={fieldState.error?.message}
          />
        )}
      />

      <EmailInput
        multiple
        id="failure-to-finalize-notification-emails"
        label="Failure to Finalize Notification Emails"
        {...register('event.params.failureToFinalizeNotificationEmails')}
      />
    </>
  );
}

const eventParamsFields = {
  [Pegasus.EventType.modifyResolutionWindow]: EventModifyResolutionWindow,
  [Pegasus.EventType.autoFinalizeClaim]: EventAutoFinalizeClaim,
  [Pegasus.EventType.askAPolicyEnforcingQuestion]:
    EventAskAPolicyEnforcingQuestion,
  [Pegasus.EventType.offerInstantExchange]: EventOfferInstantExchanges,
  [Pegasus.EventType.allowClaimTypeSelection]: EventAllowClaimTypeSelection,
  [Pegasus.EventType.collectCustomFields]: EventCollectCustomFields,
  [Pegasus.EventType.applyClaimTags]: EventApplyClaimTags,
  [Pegasus.EventType.customizeClaimFinalizationSettings]:
    EventAutoFinalizeClaimSettings,
  [Pegasus.EventType.modifyIncentives]: EventModifyIncentives,
  [Pegasus.EventType.applyFee]: EventApplyFee,
  [Pegasus.EventType.chargeForReturnLabel]: EventChargeForReturnLabel,
  [Pegasus.EventType.chargeForExchangeOrderShipping]:
    EventChargeForExchangeOrderShipping,
  [Pegasus.EventType.allowCustomerToKeepItem]: EventAllowCustomerToKeepItem,
  [Pegasus.EventType.productRegistrationSelection]:
    EventProductRegistrationSelection,
  [Pegasus.EventType.returnShippingConfig]: EventReturnShippingConfig,
  [Pegasus.EventType.applyOrderTags]: EventApplyOrderTags,
} as const satisfies Record<Pegasus.EventType, unknown>;

const eventDefaultParams = {
  [Pegasus.EventType.productRegistrationSelection]: () => ({
    productRegistrationProductGroupId: 0,
  }),
  [Pegasus.EventType.modifyResolutionWindow]: () => ({
    kind: 'eligibilityDays',
    refund: 0,
    giftCard: 0,
    exchange: 0,
    warrantyReview: 0,
  }),
  [Pegasus.EventType.autoFinalizeClaim]: () => ({
    allowedResolutionMethods: [
      CrewClaimResolutionMethodEnum.refund,
      CrewClaimResolutionMethodEnum.giftCard,
      CrewClaimResolutionMethodEnum.variantExchange,
    ],
  }),
  [Pegasus.EventType.askAPolicyEnforcingQuestion]: () => ({
    question: '',
    no: null,
    yes: 'markAsIneligible',
  }),
  [Pegasus.EventType.offerInstantExchange]: () => ({
    timeToReturnBeforeCharging: 7,
  }),
  [Pegasus.EventType.allowClaimTypeSelection]: () => ({
    claimTypeSelectionDetailText: '',
  }),
  [Pegasus.EventType.collectCustomFields]: () => ({
    showDefaultCommentField: true,
    isMediaUploadRequired: false,
    mediaUploadInstructions: '',
    minimumMediaUploadAmount: 1,
    isDefaultCommentFieldRequired: false,
    customFieldIds: [],
  }),
  [Pegasus.EventType.applyClaimTags]: () => ({
    claimTags: [],
  }),
  [Pegasus.EventType.applyOrderTags]: () => ({
    orderTags: [],
    orderTagAction: 'Original Order',
  }),
  [Pegasus.EventType.customizeClaimFinalizationSettings]: () => ({
    restockToShopify: false,
    failOnOutOfStockScenarios: false,
    failureToFinalizeNotificationEmails: '',
  }),
  [Pegasus.EventType.modifyIncentives]: () => ({
    giftCardIncentive: { shouldModify: false },
    shouldApplyVariantExchangeShipping: false,
  }),
  [Pegasus.EventType.applyFee]: () => ({
    feeAmount: 0,
    feeDisplayName: 'Handling Fee',
  }),
  [Pegasus.EventType.chargeForReturnLabel]: () => ({
    applyLabelMarkup: { shouldModify: false },
  }),
  [Pegasus.EventType.chargeForExchangeOrderShipping]: () => ({
    applyExchangeOrderShippingMarkup: { shouldModify: false },
  }),
  [Pegasus.EventType.allowCustomerToKeepItem]: () => ({}),
  [Pegasus.EventType.returnShippingConfig]: () => ({
    returnLocationId: 0,
    returnMethods: [],
  }),
} as const satisfies {
  [E in Pegasus.EventType]: () => Pegasus.EventParams<E>;
};

/** Used as the default for nearly all Hooks, to work with all inputs being controlled. */
const defaultCustomerTagExpression = () =>
  ({
    fact: Pegasus.Fact.customerTags,
    operator: Pegasus.Operator.in,
    value: [],
  }) as const satisfies Pegasus.Expression<Pegasus.Fact.customerTags>;

/** Prefer to have a fact with an empty array for the value, just for simplicity and consistency with the underlying controlled inputs. */
const defaultExpressionForHook = {
  [Pegasus.Hook.registrationProductSelection]: () => ({
    fact: Pegasus.Fact.registrationChannel,
    operator: Pegasus.Operator.in,
    value: [],
  }),
  [Pegasus.Hook.orderLookup]: defaultCustomerTagExpression,
  [Pegasus.Hook.claimCreated]: defaultCustomerTagExpression,
  [Pegasus.Hook.afterReasonSelection]: defaultCustomerTagExpression,
  [Pegasus.Hook.beforeClaimSubmission]: defaultCustomerTagExpression,
  [Pegasus.Hook.rmaWebhook]: defaultCustomerTagExpression,
  [Pegasus.Hook.returnShipmentWebhook]: defaultCustomerTagExpression,
  [Pegasus.Hook.claimFinalized]: defaultCustomerTagExpression,
  [Pegasus.Hook.returnShipmentCreated]: defaultCustomerTagExpression,
  [Pegasus.Hook.returnShipmentQuote]: defaultCustomerTagExpression,
} as const satisfies {
  [H in Pegasus.Hook]: () => Pegasus.Expression<
    keyof Pegasus.FactDataForHook<H>
  >;
};

function ConditionValueField({
  i,
  condition,
}: {
  i: number;
  condition: Pegasus.Expression;
}) {
  const { control } = useFormContext<StoreRuleFormData>();
  const { isPending, data: factValues, error } = useFactValues();

  if (isPending) {
    return <Skeleton.Rectangle height="2rem" width="8rem" />;
  }

  if (error) {
    return <Alert variant="danger" message={error.message} />;
  }

  return (
    <Controller
      control={control}
      name={`conditions.${i}.value`}
      render={({ field, fieldState }) => {
        const fact = toEnum(condition.fact, Pegasus.Fact);
        const valueType = Pegasus.factValueType[fact];
        if (!Pegasus.isArrayValueType(valueType)) {
          // ! EXPLICIT TYPE ASSERTION // TODO resolve
          const ValueInput = singleValueTypeInput[valueType] as FC<
            ComponentProps<(typeof singleValueTypeInput)[typeof valueType]>
          >;

          return (
            <ValueInput
              id={`expressions.${i}.value`}
              fact={fact}
              // ! EXPLICIT TYPE ASSERTION // TODO figure out alternative so that we can remove `as never` assertions
              value={field.value as never}
              onChange={field.onChange}
              error={fieldState.error?.message}
            />
          );
        }

        if (!Pegasus.isArrayFact(fact)) {
          console.error('Unexpected Non-Array Fact', { fact });
          return <Badge variant="danger">Unexpected Fact: {fact}</Badge>;
        }

        if (
          Pegasus.isArbitraryFact(fact) &&
          Pegasus.isArbitraryValueType(valueType)
        ) {
          const ValueInput = arbitraryValueTypeInput[valueType];
          const options = factValues[Pegasus.ArrayFactType.arbitrary][fact];
          return (
            <ValueInput
              id={`expressions.${i}.value`}
              fact={fact}
              // ! EXPLICIT TYPE ASSERTION // TODO figure out alternative so that we can remove `as never` assertions
              value={field.value as never}
              onChange={field.onChange}
              error={fieldState.error?.message}
              options={options}
            />
          );
        }
        if (
          Pegasus.isDefiniteFact(fact) &&
          Pegasus.isDefiniteValueType(valueType)
        ) {
          const ValueInput = definiteValueTypeInput[valueType];
          const options = factValues[Pegasus.ArrayFactType.definite][fact];
          return (
            <ValueInput
              id={`expressions.${i}.value`}
              fact={fact}
              // ! EXPLICIT TYPE ASSERTION // TODO figure out alternative so that we can remove `as never` assertions
              value={field.value as never}
              onChange={field.onChange}
              error={fieldState.error?.message}
              // ! EXPLICIT TYPE ASSERTION // TODO figure out alternative so that we can remove `as never` assertions
              options={options as never}
            />
          );
        }

        console.error('Unhandled Fact', { fact, valueType });
        return <Badge variant="danger">Unhandled Fact: {fact}</Badge>;
      }}
    />
  );
}

function StoreRuleConditionsFields() {
  const { control, watch, setValue } = useFormContext<StoreRuleFormData>();

  const conditionsFieldArray = useFieldArray({ control, name: 'conditions' });
  const conditionsWatch = watch('conditions');

  // refer to controlled field array: https://react-hook-form.com/docs/usefieldarray#:~:text=Controlled%20Field%20Array
  const conditions = conditionsFieldArray.fields.map((field, i) => ({
    type: 'all' as const,
    ...field,
    ...conditionsWatch[i],
  }));

  const selectedHook = watch('hook');
  const booleanExpression = watch('booleanExpression');

  return (
    <>
      <Controller
        control={control}
        name="booleanExpression"
        render={({ field, fieldState }) => (
          <RadioGroupInput
            label="Matching Conditions"
            details="Determines if all or any of the conditions must be met for the rule to be triggered."
            id="boolean-expression"
            options={matchCondition}
            value={field.value}
            onChange={field.onChange}
            error={fieldState.error?.message}
          />
        )}
      />
      {/* unconditional rule */}
      {!conditions.length && (
        <Alert
          variant="info"
          message="Without conditions, this rule will always apply."
        />
      )}
      <ol>
        {conditions.map((condition, i) => (
          // TODO fix change of fact, operator reset isn't working as expected
          // ? possibly extract to separate component; however, that would necessitate a form context
          <li key={condition.id} className="flex flex-col">
            <Fieldset legend={`Condition ${i + 1}`}>
              <div className="flex flex-col flex-wrap justify-between gap-2 md:flex-row md:items-center">
                <div className="flex flex-grow flex-col flex-wrap gap-2 md:flex-row md:items-center">
                  <Controller
                    control={control}
                    name={`conditions.${i}.fact`}
                    render={({ field }) => (
                      <SimpleSelect
                        label="Fact"
                        labelVisuallyHidden
                        options={Pegasus.factsForHook[selectedHook]
                          .map((fact) => ({
                            value: fact,
                            label: displayName.fact[fact],
                          }))
                          .sort((a, b) => a.label.localeCompare(b.label))}
                        value={field.value}
                        onChange={(fact) => {
                          setValue(
                            `conditions.${i}.operator`,
                            Pegasus.operatorForFact(fact)[0],
                          );
                          const newValueType = Pegasus.factValueType[fact];
                          setValue(
                            `conditions.${i}.value`,
                            valueTypeDefault[newValueType](),
                          );
                          field.onChange(fact);
                        }}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name={`conditions.${i}.operator`}
                    render={({ field }) => (
                      <SimpleSelect
                        label="Operator"
                        labelVisuallyHidden
                        options={Pegasus.operatorForFact(condition.fact)
                          .map((operator) => {
                            const valueType =
                              Pegasus.factValueType[condition.fact];
                            return {
                              value: operator,
                              label:
                                valueTypeOperator[valueType]?.[operator] ??
                                displayName.operator[operator],
                            };
                          })
                          .sort((a, b) => a.label.localeCompare(b.label))}
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                  <ConditionValueField i={i} condition={condition} />
                </div>
                <IconAction.Button
                  className="ml-auto"
                  title={`Remove Condition ${i + 1}`}
                  icon={TrashIcon}
                  iconSize="sm"
                  onClick={() => conditionsFieldArray.remove(i)}
                />
              </div>
            </Fieldset>
            {conditions.length && i < conditions.length - 1 && (
              <div className="flex flex-col items-center self-start pl-4">
                <div className="h-1.5 w-px bg-corso-gray-200" />
                <p className="text-sm font-semibold">
                  {booleanExpressionBadge[booleanExpression]}
                </p>
                <div className="h-1.5 w-px bg-corso-gray-200" />
              </div>
            )}
          </li>
        ))}
      </ol>
      <Button
        disabled={!selectedHook} // there should always be a hook defined, but disabled if no hook is selected; otherwise, this could cause issues
        size="intrinsic"
        onClick={() =>
          conditionsFieldArray.append(defaultExpressionForHook[selectedHook]())
        }
      >
        <PlusIcon className="h-5 w-5" aria-hidden="true" />
        Add Condition
      </Button>
    </>
  );
}

function StoreRuleOverviewFields({
  hideEventTypeField = false,
}: {
  hideEventTypeField?: boolean;
}) {
  const {
    control,
    register,
    formState: { errors },
    setValue,
  } = useFormContext<StoreRuleFormData>();

  const isTest = useIsTest();

  return (
    <>
      {!hideEventTypeField && (
        <Controller
          control={control}
          name="event.type"
          render={({ field }) => (
            <SimpleSelect
              label="What would you like to do?"
              options={Object.values(Pegasus.EventType)
                .filter(
                  (eventType) =>
                    isTest ||
                    !Pegasus.eventsAvailableForTestOnly.includes(eventType),
                )
                .sort((a, b) =>
                  displayName.eventType[a].localeCompare(
                    displayName.eventType[b],
                  ),
                )
                .map((eventType) => ({
                  label: (
                    <span className="text-left">
                      <p>{displayName.eventType[eventType]}</p>
                      <p className="text-xs">
                        {eventTypeDescription[eventType]}
                      </p>
                    </span>
                  ),
                  value: eventType,
                }))}
              placeholder="Select an Action"
              value={field.value}
              onChange={(value) => {
                field.onChange(value);
                setValue('hook', Pegasus.hooksForEvent[value][0]); // reset whatever was selected to the first valid hook for the event
                setValue('event.params', eventDefaultParams[value]()); // reset event params
                setValue('conditions', []); // reset conditions // ? could potentially filer or mark invalid rules
              }}
            />
          )}
        />
      )}

      <TextInput
        id="name"
        label="Name"
        details="A short name or phrase describing the rule."
        required
        {...register('name')}
        error={errors.name?.message}
      />

      <Controller
        control={control}
        name="isEnabled"
        render={({ field }) => (
          <SwitchInput
            id="is-enabled"
            label="Enabled"
            details="Enable or disable the rule."
            checked={field.value}
            onChange={field.onChange}
          />
        )}
      />
    </>
  );
}

function StoreRuleEventDetailFields() {
  const {
    watch,
    control,
    setValue,
    register,
    formState: { errors },
  } = useFormContext<StoreRuleFormData>();
  const selectedEventType = watch('event.type');
  const EventParameterFields = eventParamsFields[selectedEventType];

  const availableHookOptions = Pegasus.hooksForEvent[selectedEventType];

  return (
    <>
      <Fieldset legend={`${displayName.eventType[selectedEventType]} Config`}>
        <div className="flex flex-col gap-4">
          <NumberInput
            id="priority"
            label="Priority"
            details="The order in which the rule is applied in relation to others. Higher numbers are applied first."
            required
            {...register('priority', { valueAsNumber: true })}
            error={errors.priority?.message}
          />
          <EventParameterFields />
        </div>
      </Fieldset>
      {availableHookOptions.length > 1 && (
        <Controller
          // * key used to help reset the control when the event type changes, since the internal state was not updating between changes for the event type
          key={`hook-${selectedEventType}`}
          control={control}
          name="hook"
          render={({ field }) => (
            <SimpleSelect
              label="When should this rule apply?"
              options={availableHookOptions.map((hook) => ({
                label: displayName.hook[hook],
                value: hook,
              }))}
              placeholder="Select a Hook"
              value={field.value}
              onChange={(value) => {
                field.onChange(value);
                setValue('conditions', []); // reset conditions // ? could potentially filer or mark invalid rules
              }}
            />
          )}
        />
      )}
    </>
  );
}

const storeRuleFormDataSchema = storeRuleSchema
  .pick({
    name: true,
    hook: true,
    isEnabled: true,
  })
  .merge(storeRuleSchema.shape.rule.pick({ priority: true, event: true }))
  .merge(
    z.object({
      booleanExpression: z.union([z.literal('all'), z.literal('any')]),
      conditions: z.union([
        storeRuleSchema.shape.rule.shape.conditions.options[0].shape.all,
        storeRuleSchema.shape.rule.shape.conditions.options[1].shape.any,
      ]),
    }),
  );

type StoreRuleFormData = z.infer<typeof storeRuleFormDataSchema>;

function storeRuleToStoreRuleFormData(storeRule: StoreRule) {
  return {
    name: storeRule.name,
    hook: storeRule.hook,
    priority: storeRule.rule.priority,
    isEnabled: storeRule.isEnabled,
    event: {
      ...storeRule.rule.event,
      storeRuleId: storeRule.id, // * add `storeRuleId` explicitly; adds for pre-existing rules before it existed or any moving forward
    },
    booleanExpression: 'all' in storeRule.rule.conditions ? 'all' : 'any',
    conditions:
      'all' in storeRule.rule.conditions ?
        storeRule.rule.conditions.all
      : storeRule.rule.conditions.any,
  } satisfies StoreRuleFormData;
}

function storeRuleTemplateToStoreRuleFormData({
  storeRule,
}: StoreRuleTemplate) {
  return {
    name: storeRule.name,
    hook: storeRule.hook,
    priority: storeRule.rule.priority,
    isEnabled: storeRule.isEnabled,
    event: storeRule.rule.event,
    booleanExpression: 'all' in storeRule.rule.conditions ? 'all' : 'any',
    conditions:
      'all' in storeRule.rule.conditions ?
        storeRule.rule.conditions.all
      : storeRule.rule.conditions.any,
  } satisfies StoreRuleFormData;
}

/** Contains defaults for non-field associated value. */
function toStoreRuleUpsert(
  storeRuleFormData: StoreRuleFormData,
  originalStoreRule?: StoreRule | StoreRuleCreate,
) {
  return {
    ...originalStoreRule, // populate `id`, without explicitly creating the key that would then be explicitly `undefined`
    name: storeRuleFormData.name,
    isEnabled: storeRuleFormData.isEnabled,
    hook: storeRuleFormData.hook,
    rule: {
      priority: storeRuleFormData.priority,
      event: storeRuleFormData.event,
      conditions: {
        ...(storeRuleFormData.booleanExpression === 'all' ?
          { all: storeRuleFormData.conditions }
        : { any: storeRuleFormData.conditions }),
      },
    },
  } satisfies StoreRuleCreate | StoreRuleUpdate;
}

function StoreRuleExpressionValueDisplay({
  expression,
}: {
  expression: Pegasus.Expression;
}) {
  const { isPending, data: factValues, error } = useFactValues();

  if (isPending) {
    return <Skeleton.Rectangle height="1.5rem" width="6rem" />;
  }

  if (error) {
    return <Alert variant="danger" message={error.message} />;
  }

  const valueType = Pegasus.factValueType[expression.fact];
  if (!Pegasus.isArrayValueType(valueType)) {
    return (
      // ! EXPLICIT TYPE ASSERTION // TODO figure out alternative so that we can remove `as never` assertions
      <>{singleValueTypeFormatter[valueType](expression.value as never)}</>
    );
  }

  const fact = toEnum(expression.fact, Pegasus.Fact);

  if (
    Pegasus.isArbitraryFact(fact) &&
    Pegasus.isArbitraryValueType(valueType)
  ) {
    return (
      // ! EXPLICIT TYPE ASSERTION // TODO figure out alternative so that we can remove `as never` assertions
      <>{arbitraryValueTypeFormatter[valueType](expression.value as never)}</>
    );
  }

  if (Pegasus.isDefiniteFact(fact) && Pegasus.isDefiniteValueType(valueType)) {
    return (
      <>
        {definiteValueTypeFormatter[valueType](
          // ! EXPLICIT TYPE ASSERTION // TODO figure out alternative so that we can remove `as never` assertions
          expression.value as never,
          // ! EXPLICIT TYPE ASSERTION // TODO figure out alternative so that we can remove `as never` assertions
          factValues.definite[fact] as never,
        )}
      </>
    );
  }

  console.error('Unhandled Fact', { fact, valueType });
  return <Badge variant="danger">Unhandled Fact: {fact}</Badge>;
}

// designed to be similar to the `StoreRuleExpressionForm`
export function StoreRuleExpressionDisplay({
  rule,
}: {
  rule: StoreRule['rule'];
}) {
  const booleanExpression = 'all' in rule.conditions ? 'all' : 'any';
  const expressions =
    'all' in rule.conditions ? rule.conditions.all : rule.conditions.any;

  if (!expressions.length) {
    return (
      <Alert variant="info" message="This rule always applies when enabled." />
    );
  }

  return (
    <div className="space-y-1">
      <p>{matchCondition[booleanExpression]}:</p>

      <ol>
        {expressions.map((expression, i) => {
          const valueType = Pegasus.factValueType[expression.fact];
          return (
            <li
              /* eslint-disable-next-line react/no-array-index-key */ // TODO find a better key
              key={i}
              className="flex flex-col"
            >
              <div className="flex max-w-max flex-wrap items-center gap-1.5 rounded-md border p-2">
                <Badge>{displayName.fact[expression.fact]}</Badge>
                <Badge>
                  {valueTypeOperator[valueType]?.[expression.operator] ??
                    displayName.operator[expression.operator]}
                </Badge>
                <StoreRuleExpressionValueDisplay expression={expression} />
              </div>
              {expressions.length && i < expressions.length - 1 && (
                // ? refactor to separate component
                <div className="flex flex-col items-center self-start">
                  <div className="h-1.5 w-px bg-corso-gray-200" />
                  <p className="text-sm font-semibold">
                    {booleanExpressionBadge[booleanExpression]}
                  </p>
                  <div className="h-1.5 w-px bg-corso-gray-200" />
                </div>
              )}
            </li>
          );
        })}
      </ol>
    </div>
  );
}

export const eventDisplay = {
  [Pegasus.EventType.modifyResolutionWindow]: ({
    kind,
    exchange,
    giftCard,
    refund,
    warrantyReview,
  }) =>
    kind === 'eligibilityDays' ?
      <div className="space-y-1">
        <p>Eligibility Days</p>
        {isNonNullable(refund) && <p>Refund within {refund} days</p>}
        {isNonNullable(giftCard) && <p>Gift Card within {giftCard} days</p>}
        {isNonNullable(exchange) && <p>Exchange within {exchange} days</p>}
        {isNonNullable(warrantyReview) && (
          <p>Warranty Review within {warrantyReview} days</p>
        )}
      </div>
    : <div className="space-y-1">
        <p>Resolution Methods</p>
        {refund && <p>Refund until {formatter.date(refund)}</p>}
        {giftCard && <p>Gift Card until {formatter.date(giftCard)}</p>}
        {exchange && <p>Exchange until {formatter.date(exchange)}</p>}
        {warrantyReview && (
          <p>Warranty Review until: {formatter.date(warrantyReview)}</p>
        )}
      </div>,
  [Pegasus.EventType.autoFinalizeClaim]: () => (
    <p>Claims will be auto-finalized based on configured conditions. </p>
  ),
  [Pegasus.EventType.collectCustomFields]: ({
    customFieldIds,
    isMediaUploadRequired,
    mediaUploadInstructions,
    minimumMediaUploadAmount,
    showDefaultCommentField,
    isDefaultCommentFieldRequired,
  }) => {
    const { data: customFields } = useCustomFields();

    return (
      <div className="space-y-1">
        <p>
          Fields to Collect:{' '}
          {customFieldIds
            .map(
              (id) =>
                customFields?.find((field) => field.id === id)?.displayName ??
                '',
            )
            .join(', ')}
        </p>
        {showDefaultCommentField && <p>Show Default Comment Field</p>}
        {isDefaultCommentFieldRequired && <p>Default Comment Field Required</p>}
        {isMediaUploadRequired && (
          <p>
            Require Media Uploads: {minimumMediaUploadAmount}{' '}
            {minimumMediaUploadAmount === 1 ? 'file' : 'files'}
          </p>
        )}
        {mediaUploadInstructions && (
          <p>Media Upload Instructions: {mediaUploadInstructions}</p>
        )}
      </div>
    );
  },
  [Pegasus.EventType.offerInstantExchange]: ({
    timeToReturnBeforeCharging,
  }) => (
    <p>
      Customers who select instant exchange will have{' '}
      {timeToReturnBeforeCharging} days to return before being charged. The
      exchange order will be processed at the time of the return.
    </p>
  ),
  [Pegasus.EventType.askAPolicyEnforcingQuestion]: ({
    question,
    onYesMessage,
  }) => (
    <div className="space-y-1">
      <p>Question: {question}</p>
      {onYesMessage && <p>On Yes Message: {onYesMessage}</p>}
    </div>
  ),
  [Pegasus.EventType.allowClaimTypeSelection]: ({
    claimTypeSelectionDetailText,
  }) => (
    <div className="space-y-1">
      <p>Detail Text: {claimTypeSelectionDetailText}</p>
    </div>
  ),
  [Pegasus.EventType.applyClaimTags]: ({ claimTags }) => (
    <p>Claim Tags: {claimTags.map((v) => v.name).join(', ')}</p>
  ),
  [Pegasus.EventType.applyOrderTags]: ({ orderTags, orderTagAction }) => (
    <>
      <p>Order Tags: {orderTags.map((v) => v.name).join(', ')}</p>
      <p>Order To Tag: {orderTagAction}</p>
    </>
  ),
  [Pegasus.EventType.customizeClaimFinalizationSettings]: ({
    failOnOutOfStockScenarios,

    failureToFinalizeNotificationEmails,
  }) => (
    <>
      <p> Fail on Out of Stock: {failOnOutOfStockScenarios ? 'Yes' : 'No'}</p>
      <p>
        Failure To Finalize Notification Emails:{' '}
        {failureToFinalizeNotificationEmails}
      </p>
    </>
  ),

  [Pegasus.EventType.modifyIncentives]: ({ giftCardIncentive }) => {
    const {
      storeUser: {
        store: { currencyCode },
      },
    } = useMerchantContext();

    return (
      <p>
        Gift Card Incentive Amount:{' '}
        {
          // TODO resolve nested ternaries
          /* eslint-disable no-nested-ternary */
          !giftCardIncentive.shouldModify ?
            'Using Store Defaults'
          : giftCardIncentive.type === CrewIncentiveTypeEnum.fixed ?
            formatter.currency(giftCardIncentive.amount, currencyCode)
          : giftCardIncentive.type === CrewIncentiveTypeEnum.percent ?
            formatter.percent(giftCardIncentive.amount / 100) // * stored value isn't between 0 and 1
          : 'None'
          /* eslint-enable no-nested-ternary */
        }
      </p>
    );
  },
  [Pegasus.EventType.applyFee]: ({ feeAmount, feeDisplayName }) => {
    const {
      storeUser: {
        store: { currencyCode },
      },
    } = useMerchantContext();

    return (
      <>
        <p>
          {feeAmount > 0 ?
            `Amount: ${formatter.currency(feeAmount, currencyCode)}`
          : 'Fees are not applied'}
        </p>
        <p>{feeAmount > 0 && `Display Name: ${feeDisplayName}`}</p>
      </>
    );
  },
  [Pegasus.EventType.chargeForReturnLabel]: ({ applyLabelMarkup }) => {
    const {
      storeUser: {
        store: { currencyCode },
      },
    } = useMerchantContext();
    const { shouldModify } = applyLabelMarkup;
    if (!shouldModify) return null;

    return (
      <p>
        <p>
          Markup Amount:{' '}
          {applyLabelMarkup.type === 'Fixed' ?
            formatter.currency(applyLabelMarkup.amount, currencyCode)
          : formatter.percent(applyLabelMarkup.amount / 100)}
        </p>
      </p>
    );
  },
  [Pegasus.EventType.chargeForExchangeOrderShipping]: ({
    applyExchangeOrderShippingMarkup,
  }) => {
    const {
      storeUser: {
        store: { currencyCode },
      },
    } = useMerchantContext();
    const { shouldModify } = applyExchangeOrderShippingMarkup;
    if (!shouldModify) return null;

    return (
      <p>
        <p>
          Markup Amount:{' '}
          {applyExchangeOrderShippingMarkup.type === 'Fixed' ?
            formatter.currency(
              applyExchangeOrderShippingMarkup.amount,
              currencyCode,
            )
          : formatter.percent(applyExchangeOrderShippingMarkup.amount / 100)}
        </p>
      </p>
    );
  },

  [Pegasus.EventType.productRegistrationSelection]: ({
    productRegistrationProductGroupId,
  }) => {
    const { data: productGroups } = useProductGroups();
    const productGroup = productGroups?.find(
      ({ id }) => id === productRegistrationProductGroupId,
    );

    return (
      <div>{productGroup && <p>Product Group: {productGroup.name}</p>}</div>
    );
  },
  [Pegasus.EventType.allowCustomerToKeepItem]: () => (
    <p>The customer will be allowed to keep the item without returning it.</p>
  ),
  [Pegasus.EventType.returnShippingConfig]: ({
    returnMethods,
    returnLocationId,
  }) => {
    const { data: returnLocations } = useReturnLocations();
    const returnTo = returnLocations?.find(({ id }) => id === returnLocationId);
    return (
      <div className="flex flex-col gap-2">
        <p>
          Return Methods:{' '}
          {returnMethods.map((rm) => shipmentTypeLabels[rm]).join(', ')}{' '}
        </p>

        {returnTo && <p>Return To: {returnTo.name}</p>}
      </div>
    );
  },
} satisfies {
  [K in Pegasus.EventType]: (eventParams: Pegasus.EventParams<K>) => ReactNode;
};

export default function StoreRuleForm() {
  const defaultEventType = Pegasus.EventType.productRegistrationSelection;

  const setPageForm = useSettingsLayoutContext();
  useEffect(() => {
    setPageForm(null);
  }, [setPageForm]);

  const navigate = useNavigate();

  const templateParam = useSearchParam('template');
  const storeRuleTemplate = storeRuleTemplatesWithIds.find(
    ({ id }) => id === templateParam,
  );

  const { storeRuleId } = usePathParams(
    z.object({ storeRuleId: z.coerce.number().optional() }),
  );
  const { data: storeRules = [] } = useStoreRules();
  const selectedStoreRule = storeRules.find(({ id }) => id === storeRuleId);

  const values: StoreRuleFormData | undefined =
    // eslint-disable-next-line  no-nested-ternary
    selectedStoreRule ? storeRuleToStoreRuleFormData(selectedStoreRule)
    : storeRuleTemplate ?
      storeRuleTemplateToStoreRuleFormData(storeRuleTemplate)
    : undefined;

  const { mutate: storeRuleUpsert, isPending } = useStoreRuleUpsert();

  const methods = useForm<StoreRuleFormData>({
    resolver: zodResolver(storeRuleFormDataSchema),
    values,
    defaultValues: {
      name: '',
      hook: Pegasus.hooksForEvent[defaultEventType][0], // first element is considered default
      priority: 1,
      isEnabled: true, // default to enabled
      event: {
        type: defaultEventType,
        params: eventDefaultParams[defaultEventType](),
      },
      booleanExpression: 'all',
      conditions: [],
    },
  });

  const eventTypeParam = useSearchParam('eventType');
  const returnTo = useSearchParam('returnTo');
  const eventType = z
    .nativeEnum(Pegasus.EventType)
    .nullable()
    .parse(eventTypeParam);

  useEffect(() => {
    // if no event type or template selected, stick with defaults; otherwise, set the selected event type as the default
    if (!eventType || storeRuleTemplate) return;
    methods.setValue('event.type', eventType);
    methods.setValue('hook', Pegasus.hooksForEvent[eventType][0]);
    methods.setValue('event.params', eventDefaultParams[eventType]());
  }, [eventType, storeRuleTemplate, methods]);

  const isExistingOrTemplate = !!values;

  return (
    <ContentWrapper>
      <BackAction.Button text="" />
      <Panel
        headline={`${selectedStoreRule ? 'Update' : 'Create'}${
          eventType ? ` ${displayName.eventType[eventType]}` : ''
        } Automation`}
      >
        {storeRuleTemplate && (
          <Alert variant="info" message={storeRuleTemplate.instructions} />
        )}
        {/* eslint-disable-next-line react/jsx-props-no-spreading -- allowed for `FormProvider` */}
        <FormProvider {...methods}>
          <form
            className="flex flex-col gap-4"
            onSubmit={(event) => {
              methods
                .handleSubmit((storeRuleFormData) => {
                  const upsert = toStoreRuleUpsert(
                    storeRuleFormData,
                    selectedStoreRule ?? storeRuleTemplate?.storeRule,
                  );
                  storeRuleUpsert(upsert);

                  if (returnTo) {
                    navigate(returnTo);
                    return;
                  }
                  navigate({
                    pathname: '../list',
                    search: `?eventType=${storeRuleFormData.event.type}`,
                  });
                })(event)
                .catch((error) => console.error(error));
            }}
          >
            <StoreRuleOverviewFields
              hideEventTypeField={!!eventType || isExistingOrTemplate}
            />
            <StoreRuleEventDetailFields />
            <StoreRuleConditionsFields />
            <div className="flex flex-col gap-2 md:flex-row">
              {/* // ? probably needs some kind of default if form is directly accessed via URL without history */}
              <Button
                onClick={() => {
                  if (returnTo) {
                    navigate(returnTo);
                    return;
                  }
                  navigate(-1);
                }}
                size="full"
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                size="full"
                className="flex-grow"
                loading={isPending}
              >
                {values ? 'Save' : 'Create'} Rule
              </Button>
            </div>
          </form>
        </FormProvider>
      </Panel>
    </ContentWrapper>
  );
}
