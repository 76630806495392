type Props = { supportingText: string } & (
  | { actionText?: string; action?: () => void }
  | { actionText?: never; action?: never }
);

// * based on https://tailwindui.com/components/application-ui/overlays/notifications#component-8960ebee918a39c1a753da4d378c0f96
export default function Snackbar({
  supportingText,
  action,
  actionText,
}: Props) {
  return (
    <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-md bg-black shadow-lg ring-1 ring-black ring-opacity-5">
      <div className="flex flex-1 items-center justify-between px-4 py-3">
        <p className="w-0 flex-1 py-1 text-sm font-medium text-white">
          {supportingText}
        </p>
        {action && (
          <button
            type="button"
            className="flex-shrink-0 rounded-md px-2 py-1 text-sm font-medium tracking-wider text-corso-blue-600 hover:bg-corso-gray-700 focus:bg-corso-gray-700 focus:outline-none focus:ring-2 focus:ring-corso-blue-600"
            onClick={action}
          >
            {actionText}
          </button>
        )}
      </div>
    </div>
  );
}
