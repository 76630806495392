import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ForwardRefExoticComponent, SVGProps } from 'react';

export type SupportedIcon =
  | ForwardRefExoticComponent<SVGProps<SVGSVGElement>>
  | IconDefinition;

/** Supports [HeroIcon](https://heroicons.com/) SVGs, or icons from [FontAwesome](https://fontawesome.com/). */
export default function Icon({
  icon: IconOrDefinition,
  className,
}: {
  icon: SupportedIcon;
  className?: string;
}) {
  /**
   * Using `$$typeof` might be unstable across React versions.
   * It exists primarily to distinguish between React elements and other objects to prevent XSS attack
   * This reference specifically looks towards it existing on exotic components, i.e. forwarded refs.
   */
  return '$$typeof' in IconOrDefinition ?
      <IconOrDefinition data-icon-type="svg" className={className} />
    : <FontAwesomeIcon
        data-icon-type="fontawesome"
        icon={IconOrDefinition}
        className={className}
      />;
}
