//* return location

import { z } from 'zod';
import { helperSchema } from '../../zodExtensions.js';
import { locationAddress } from './address.js';

export const returnLocation = z.object({
  id: z.number(),
  name: helperSchema.nonEmptyString,
  customsSignerName: z.string().optional(),
  fulfillmentLocationIdFromPlatform: z.string().nullish(),
  address: locationAddress,
});

export const returnLocationCreate = returnLocation.omit({ id: true });
