import { useQuery } from '@tanstack/react-query';
import { useDebounce } from '@uidotdev/usehooks';
import { SearchableCrewClaimResolutionMethod } from 'corso-types';
import {
  CrewClaimRollupStatusCode,
  CrewClaimTypeEnum,
  crewClaimTypeEnumPluralizedName,
} from 'corso-types/enums/crew';
import { endOfDay, startOfDay, subDays } from 'date-fns';
import { useMemo } from 'react';
import {
  Navigate,
  Outlet,
  useOutletContext,
  useSearchParams,
} from 'react-router-dom';
import { z } from 'zod';
import api from '~/api';
import BackAction from '~/components/BackAction';
import ClaimsList from '~/components/claim/ClaimList';
import ClaimSearch from '~/components/claim/ClaimSearch';
import ContentWrapper from '~/components/ContentWrapper';
import Page from '~/components/Page';
import Panel from '~/components/Panel';
import useIsDesktop from '~/hooks/useIsDesktop';
import { usePathParams } from '~/hooks/usePathParams';

type ClaimsLayoutContext = {
  claimType: CrewClaimTypeEnum;
};

/** Only available to direct descendants of the `AppLayout`. */
export function useClaimsLayoutContext() {
  return useOutletContext<ClaimsLayoutContext>();
}

const searchParamsSchema = z.preprocess(
  (input) =>
    input instanceof URLSearchParams ?
      [...input.entries()].reduce(
        (acc, [key, value]) => ({ ...acc, [key]: value }),
        {},
      )
    : input,
  z.object({
    searchTerm: z.string().default(''),
    startDate: z.coerce.date().default(subDays(new Date(), 30)),
    endDate: z.coerce.date().default(new Date()),
    requestedResolutionMethod: z
      .nativeEnum(SearchableCrewClaimResolutionMethod)
      .or(z.literal('all'))
      .default('all'),
    rollupStatusCode: z
      .nativeEnum(CrewClaimRollupStatusCode)
      .or(z.literal('all'))
      .default('all'),
    orderBy: z.union([z.literal('asc'), z.literal('desc')]).default('desc'),
  }),
);

const paramsSchema = z.object({
  storeId: z.string(),
  claimType: z
    .string()
    .transform((value) => value.replace(/^./, (s) => s.toUpperCase()))
    .pipe(z.nativeEnum(CrewClaimTypeEnum)),
  claimId: z.string().optional(),
});

/** Represents a sidebar and main content layout, which intelligently shows both on desktop, while separating the sidebar list navigation and individual items on mobile. */
export default function ClaimsLayout() {
  const [searchParams] = useSearchParams();
  const params = usePathParams(paramsSchema);
  const searchData = useMemo(
    () => searchParamsSchema.parse(searchParams),
    [searchParams],
  );

  /** Debounce data, so that the query parameter and derived state can be updated in real time, but the query key can be debounced. */
  const debouncedSearchData = useDebounce(searchData, 500);

  // TODO paginated/virtualized
  // TODO loading skeleton / disabled state of form while query loading
  // TODO error state
  const { data: claimSearchResults, isLoading } = useQuery({
    refetchOnMount: true,
    queryKey: ['claims', params.storeId, params.claimType, debouncedSearchData],
    queryFn: async () => {
      const {
        searchTerm,
        startDate,
        endDate,
        rollupStatusCode,
        requestedResolutionMethod,
        orderBy,
      } = debouncedSearchData;

      const searchParameters = {
        ...(requestedResolutionMethod === 'all' ? null : (
          { requestedResolutionMethod }
        )),

        claimType: params.claimType,
        ...(rollupStatusCode === 'all' ? null : (
          { statusCode: rollupStatusCode }
        )),
        // regarding range, it's inclusive, but this just makes the range explicit to the expectation
        startDate: startOfDay(startDate),
        endDate: endOfDay(endDate),
        searchTerm,
        orderBy,
      };

      const [claimList = [], claimListCount] = await Promise.all([
        api.store(params.storeId).claimList.get(searchParameters), // ? maybe allow setting the `take` parameter
        api.store(params.storeId).claimList.count(searchParameters),
      ]);

      return { claimList, claimListCount };
    },
    retry: 1,
  });

  const isDesktop = useIsDesktop();
  const headline = crewClaimTypeEnumPluralizedName[params.claimType];
  const context = useMemo<ClaimsLayoutContext>(
    () => ({ claimType: params.claimType }),
    [params.claimType],
  );

  const [firstClaim, ...additionalClaims] = claimSearchResults?.claimList ?? [];
  // if there is only one claim found, and it differs from the current, redirect to the first claim
  if (
    firstClaim &&
    !additionalClaims.length &&
    params.claimId !== `${firstClaim.id}`
  ) {
    return (
      <Navigate
        to={{
          pathname: `${firstClaim.id}`,
          search: searchParams.toString(),
        }}
      />
    );
  }

  if (!isDesktop) {
    return (
      <Page headline={headline}>
        <ContentWrapper>
          {params.claimId ?
            <>
              <BackAction.Link text="Claims" />
              <Panel className="gap-0 p-0 lg:p-0">
                <Outlet context={context} />
              </Panel>
            </>
          : <>
              <ClaimSearch
                searchTerm={searchData.searchTerm}
                dateRange={{
                  from: searchData.startDate,
                  to: searchData.endDate,
                }}
                requestedResolutionMethod={searchData.requestedResolutionMethod}
                rollupStatusCode={searchData.rollupStatusCode}
                orderBy={searchData.orderBy}
              >
                <p className="text-xs text-corso-gray-500">
                  Showing {claimSearchResults?.claimList.length ?? '#'} of{' '}
                  {claimSearchResults?.claimListCount.count ?? '#'}
                </p>
              </ClaimSearch>
              <ClaimsList
                claims={claimSearchResults?.claimList}
                isLoading={isLoading}
              />
            </>
          }
        </ContentWrapper>
      </Page>
    );
  }

  // desktop layout with list and details in a multi-column layout
  return (
    <Page headline={headline}>
      <ContentWrapper>
        <div className="grid grid-cols-4 items-start gap-4">
          {/*
           * // * top-20 is a magic value so that it doesn't slip behind the app layout navigation
           * // * z index is required to ensure any absolute positioned elements such as the date picker are above the app main content
           */}
          <ContentWrapper className="sticky top-20 z-10 w-full self-start">
            <ClaimSearch
              requestedResolutionMethod={searchData.requestedResolutionMethod}
              searchTerm={searchData.searchTerm}
              dateRange={{
                from: searchData.startDate,
                to: searchData.endDate,
              }}
              rollupStatusCode={searchData.rollupStatusCode}
              orderBy={searchData.orderBy}
            >
              <p className="text-xs text-corso-gray-500">
                Showing {claimSearchResults?.claimList.length ?? '#'} of{' '}
                {claimSearchResults?.claimListCount.count ?? '#'}
              </p>
            </ClaimSearch>
            <ClaimsList
              claims={claimSearchResults?.claimList}
              isLoading={isLoading}
            />
          </ContentWrapper>
          <div className="col-span-3">
            <Outlet context={context} />
          </div>
        </div>
      </ContentWrapper>
    </Page>
  );
}
