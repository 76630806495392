import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '~/utils/shadcn';

// TODO have this primitive replace our current `Badge` component

const badgeVariants = cva(
  'inline-flex items-center rounded-full border border-neutral-200 px-2.5 py-0.5 text-xs font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-neutral-950 focus:ring-offset-2',
  {
    variants: {
      variant: {
        default:
          'bg-corso-gray-50 text-corso-gray-600 ring-corso-gray-500/20 hover:bg-corso-gray-100',
        warning:
          'hover:bg-corso-orange-100 border-corso-orange-800 bg-corso-orange-50 text-corso-orange-800 ring-corso-orange-800',
        success:
          'border-corso-green-800 bg-corso-green-50 text-corso-green-800 ring-corso-green-700/30 hover:bg-corso-green-100',
        danger:
          'border-corso-red-800 bg-corso-red-50 text-corso-red-800 ring-corso-red-700/30 hover:bg-corso-red-100',
        info: 'border-corso-blue-800 bg-corso-blue-50 text-corso-blue-800 ring-corso-blue-700/30 hover:bg-corso-blue-100',

        beta: 'border-corso-blue-600 text-corso-blue-600',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

export type BadgeProps = React.HTMLAttributes<HTMLDivElement> &
  VariantProps<typeof badgeVariants>;

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <span className={cn(badgeVariants({ variant }), className)} {...props} />
  );
}

export { Badge, badgeVariants };
