import { z } from 'zod';
import { DbJsonSchema } from '../../../dbJsonSchemas.js';
import {
  AutomatedEmailTypeEnum,
  CrewIncentiveTypeEnum,
  FinalizationEmailTypeEnum,
} from '../../../enums/crew.js';
import { ShipmentApiProviders } from '../../../enums/enum.js';
import {
  ClaimReasonEnum,
  ShopifyAppSubscriptionStatusEnum,
} from '../../../enums/gsp.js';
import { enumToZodLiteralUnion, helperSchema } from '../../../zodExtensions.js';

export const settingsTheme = DbJsonSchema.StoreUiConfig.customerUiConfigSchema;

/** Related to database schema where columns have been set to `@db.Decimal(6, 2)`, as values must round to be less than 10k */
const lessThan10k = helperSchema.nonNegativeNumber.lt(10_000, {
  message: 'Must be less than 10,000',
});

const baseEmailTemplate = z.object({
  id: z.number(),
  name: z.string(), // display name - non-editable
  sendAfterDays: z.number().nullable(),
  header: helperSchema.nonEmptyString,
  subject: helperSchema.nonEmptyString,
  body: helperSchema.nonEmptyString,
});

const automatedEmailTemplate = baseEmailTemplate.merge(
  z.object({
    isEnabled: z.boolean(),
    type: enumToZodLiteralUnion(AutomatedEmailTypeEnum),
  }),
);

const finalizeEmailTemplates = baseEmailTemplate.merge(
  z.object({
    type: enumToZodLiteralUnion(FinalizationEmailTypeEnum),
  }),
);

const notifications = z.object({
  isNewClaimEmailEnabled: z.boolean(),
  emailsForNewClaim: helperSchema.nullifiedEmailList,
  isClaimReceivedEmailEnabled: z.boolean(),
  emailsForClaimReceived: helperSchema.nullifiedEmailList,
});

const email = z
  .object({
    emailSendFrom: helperSchema.nullifiedEmail,
    emailSendFromName: helperSchema.nullifiedString,
    emailReplyTo: helperSchema.nullifiedEmail,
    emailReplyToName: helperSchema.nullifiedString,
    verifiedEmailDomain: z.string().nullable(),
    storeEmailTemplates: z.object({
      automatedEmailTemplates: z.array(automatedEmailTemplate),
      finalizeEmailTemplates: z.array(finalizeEmailTemplates),
    }),
  })
  .refine(
    ({ verifiedEmailDomain, emailSendFrom }) => {
      if (!verifiedEmailDomain) return true;
      return z
        .string()
        .email()
        .endsWith(`@${verifiedEmailDomain}`)
        .or(z.literal('noreply@corso.com'))
        .safeParse(emailSendFrom).success;
    },
    (ctx) => {
      const { verifiedEmailDomain } = ctx;
      return {
        message: `Email send from must be a valid email address ending with ${
          verifiedEmailDomain ?? ''
        }`,
        path: ['emailSendFrom'],
      };
    },
  );

export const appSubscription = z.object({
  appSubscriptionStatus: enumToZodLiteralUnion(
    ShopifyAppSubscriptionStatusEnum,
  ),
  appSubscriptionConfirmationUrl: z.string(),
  isActive: z.boolean(), // * is the store active (payment method configured, able to create claims)
});

// -------------- klaviyo ----------------
const klaviyoDisconnected = z.object({
  startOauthUrl: z.string(),
  connected: z.literal(false),
});

const klaviyoConnected = z.object({
  disconnectOauthUrl: z.string(),
  connected: z.literal(true),
});

const klaviyoConfig = z.discriminatedUnion('connected', [
  klaviyoDisconnected,
  klaviyoConnected,
]);

// --------------- stripe ----------------

const stripeDisconnected = z.object({
  startOauthUrl: z.string(),
  connected: z.literal(false),
});

const stripeConnected = z.object({
  idFromPlatform: z.string(),
  disconnectOauthUrl: z.string(),
  connected: z.literal(true),
});

const stripeConfig = z.discriminatedUnion('connected', [
  stripeDisconnected,
  stripeConnected,
]);

// --------------- shipBob ----------------

const shipBobConfig = z.object({
  id: z.number(),
  shouldCreateRma: z.boolean(),
  accessToken: z.string(),
});

const shipBobConfigUpdate = shipBobConfig.omit({ id: true }).merge(
  z.object({
    disconnect: z.boolean().optional(),
  }),
);

// --------------- shipHero ----------------

const shipHeroConfig = z.object({
  id: z.number(),
  shouldCreateRma: z.boolean(),
  refreshToken: z.string(),
});

const shipHeroConfigUpdate = shipHeroConfig
  .omit({ id: true, refreshToken: true })
  .merge(
    z.object({
      refreshToken: helperSchema.nonEmptyString,
      disconnect: z.boolean().optional(),
    }),
  );

// --------------- blueBox ----------------
const blueBoxConfig = z.object({
  id: z.number(),
  username: z.string(),
  password: z.string(),
  shouldCreateRma: z.boolean(),
});

const blueBoxConfigUpdate = blueBoxConfig
  .omit({ id: true })
  .merge(
    z.object({
      username: helperSchema.nonEmptyString,
      password: helperSchema.nonEmptyString,
    }),
  )
  .merge(z.object({ disconnect: z.boolean().optional() }));

// --------------- Gorgias ----------------

const gorgiasConfig = z.object({
  account: z.string(),
  appUrl: z.string(),
});

// --------------- shipping provider ----------------

const carrier = z.object({
  id: z.number(),
  name: z.string(),
});

const shippingProvider = z.object({
  provider: enumToZodLiteralUnion(ShipmentApiProviders),
  apiKey: z.string(),
  carriers: z.array(carrier),
  enabledCarriers: z.array(carrier),
  isEnabled: z.boolean(),
});

const statusConfig = z.object({
  id: z.number(),
  startReturnUrl: z.string().optional(),
  idFromPlatform: z.string(),
});

const rechargeConfig = z.object({
  id: z.number(),
  apiKey: z.string(),
  isEnabled: z.boolean(),
});

const gladlyConfig = z.object({
  id: z.number(),
});

const integrations = z.object({
  appSubscription,
  stripeConfig,
  klaviyoConfig,
  vesylConfig: shippingProvider.nullable(),
  rechargeConfig: rechargeConfig.nullable(),
  shipHeroConfig: shipHeroConfig.nullable(),
  gorgiasConfig: gorgiasConfig.nullable(),
  shipBobConfig: shipBobConfig.nullable(),
  statusConfig: statusConfig.nullable(),
  gladlyConfig: gladlyConfig.nullable(),
  easyPostConfig: shippingProvider.nullable(),
  blueBoxConfig: blueBoxConfig.nullable(),
});

export const statusConfigUpdate = z.object({
  statusConfig: statusConfig
    .omit({
      id: true,
      startReturnUrl: true,
    })
    .merge(
      z.object({
        disconnect: z.boolean().optional(),
      }),
    ),
  kind: z.literal('statusConfig'),
});

export const shippingProviderUpdate = shippingProvider
  .omit({ apiKey: true, carriers: true })
  .merge(
    z.object({
      apiKey: helperSchema.nonEmptyString,
    }),
  );

export const rechargeUpdate = rechargeConfig.omit({ id: true }).merge(
  z.object({
    kind: z.literal('rechargeConfig'),
  }),
);

export const vesylUpdate = shippingProviderUpdate.merge(
  z.object({
    kind: z.literal('vesylConfig'),
  }),
);

export const easyPostUpdate = shippingProviderUpdate.merge(
  z.object({
    kind: z.literal('easyPostConfig'),
  }),
);

export const shipHeroUpdate = z.object({
  shipHeroConfig: shipHeroConfigUpdate,
  kind: z.literal('shipHeroConfig'),
});

export const shipBobUpdate = z.object({
  shipBobConfig: shipBobConfigUpdate,
  kind: z.literal('shipBobConfig'),
});

export const blueBoxUpdate = z.object({
  blueBoxConfig: blueBoxConfigUpdate,
  kind: z.literal('blueBoxConfig'),
});

const integrationsUpdateDiscriminatedUnion = z.discriminatedUnion('kind', [
  shipHeroUpdate,
  vesylUpdate,
  easyPostUpdate,
  shipBobUpdate,
  statusConfigUpdate,
  rechargeUpdate,
  blueBoxUpdate,
]);

// applies to all resolution methods
const returns = z.object({
  isReturnsEnabled: z.boolean(),
  returnPolicyUrl: helperSchema.nullifiedUrl,
  isReturnInspectionEnabled: z.boolean(),

  isReplenishInventoryWhenReturned: z.boolean(),
  isClaimImageFromCustomerRequired: z.boolean(),

  productTagFinalSale: helperSchema.nonEmptyString,
  productTagExchangeOnly: helperSchema.nonEmptyString,

  exchangeOrderPrefix: helperSchema.nullifiedString,
  defaultReturnExpirationDays: helperSchema.nonNegativeNumber,
});

const refunds = z.object({
  refundValidityDays: helperSchema.nonNegativeNumber, // * an escape hatch allowing NO refunds when set to `0`
});

// displayed as store credit
const giftCards = z.intersection(
  z.object({
    giftCardValidityDays: helperSchema.nonNegativeNumber,
    giftCardProductIdFromPlatform: helperSchema.nullifiedString,
  }),
  z.discriminatedUnion('giftCardIncentiveType', [
    z.object({
      giftCardIncentiveType: z.literal(CrewIncentiveTypeEnum.fixed),
      giftCardIncentiveAmount: lessThan10k,
    }),
    z.object({
      giftCardIncentiveType: z.literal(CrewIncentiveTypeEnum.percent),
      giftCardIncentiveAmount: z.number().min(0).max(100),
    }),
    z.object({
      giftCardIncentiveType: z.literal(CrewIncentiveTypeEnum.none),
      giftCardIncentiveAmount: helperSchema.nonNegativeNumber,
    }),
  ]),
);

const variantExchanges = z.object({
  variantExchangeValidityDays: helperSchema.nonNegativeNumber,
  variantExchangeShippingTitle: helperSchema.nullifiedString,
  minInventoryAvailableToCustomer: helperSchema.nonNegativeNumber,
  isExchangeOfExchangeAllowed: z.boolean(),
});

const registrations = z.object({
  // main setting control for registrations
  isRegistrationEnabled: z.boolean(),

  shouldCollectRegistrationProofOfPurchase: z.boolean(),
  shouldCollectRegistrationAddress: z.boolean(),
  isRegistrationWarrantyClaimEnabled: z.boolean(),

  // all possible sales channels
  salesChannels: z.array(z.string()),
  // sales channels where product registration is offered
  offeredSalesChannels: z.array(z.string()),
});

const warranties = z.object({
  isWarrantiesEnabled: z.boolean(),
  warrantyPolicyUrl: helperSchema.nullifiedUrl,
  defaultWarrantyDays: helperSchema.nonNegativeNumber,
  isWarrantyInspectionEnabled: z.boolean(),
});

const shippingProtectionConfigured = z.object({
  isConfigured: z.literal(true),
  isOffered: z.boolean(),
  isClaimImageFromCustomerRequired: z.boolean(),
  shouldSendPurchaseConfirmation: z.boolean(),
  isMerchantFinalizationEnabled: z.boolean(),
  offeredClaimReasons: z.array(enumToZodLiteralUnion(ClaimReasonEnum)),
  giftCardProductIdFromPlatform: helperSchema.nullifiedString,
});

const shippingProtectionDisabled = z.object({
  isConfigured: z.literal(false),
});

export const shippingProtection = z.discriminatedUnion('isConfigured', [
  shippingProtectionConfigured,
  shippingProtectionDisabled,
]);

export const settingsConfig = z.object({
  email,
  returns,
  refunds,
  giftCards,
  variantExchanges,
  warranties,
  notifications,
  shippingProtection,
  registrations,
});

export const settingsIntegration = integrations;
export const settingsIntegrationUpdate = integrationsUpdateDiscriminatedUnion;
export { shippingProvider };
