import { PropsWithChildren, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

/** A `Panel` or `Card` represents a styled `section` of content.
 * It may have a `header` and/or `footer` and is typically used to group related content.
 * This implementation is inspired by Tailwind UI's `Panel` component.
 * @see https://tailwindui.com/components/application-ui/layout/panels
 */
type BaseProps = {
  footer?: ReactNode;
  className?: string;
};
type PropsWithHeader = BaseProps & {
  headline: string;
  description?: string;
  actions?: ReactNode;
};
type PropsWithoutHeader = BaseProps & {
  headline?: never;
  description?: never;
  actions?: never;
};

export default function Panel({
  children,
  className,
  headline,
  actions,
  description,
  footer,
}: PropsWithChildren<PropsWithHeader | PropsWithoutHeader>) {
  return (
    <section
      className={twMerge(
        'flex flex-col gap-3 bg-white px-3 py-5 shadow-md lg:rounded-lg lg:px-4',
        className,
      )}
    >
      {headline && (
        <header className="flex flex-col">
          <div className="flex items-center justify-between">
            <h2 className="text-lg font-medium">{headline}</h2>
            {actions}
          </div>
          {description && (
            <span className="text-sm text-corso-gray-500">{description}</span>
          )}
        </header>
      )}
      {children}
      {footer && <footer>{footer}</footer>}
    </section>
  );
}
