import { ShopifyAppSubscriptionStatusEnum } from 'corso-types/enums/gsp';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { z } from 'zod';
import { useConfigSettings } from '~/hooks/useConfigSettings';
import { useEnabledClaimType } from '~/hooks/useEnabledClaimType';
import { useIntegrationSettingsData } from '~/hooks/useIntegrationSettings';
import { useReturnLocations } from '~/hooks/useReturnLocations';
import {
  ConfigSettings,
  IntegrationSettings,
  ReturnLocation,
  StoreRule,
} from '~/types';
import { useStoreRules } from '~/hooks/useStoreRules';

type ActionData = {
  email: ConfigSettings['email'];
  enabledClaimTypes: ReturnType<typeof useEnabledClaimType>;
  giftCards: ConfigSettings['giftCards'];
  integrations: IntegrationSettings;
  returnLocations: ReturnLocation[];
  policyRules: StoreRule[];
};

type SettingsAction = {
  // display name of the action
  label: string;
  // the path to the settings page for the action
  to: string;
  // whether the action is relevant with the current settings
  shouldRenderAction?: (data: Partial<ActionData>) => boolean;
  // whether the action is complete
  checkActionComplete: (data?: Partial<ActionData>) => boolean;
};
type EvaluatedAction = SettingsAction & { isComplete: boolean };

type SettingsActionsProviderContext = {
  actions: EvaluatedAction[];
};

const isReturnOrWarrantyEnabled = (
  enabledClaimTypes: ReturnType<typeof useEnabledClaimType> | undefined,
) => !!enabledClaimTypes?.isReturnOrWarrantyEnabled;

const actions = [
  {
    label: 'Approve App Subscription',
    to: 'integrations',
    checkActionComplete: (data) =>
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      data?.integrations?.appSubscription?.appSubscriptionStatus ===
      ShopifyAppSubscriptionStatusEnum.active,
  },
  {
    label: 'Configure a Shipping Provider',
    to: 'integrations',

    shouldRenderAction: (data) =>
      isReturnOrWarrantyEnabled(data?.enabledClaimTypes),
    checkActionComplete: (data) =>
      !!data?.integrations?.vesylConfig?.apiKey.length ||
      !!data?.integrations?.easyPostConfig?.apiKey.length,
  },
  {
    label: 'Setup Gift Card Product',
    to: 'returns',
    shouldRenderAction: (data) =>
      data.giftCards?.giftCardValidityDays !== undefined &&
      data.giftCards.giftCardValidityDays > 0 &&
      isReturnOrWarrantyEnabled(data.enabledClaimTypes),
    checkActionComplete: (data) =>
      !!data?.giftCards?.giftCardProductIdFromPlatform,
  },
  {
    label: 'Configure Email Settings',
    to: 'email',
    shouldRenderAction: (data) =>
      isReturnOrWarrantyEnabled(data.enabledClaimTypes),
    checkActionComplete: (data) => {
      const requiredEmailSettings = z.object({
        emailSendFrom: z.string().email(),
        emailSendFromName: z.string(),
        emailReplyTo: z.string().email(),
        emailReplyToName: z.string(),
      });

      return requiredEmailSettings.safeParse(data?.email).success;
    },
  },
  {
    label: 'Configure Locations and Policies',
    to: 'shipping-policies',
    shouldRenderAction: (data) =>
      isReturnOrWarrantyEnabled(data.enabledClaimTypes),
    checkActionComplete: (data) =>
      !!data?.returnLocations?.length && !!data?.policyRules?.length,
  },
] satisfies SettingsAction[];

const SettingsActionsContext = createContext<SettingsActionsProviderContext>({
  actions: [],
});

export const useSettingsActionsContext = () =>
  useContext(SettingsActionsContext);

export function SettingsActionsProvider({
  children,
}: {
  children?: ReactNode;
}) {
  const { data: configSettings, isLoading: isConfigSettingsLoading } =
    useConfigSettings(({ email, giftCards }) => ({
      email,
      giftCards,
    }));

  const { data: integrationSettings, isLoading: isIntegrationLoading } =
    useIntegrationSettingsData();

  const { data: returnLocations, isLoading: isReturnLocationsLoading } =
    useReturnLocations();

  const { data: storeRules, isLoading: isStoreRulesLoading } = useStoreRules();

  const enabledClaimTypes = useEnabledClaimType();

  const actionData = useMemo(
    () =>
      ({
        ...configSettings,
        enabledClaimTypes,
        integrations: integrationSettings,
        returnLocations,
        policyRules:
          storeRules?.filter(
            (sr) => sr.rule.event.type === 'returnShippingConfig',
          ) ?? [],
      }) satisfies Partial<ActionData>,
    [
      configSettings,
      integrationSettings,
      returnLocations,
      enabledClaimTypes,
      storeRules,
    ],
  );

  const dataLoaded =
    !isConfigSettingsLoading &&
    !isIntegrationLoading &&
    !isReturnLocationsLoading &&
    !isStoreRulesLoading;

  const [evaluated, setEvaluated] = useState<EvaluatedAction[]>([]);

  useEffect(() => {
    // * this check will keep settings action badge from flashing none are complete on initial load
    if (dataLoaded) {
      setEvaluated(
        actions
          .filter((action) => action.shouldRenderAction?.(actionData) ?? true)
          .map(
            (action) =>
              ({
                ...action,
                isComplete: action.checkActionComplete(actionData),
              }) satisfies EvaluatedAction,
          ),
      );
    }
  }, [actionData, dataLoaded]);

  const value = useMemo<SettingsActionsProviderContext>(
    () => ({ actions: evaluated }),
    [evaluated],
  );

  return (
    <SettingsActionsContext.Provider value={value}>
      {children}
    </SettingsActionsContext.Provider>
  );
}
