import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from 'react-router-dom';

import AppLayout from './layouts/AppLayout';
import AuxiliaryLayout from './layouts/AuxiliaryLayout';
import ClaimsLayout from './layouts/ClaimsLayout';
import SettingsLayout from './layouts/SettingsLayout';

import SignUpLoader from './loaders/SignUpLoader';
import UserWelcomeLoader from './loaders/UserWelcomeLoader';

import Providers from './providers';

import RegistrationsLayout from './layouts/RegistrationsLayout';
import ShippingClaimsLayout from './layouts/ShippingClaimsLayout';
import Analytics from './pages/Analytics';
import ClaimCreate from './pages/claims/ClaimCreate';
import ClaimReview from './pages/claims/ClaimReview';
import DefaultStoreNavigation from './pages/DefaultStoreNavigation';
import FullPageError from './pages/FullPageError';
import Invoices from './pages/Invoices';
import NotFound from './pages/NotFound';
import RegistrationOverview from './pages/registrations/RegistrationsOverview';
import ClaimReasonSettings from './pages/settings/ClaimReasonSettings';
import CustomFieldSettings from './pages/settings/CustomFieldSettings';
import EmailSettings from './pages/settings/EmailSettings';
import IntegrationsSettings from './pages/settings/IntegrationsSettings';
import NotificationsSettings from './pages/settings/NotificationsSettings';
import ProductGroupSettings from './pages/settings/ProductGroupSettings';
import RegistrationSettings from './pages/settings/RegistrationSettings';
import ReturnsSettings from './pages/settings/ReturnsSettings';
import ShippingPolicySettings from './pages/settings/ShippingPolicySettings';
import ShippingProtectionSettings from './pages/settings/ShippingProtectionSettings';
import StoreRuleForm from './pages/settings/storeRules/StoreRuleForm';
import StoreRulesList from './pages/settings/storeRules/StoreRulesList';
import StoreRulesOverview from './pages/settings/storeRules/StoreRulesOverview';
import StoreRuleTemplates from './pages/settings/storeRules/StoreRuleTemplates';
import ThemeSettings from './pages/settings/ThemeSettings';
import UserSettings from './pages/settings/UserSettings';
import WarrantiesSettings from './pages/settings/WarrantiesSettings';
import ShippingClaimOverview from './pages/shippingClaims/ShippingClaimOverview';
import SignOut from './pages/SignOut';
import SignUp from './pages/SignUp';
import UserWelcome from './pages/UserWelcome';

// ! verify src/pages/DefaultStoreNavigation.tsx routes when making changes to this file

// TODO add error boundaries to help with error handling, especially when a new version is deployed
export const routes = createRoutesFromElements(
  <Route path="/" errorElement={<FullPageError />}>
    <Route element={<Providers />}>
      <Route path="/:storeId?" element={<AppLayout />}>
        <Route index element={<DefaultStoreNavigation />} />
        {/* // * currently omitting dashboard at `/` until we know better what we want to display to merchants */}
        <Route path="registrations" element={<RegistrationsLayout />}>
          <Route
            path=":registrationId?"
            index
            element={<RegistrationOverview />}
          />
        </Route>
        <Route path="claims">
          {/* // * redirect to default route if no claim type selected */}
          <Route index element={<Navigate to=".." relative="path" replace />} />
          <Route path="create" element={<ClaimCreate />} />
          <Route path="shipping" element={<ShippingClaimsLayout />}>
            <Route
              path=":shippingClaimId?"
              index
              element={<ShippingClaimOverview />}
            />
          </Route>

          <Route path=":claimType" element={<ClaimsLayout />}>
            <Route path=":claimId?" index element={<ClaimReview />} />
          </Route>
        </Route>

        <Route path="analytics" element={<Analytics />} />
        <Route path="billing" element={<Invoices />} />

        <Route path="settings" element={<SettingsLayout />}>
          <Route index element={<Navigate to="theme" replace />} />
          <Route path="theme" element={<ThemeSettings />} />
          <Route path="email" element={<EmailSettings />} />
          <Route path="integrations" element={<IntegrationsSettings />} />
          <Route path="returns" element={<ReturnsSettings />} />
          <Route path="warranties" element={<WarrantiesSettings />} />
          <Route path="registrations" element={<RegistrationSettings />} />
          <Route path="reasons" element={<ClaimReasonSettings />} />
          <Route path="product-groups" element={<ProductGroupSettings />} />
          <Route path="notifications" element={<NotificationsSettings />} />
          <Route path="custom-fields" element={<CustomFieldSettings />} />
          <Route
            path="shipping-protection"
            element={<ShippingProtectionSettings />}
          />

          <Route
            path="shipping-policies"
            element={<ShippingPolicySettings />}
          />
          <Route path="automations">
            <Route index element={<StoreRulesOverview />} />
            <Route path="templates" element={<StoreRuleTemplates />} />
            <Route
              // => GET list - supports filtering by eventType using URL parameter
              path="list"
              element={<StoreRulesList />}
            />
            <Route
              // => POST create - can have partial store rule when loaded; i.e. from template
              path="create"
              element={<StoreRuleForm />}
            />
            <Route
              // => POST edit; GET one available through item details in list
              path="edit/:storeRuleId"
              element={<StoreRuleForm />}
            />
          </Route>
          <Route path="users" element={<UserSettings />} />
        </Route>
      </Route>
      <Route path="sign-out" element={<SignOut />} />
    </Route>
    <Route element={<AuxiliaryLayout />}>
      <Route
        path="welcome"
        element={<UserWelcome />}
        loader={UserWelcomeLoader}
      />
      <Route path="sign-up" element={<SignUp />} loader={SignUpLoader} />
    </Route>
    <Route path="*" element={<NotFound />} />
  </Route>,
);

export default function Router() {
  const router = createBrowserRouter(routes);

  return <RouterProvider router={router} />;
}
