import { startOfDay, subDays } from 'date-fns';
import { ComponentProps, forwardRef } from 'react';

/**
 * Formats a date as a relative time from now.
 * Different representations will be shown based on the duration of the time frame in the current locale.
 * Formats include: "Yesterday at {time}", "Today at {time}", "{weekday}, {date} at {time}", and "{date} at {time}".
 * If `compact` is `true`, the date will be formatted as a short date and time unless it's today or yesterday.
 *
 * Inspired by how Shopify shows relative times for orders in the admin.
 */
// * consider elevating to `formatter` utility
function formatRelativeTime(date: Date, compact = false) {
  const now = new Date();
  const today = startOfDay(now);
  const yesterday = subDays(today, 1);
  const aWeekAgo = subDays(today, 7);

  // yesterday or today
  if (date >= yesterday && date < now) {
    if (date < today) {
      return `Yesterday at ${date.toLocaleTimeString(undefined, { timeStyle: 'short' })}`;
    }

    return `Today at ${date.toLocaleTimeString(undefined, { timeStyle: 'short' })}`;
  }

  if (compact) {
    // {date}, {time}
    return Intl.DateTimeFormat(undefined, {
      dateStyle: 'short',
      timeStyle: 'short',
    }).format(date);
  }

  // within a week
  if (date >= aWeekAgo && date < yesterday) {
    // {weekday}, {date} at {time}
    return Intl.DateTimeFormat(undefined, {
      dateStyle: 'full',
      timeStyle: 'short',
    }).format(date);
  }

  // {date} at {time}
  return Intl.DateTimeFormat(undefined, {
    dateStyle: 'long',
    timeStyle: 'short',
  }).format(date);
}

/** Any value that can be coerced to a `Date`. */
export type DateTime = Date | number | string;

/**
 * Shows a relative date time from now.
 */
const RelativeDateTime = forwardRef<
  HTMLTimeElement,
  Omit<ComponentProps<'time'>, 'dateTime'> & {
    dateTime: DateTime;
    compact?: boolean;
  }
>(({ dateTime, compact = false, ...props }, ref) => {
  const date = new Date(dateTime);

  return (
    <time dateTime={date.toISOString()} ref={ref} {...props}>
      {formatRelativeTime(date, compact)}
    </time>
  );
});

export default RelativeDateTime;
